import FileApiInterface from '../../vos/media/file-api-interface';
import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Banner
{
    id = 0;
    headline: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    title: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: FileApiInterface|null = null;
    displayOrder = 1;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
}