import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import User from '@/model/entities/organisation/user';
import UserTransformer from '@/model/transformers/organisation/user-transformer';

export default class LoginEmployeeTask extends AbstractApiPostTask
{
    private _employeeNumber!: string;

    public async run(): Promise<User|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const user = UserTransformer.fromApi(response.data.data);

                return user;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set employeeNumber(employeeNumber: string)
    {
        this._employeeNumber = employeeNumber;
    }

    protected getUrl(): string
    {
        return '/login-employee';
    }

    protected getData(): FormData
    {
        const data = new FormData();
        data.append('en', this._employeeNumber);

        return data;
    }
}