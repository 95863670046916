import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import SafetyAmbassador from '../../../entities/organisation/safety-ambassador';
import SafetyAmbassadorApiInterface from '../../../entities/organisation/safety-ambassador-api-interface';
import SafetyAmbassadorTransformer from '../../../transformers/organisation/safety-ambassador-transformer';

export default class UploadSafetyAmbassadorFileTask extends AbstractApiPostTask
{
    private _safetyAmbassadorId!: number;
    private _field!: string;
    private _file!: File;

    public async run(): Promise<SafetyAmbassador|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiSafetyAmbassador: SafetyAmbassadorApiInterface = response.data.data;

                if (apiSafetyAmbassador)
                {
                    return SafetyAmbassadorTransformer.fromApi(apiSafetyAmbassador, apiSafetyAmbassador.associations);
                }
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set safetyAmbassadorId(safetyAmbassadorId: number)
    {
        this._safetyAmbassadorId = safetyAmbassadorId;
    }

    set field(field: string)
    {
        this._field = field;
    }

    set file(file: File)
    {
        this._file = file;
    }

    protected getUrl(): string
    {
        return `/safety-ambassadors/${ this._safetyAmbassadorId }/image-upload`;
    }

    protected getData(): FormData
    {
        const data = new FormData();
        data.append('safety_ambassador_id', this._safetyAmbassadorId.toString());
        data.append('field', this._field.toString());
        data.append('file', this._file);

        return data;
    }
}