import { merge } from 'lodash';
import GetInterfaceTranslationsTask from '../../tasks/api/translations/get-interface-translations';
import PostInterfaceTranslationsTask from '../../tasks/api/translations/post-interface-translations';
import defaultMessages from '../../../i18n/translations/messages';

export type SupportedLocales = 'en' | 'nl-BE';

export default class TranslationService
{
    /**
     * Returns the interface translations
     */

    public async loadInterfaceTranslations(): Promise<any>
    {
        try
        {
            // Use the default translations from source
            const translations = defaultMessages;

            // Load overrides from the server
            const task = new GetInterfaceTranslationsTask();
            const overrideTranslations: any = await task.run();

            // Merge over the defaults if the server has overrides
            if (overrideTranslations)
            {
                merge(translations, overrideTranslations);
            }

            return translations;
        }
        catch (error)
        {
            return defaultMessages;
        }
    }

    /**
     * Saves the interface translations
     *
     * @param translations
     */

    public async saveInterfaceTranslations(translations: any): Promise<boolean>
    {
        const task = new PostInterfaceTranslationsTask();
        task.translations = translations;

        return task.run();
    }
}