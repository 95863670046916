import AbstractApiGetTask from '../abstract-api-get-task';
import User from '@/model/entities/organisation/user';
import UserApiInterface from '@/model/entities/organisation/user-api-interface';
import UserResultsInterface from '@/model/vos/organisation/user-results-interface';
import UserSearchConditionsInterface from '@/model/vos/organisation/user-search-conditions-interface';
import UserSearchConditionsService from '@/model/services/organisation/user-search-conditions-service';
import UserTransformer from '@/model/transformers/organisation/user-transformer';

export default class SearchUsersTask extends AbstractApiGetTask
{
    private _conditions!: UserSearchConditionsInterface;

    public async run(): Promise<UserResultsInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiUsers: UserApiInterface[] = response.data.data.results;

                const users: User[] = [];
                for (const apiUser of apiUsers)
                {
                    try
                    {
                        // Parse the user.
                        users.push(UserTransformer.fromApi(apiUser));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse User');
                        console.error(error);
                    }
                }

                return {
                    results: users,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: UserSearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/users';
    }

    protected getParams(): any
    {
        const searchConditionsService = new UserSearchConditionsService();
        const params = searchConditionsService.getQueryParamsFromConditions(
            this._conditions, this._conditions.limit ?? 20, this._conditions.sortBy ?? 'first_name',
        );

        params.limit = this._conditions.limit;

        return params;
    }
}