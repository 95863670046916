import { Component, Prop, Vue } from 'vue-property-decorator';
import { size } from 'lodash';
import LoginFormValidator from '@/model/validator/authentication/login-form-validator';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({})
export default class LoginFormComponent extends Vue
{
    private email = '';
    private password = '';

    private validationErrors: ValidationErrorsInterface = {};

    /**
     * Returns whether the entire form is valid.
     */

    private get isValid(): boolean
    {
        const validator: LoginFormValidator = new LoginFormValidator();
        this.validationErrors = validator.validate(this.email, this.password);

        return size(this.validationErrors) === 0;
    }

    /**
     * Handles submissions.
     */

    private onSubmit()
    {
        if (!this.isValid)
        {
            return;
        }

        this.$emit(
            'submitted', this.email, this.password,
        );
    }
}