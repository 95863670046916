import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import CategoryTransformer from './category-transformer';
import Scene from '../../entities/webscenes/scene';
import SceneApiInterface from '../../entities/webscenes/scene-api-interface';
import SceneQuestionApiInterface from '../../entities/webscenes/scene-question-api-interface';
import SceneQuestionTransformer from './scene-question-transformer';

export default class SceneTransformer
{
    /**
     * Transforms an API scene representation into a Scene entity.
     *
     * @param apiScene
     */

    public static fromApi(apiScene: SceneApiInterface, associations?: AssociationsApiInterface): Scene
    {
        const scene = new Scene();
        scene.id = apiScene.id;
        scene.ref = apiScene.ref;
        scene.type = apiScene.type;
        scene.categoryIds = apiScene.category_ids ?? [];
        scene.locationIds = apiScene.location_ids ?? [];
        scene.name = apiScene.name;
        scene.description = apiScene.description;
        scene.introduction = apiScene.introduction;
        scene.image = apiScene.image ?? null;
        scene.sceneImage = apiScene.scene_image ?? null;
        scene.isEnabled = apiScene.enabled;
        scene.created = parseISO(apiScene.created);
        scene.updated = parseISO(apiScene.updated);

        // Associations.
        if (associations)
        {
            if (apiScene.category_ids && associations.categories)
            {
                for (const categoryId of apiScene.category_ids)
                {
                    if (!associations.categories[categoryId])
                    {
                        continue;
                    }

                    const category = CategoryTransformer.fromApi(associations.categories[categoryId], associations);
                    scene.categories.push(category);
                }
            }

            if (apiScene.question_ids)
            {
                for (const questionId of apiScene.question_ids)
                {
                    if (!associations.scenes_questions?.[questionId])
                    {
                        continue;
                    }

                    const question = SceneQuestionTransformer.fromApi(associations.scenes_questions[questionId], associations);
                    scene.questions.push(question);
                }
            }
        }

        // Parent linking of associations.
        for (const question of scene.questions)
        {
            question.scene = scene;

            for (const answer of question.answers)
            {
                answer.question = question;
            }
        }

        return scene;
    }

    /**
     * Transforms a Scene entity into an API representation of a scene.
     *
     * @param scene
     */

    public static toApi(scene: Scene): SceneApiInterface
    {
        // Get associations.
        const questions: SceneQuestionApiInterface[] = [];
        for (const question of scene.questions)
        {
            questions.push(SceneQuestionTransformer.toApi(question));
        }

        return {
            id: scene.id,
            ref: scene.ref,
            type: scene.type,
            category_ids: scene.categoryIds,
            location_ids: scene.locationIds,
            name: scene.name,
            description: scene.description,
            introduction: scene.introduction,
            image: scene.image ?? undefined,
            scene_image: scene.sceneImage ?? undefined,
            enabled: scene.isEnabled,
            created: formatISO(scene.created),
            updated: formatISO(scene.updated),
            associations:
            {
                scenes_questions: questions,
            },
        };
    }
}