import LoginTask from '@/model/tasks/api/organisation/login';
import RefreshTokenTask from '@/model/tasks/api/organisation/refresh-token';
import RegisterTask from '@/model/tasks/api/organisation/register';
import RequestResetPasswordTask from '@/model/tasks/api/organisation/request-reset-password';
import ResetPasswordTask from '@/model/tasks/api/organisation/reset-password';
import User from '@/model/entities/organisation/user';
import store from '@/store';

export default class AuthenticationService
{
    /**
     * Registers a user.
     *
     * @param user
     */

    public async registerUser(user: User, password: string): Promise<boolean>
    {
        const task = new RegisterTask();
        task.user = user;
        task.password = password;

        return task.run();
    }

    /**
     * Requests for a password to be reset.
     *
     * @param email
     */

    public async requestResetPassword(email: string): Promise<boolean>
    {
        const task = new RequestResetPasswordTask();
        task.email = email;

        return task.run();
    }

    /**
     * Resets a password.
     *
     * @param key
     * @param password
     */

    public async resetPassword(key: string, password: string): Promise<boolean>
    {
        const task = new ResetPasswordTask();
        task.key = key;
        task.password = password;

        return task.run();
    }

    /**
     * Attempts to log a user in using a standard email and password.
     *
     * @param email
     * @param password
     */

    public async login(email: string, password: string)
    {
        const task = new LoginTask();
        task.email = email;
        task.password = password;

        const user = await task.run();

        // Update the store with the user.
        if (user)
        {
            store.commit('app/user', user);
        }

        return user;
    }

    /**
     * Logs the user out.
     */

    public async logout()
    {
        store.commit('app/user', null);
    }

    /**
     * Refreshes the api token.
     */

    public async refreshToken()
    {
        const task = new RefreshTokenTask();

        return task.run();
    }
}