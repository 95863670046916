import { Component, Prop, Vue } from 'vue-property-decorator';
import SceneQuestion from '../../../model/entities/webscenes/scene-question';
import SceneQuestionAnswer from '../../../model/entities/webscenes/scene-question-answer';

@Component({
    components:
    {},
})
export default class SceneQuestionComponent extends Vue
{
    @Prop()
    private question!: SceneQuestion;

    private selectedAnswer: SceneQuestionAnswer|null = null;

    /**
     * Returns the image uri for the scene.
     */

    get imageUri(): string|null
    {
        return this.selectedAnswer && this.selectedAnswer.image
            ? this.$serviceContainer.getSceneService().getSceneQuestionAnswerImageUri(this.selectedAnswer)
            : this.$serviceContainer.getSceneService().getSceneQuestionImageUri(this.question);
    }

    /**
     * Handles selection of an answer.
     *
     * @param answer
     */

    private async onAnswer(answer: SceneQuestionAnswer)
    {
        // Save the answer selection.
        const details = await this.$serviceContainer.getSceneService().saveUserAnswer(answer);
        if (!details)
        {
            return;
        }

        // Mark the selected answer.
        this.selectedAnswer = Object.assign({}, answer);

        // If the answer has selection text and/or image show this now.
        let closeQuestion: boolean;
        if ('en' in details.text || details.image)
        {
            closeQuestion = false;

            this.selectedAnswer.selectionText = details.text;
            this.selectedAnswer.image = details.image;
        }
        else
        {
            closeQuestion = true;
        }

        this.$emit(
            'answered', this.question, answer, closeQuestion,
        );
    }

    /**
     * Handles close button clicks.
     */

    private onClose()
    {
        this.$emit('close');
    }
}