import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isNil } from 'lodash';
import EmployeeSearchConditionsInterface from '../../../model/vos/organisation/employee-search-conditions-interface';
import Team from '@/model/entities/organisation/team';

@Component({
})
export default class EmployeeFiltersComponent extends Vue
{
    @Prop()
    private filters!: EmployeeSearchConditionsInterface;

    @Prop()
    private locations!: Location[];

    @Prop()
    private teams!: Team[];

    private isVisible = false;

    private formFilters: EmployeeSearchConditionsInterface = {
        employeeNumber: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        locationId: undefined,
        teamId: undefined,
    };

    private filterKeys: (keyof EmployeeSearchConditionsInterface)[] = [
        'employeeNumber',
        'firstName',
        'lastName',
        'email',
        'locationId',
        'teamId',
    ];

    created()
    {
        Object.assign(this.formFilters, this.filters);
    }

    /**
     * Returns whether any filters are active.
     */

    get isFiltersActive(): boolean
    {
        for (const key of this.filterKeys)
        {
            if (!isNil(this.filters[key]))
            {
                return true;
            }
        }

        return false;
    }

    @Watch('filters')
    private onFiltersChange()
    {
        Object.assign(this.formFilters, this.filters);
    }

    /**
     * Handles reset requests.
     */

    private onResetFilters()
    {
        for (const key of this.filterKeys)
        {
            this.formFilters[key] = undefined;
        }

        this.$emit('update', this.formFilters);
    }

    /**
     * Handles update requests.
     */

    private onUpdateFilters()
    {
        this.$emit('update', this.formFilters);
    }

    /**
     * Handles close requests.
     */

    private onCloseFilters()
    {
        this.isVisible = false;
    }
}