import DeleteAccountTask from '@/model/tasks/api/organisation/delete-account';
import DeleteUserTask from '@/model/tasks/api/organisation/delete-user';
import GetUserTask from '@/model/tasks/api/organisation/get-user';
import PatchUserTask from '@/model/tasks/api/organisation/patch-user';
import PostUserTask from '@/model/tasks/api/organisation/post-user';
import SearchUsersTask from '@/model/tasks/api/organisation/search-users';
import User from '@/model/entities/organisation/user';
import UserResultsInterface from '@/model/vos/organisation/user-results-interface';
import UserSearchConditionsInterface from '@/model/vos/organisation/user-search-conditions-interface';

export default class UserService
{
    /**
     * Returns users matching the conditions.
     *
     * @param conditions
     */

    public async searchUsers(conditions: UserSearchConditionsInterface): Promise<UserResultsInterface|null>
    {
        const task = new SearchUsersTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a user.
     *
     * @param id
     */

    public async loadUser(id: number): Promise<User|null>
    {
        const task = new GetUserTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a user.
     *
     * @param user
     */

    public async createUser(user: User): Promise<User>
    {
        const task = new PostUserTask();
        task.user = user;

        return task.run();
    }

    /**
     * Updates a user.
     *
     * @param user
     */

    public async updateUser(user: User): Promise<User>
    {
        const task = new PatchUserTask();
        task.user = user;

        return task.run();
    }

    /**
     * Deletes a user.
     *
     * @param id
     */

    public async deleteUser(id: number): Promise<void>
    {
        const task = new DeleteUserTask();
        task.id = id;

        return task.run();
    }

    /**
     * Deletes the user's account.
     */

    public async deleteAccount()
    {
        const task = new DeleteAccountTask();

        return task.run();
    }

    /**
     * Returns whether the user has one of the roles.
     *
     * @param user
     * @param roles
     */

    public static userHasRole(user: User, roles: string[]): boolean
    {
        for (const role of roles)
        {
            if (user.roles.indexOf(role) > -1)
            {
                return true;
            }
        }

        return false;
    }
}