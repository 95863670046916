import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        dark: true,

        options: {
            customProperties: true,
        },

        themes: {
            dark: {
                primary: '#19467F',
                secondary: '#00875E',
                tertiary: '#3C89CD',
                dark: '#090909',
                light: '#FFFFFF',
                black: '#000000',
                nav: '#595959',
                search: '#0A345C',
                background: '#B3B3B1',
            },
        },
    },
});