import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import UserSceneAnswer from '../../../entities/webscenes/user-scene-answer';
import UserSceneAnswerApiInterface from '../../../entities/webscenes/user-scene-answer-api-interface';
import UserSceneAnswerTransformer from '../../../transformers/webscenes/user-scene-answer-transformer';

export default class GetUserAnswersTask extends AbstractApiGetTask
{
    private _sceneId!: number;

    public async run(): Promise<UserSceneAnswer[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiAnswers: UserSceneAnswerApiInterface[] = response.data.data;

                const answers: UserSceneAnswer[] = [];
                for (const apiAnswer of apiAnswers)
                {
                    try
                    {
                        // Parse the answer.
                        answers.push(UserSceneAnswerTransformer.fromApi(apiAnswer));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse User Answer');
                        console.error(error);
                    }
                }

                return answers;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    set sceneId(sceneId: number)
    {
        this._sceneId = sceneId;
    }

    protected getUrl(): string
    {
        return `/scenes/${ this._sceneId }/answers`;
    }
}