import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isEqual } from 'lodash';

@Component({
    components:
    {
    },
})
export default class TagsInputComponent extends Vue
{
    @Prop()
    private label!: string;

    @Prop()
    private tags!: string[];

    @Prop({
        type: Boolean,
        default: false,
    })
    private box!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private solo!: boolean;

    @Prop()
    private appendOuterIcon!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private readonly!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private error!: boolean;

    @Prop()
    private errorMessages!: string[];

    $refs!:
    {
        combobox: any
    };

    private searchTerm = '';
    private selectedTags: string[] = [];
    private selectorTags: string[] = [];
    private isLoading = false;

    private timeout: number|null = null;

    created()
    {
        this.updateSelectedTagsFromProp();
    }

    @Watch('tags')
    private onTagsUpdated()
    {
        this.updateSelectedTagsFromProp();
    }

    /**
     * Returns the read-only vlaue.
     */

    get readonlyValue()
    {
        return this.tags.join(', ');
    }

    /**
     * Handles change events from the autocomplete.
     *
     * @param selectedTags
     */

    private onChange(selectedTags: string[])
    {
        // Emit a change event if the tags are different than original props.
        const isSame = isEqual(this.tags.slice(0).sort(), this.selectedTags.slice(0).sort());
        if (!isSame)
        {
            this.$emit('change', selectedTags);
        }

        // Close the dropdown.
        this.$refs.combobox.isMenuActive = false;
    }

    /**
     * Handles the auto-complete lookups for tags.
     *
     * @param searchTerm
     */

    @Watch('searchTerm')
    private searchTags(searchTerm: string)
    {
        if (!searchTerm || searchTerm.length < 3)
        {
            return;
        }

        this.isLoading = true;

        if (this.timeout)
        {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(async() =>
        {
            this.selectorTags = await this.$serviceContainer.getTagService().getTags(searchTerm);
            this.isLoading = false;
        }, 500);
    }

    /**
     * Updates the selected contacts based on the contacts prop.
     */

    private updateSelectedTagsFromProp()
    {
        this.selectedTags = this.tags ? this.tags.slice() : [];

        if (this.tags)
        {
            this.selectorTags = this.tags.slice();
        }
    }
}