import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import RequestResetPasswordFormComponent from '@/components/widgets/request-reset-password-form/index.vue';
import ResetPasswordFormComponent from '@/components/widgets/reset-password-form/index.vue';

@Component({
    components:
    {
        'vp-reset-password-form': ResetPasswordFormComponent,
        'vp-request-reset-password-form': RequestResetPasswordFormComponent,
    },
})
export default class ResetPasswordView extends Vue
{
    private isRequesting = false;
    private isResetting = false;

    private hasRequestedResetPassord = false;
    private hasResetPassword = false;

    private resetKey = '';

    private isLoading = false;

    created()
    {
        // Resetting with a key.
        if (this.$route.params.key)
        {
            this.isResetting = true;
            this.resetKey = this.$route.params.key as string;
        }

        // Requesting a reset.
        else
        {
            this.isRequesting = true;
        }
    }

    /**
     * Handles password reset requests.
     *
     * @param email
     */

    private async onRequestResetPassword(email: string)
    {
        try
        {
            this.isLoading = true;
            await this.$serviceContainer.getAuthenticationService().requestResetPassword(email);

            this.hasRequestedResetPassord = true;
        }
        catch (error: any)
        {
            console.error(error);

            switch (error.message)
            {
            case 'password_already_reset':
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.password_already_reset'), 'error',
                );
                break;

            default:
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.problem_resetting_password'), 'error',
                );
            }
        }
        finally
        {
            this.isLoading = false;
        }
    }

    /**
     * Handles password resets.
     *
     * @param password
     */

    private async onResetPassword(password: string)
    {
        try
        {
            this.isLoading = true;
            await this.$serviceContainer.getAuthenticationService().resetPassword(this.resetKey, password);

            this.hasResetPassword = true;
        }
        catch (error: any)
        {
            console.error(error);

            switch (error.message)
            {
            case 'password_already_reset':
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.password_already_reset'), 'error',
                );
                break;

            default:
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.problem_resetting_password'), 'error',
                );
            }
        }
        finally
        {
            this.isLoading = false;
        }
    }
}