import AnswerSelectionApiInterface from '../../vos/webscenes/answer-selection-api-interface';
import DeleteSceneTask from '../../tasks/api/webscenes/delete-scene';
import GetActiveScenesTask from '../../tasks/api/webscenes/get-active-scenes';
import GetIncompleteScenesTask from '../../tasks/api/webscenes/get-incomplete-scenes';
import GetLatestScenesTask from '../../tasks/api/webscenes/get-latest-scenes';
import GetSceneTask from '../../tasks/api/webscenes/get-scene';
import GetUserAnswersTask from '../../tasks/api/webscenes/get-user-answers';
import GetUserSceneResultsTask from '../../tasks/api/webscenes/get-user-scene-results';
import PatchSceneTask from '../../tasks/api/webscenes/patch-scene';
import PostSceneTask from '../../tasks/api/webscenes/post-scene';
import PostUserAnswerTask from '../../tasks/api/webscenes/post-user-answer';
import RestartSceneTask from '../../tasks/api/webscenes/restart-scene';
import Scene from '../../entities/webscenes/scene';
import SceneQuestion from '../../entities/webscenes/scene-question';
import SceneQuestionAnswer from '../../entities/webscenes/scene-question-answer';
import SceneResultsApiInterface from '../../vos/webscenes/scene-results-api-interface';
import SceneSearchConditionsInterface from '../../vos/webscenes/scene-search-conditions-interface';
import SearchScenesTask from '../../tasks/api/webscenes/search-scenes';
import UploadFileTask from '@/model/tasks/api/file/upload-file';
import UserSceneAnswer from '../../entities/webscenes/user-scene-answer';
import UserSceneResultsApiInterface from '../../vos/webscenes/user-scene-results-api-interface';
import store from '@/store';

export default class SceneService
{
    /**
     * Returns all the active scenes.
     *
     * @param categoryId
     */

    public async getActiveScenes(categoryId: number): Promise<Scene[]>
    {
        const task = new GetActiveScenesTask();
        task.categoryId = categoryId;

        return task.run();
    }

    /**
     * Returns incomplete scenes.
     *
     */

    public async getIncompleteScenes(): Promise<Scene[]>
    {
        const task = new GetIncompleteScenesTask();

        return task.run();
    }

    /**
     * Restarts a scene.
     *
     * @param sceneId
     */

    public async restartScene(sceneId: number): Promise<void>
    {
        const task = new RestartSceneTask();
        task.sceneId = sceneId;

        return task.run();
    }

    /**
     * Returns all the selected answers for a scene.
     *
     * @param sceneId
     */

    public async getUserAnswers(sceneId: number): Promise<UserSceneAnswer[]>
    {
        const task = new GetUserAnswersTask();
        task.sceneId = sceneId;

        return task.run();
    }

    /**
     * Saves a user answer.
     *
     * @param answer
     */

    public async saveUserAnswer(answer: SceneQuestionAnswer): Promise<AnswerSelectionApiInterface|null>
    {
        const task = new PostUserAnswerTask();
        task.answer = answer;

        return task.run();
    }

    /**
     * Returns the scene results for a user.
     *
     * @param sceneId
     */

    public async getUserSceneResults(sceneId: number): Promise<UserSceneResultsApiInterface|null>
    {
        const task = new GetUserSceneResultsTask();
        task.id = sceneId;

        return task.run();
    }

    /**
     * Returns the latest scenes.
     *
     */

    public async getLatestScenes(): Promise<Scene[]>
    {
        const task = new GetLatestScenesTask();

        return task.run();
    }

    /**
     * Returns scenes matching the conditions.
     *
     * @param conditions
     */

    public async searchScenes(conditions: SceneSearchConditionsInterface): Promise<SceneResultsApiInterface|null>
    {
        const task = new SearchScenesTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a scene.
     *
     * @param id
     */

    public async loadScene(id: number|string): Promise<Scene|null>
    {
        const task = new GetSceneTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a scene.
     *
     * @param scene
     */

    public async createScene(scene: Scene): Promise<Scene>
    {
        const task = new PostSceneTask();
        task.scene = scene;

        return task.run();
    }

    /**
     * Updates a scene.
     *
     * @param scene
     */

    public async updateScene(scene: Scene): Promise<Scene>
    {
        const task = new PatchSceneTask();
        task.scene = scene;

        return task.run();
    }

    /**
     * Deletes an scene.
     *
     * @param id
     */

    public async deleteScene(id: number): Promise<void>
    {
        const task = new DeleteSceneTask();
        task.id = id;

        return task.run();
    }

    /**
     * Returns the image uri for a scene.
     *
     * @param scene
     */

    public getSceneImageUri(scene: Scene): string|null
    {
        if (!scene.id || !scene.image)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/scenes/${ scene.id }/image.jpg?token=${ this.getApiToken() }`;
    }

    /**
     * Returns the image uri for a scene's scene image.
     *
     * @param scene
     */

    public getSceneSceneImageUri(scene: Scene): string|null
    {
        if (!scene.id || !scene.sceneImage)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/scenes/${ scene.id }/image_scene.jpg?token=${ this.getApiToken() }`;
    }

    /**
     * Returns the image uri for a question.
     *
     * @param question
     */

    public getSceneQuestionImageUri(question: SceneQuestion): string|null
    {
        if (!question.id || !question.image)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/scenes/${ question.scene?.id }/questions/${ question.id }/image.jpg?token=${ this.getApiToken() }`;
    }

    /**
     * Returns the scene image uri for a question.
     *
     * @param question
     */

    public getSceneQuestionSceneImageUri(question: SceneQuestion): string|null
    {
        if (!question.id || !question.sceneImage)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/scenes/${ question.scene?.id }/questions/${ question.id }/image_scene.png?token=${ this.getApiToken() }`;
    }

    /**
     * Returns the scene glow image uri for a question.
     *
     * @param question
     */

    public getSceneQuestionSceneGlowImageUri(question: SceneQuestion): string|null
    {
        if (!question.id || !question.sceneImageGlow)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/scenes/${ question.scene?.id }/questions/${ question.id }/image_scene_glow.png?token=${ this.getApiToken() }`;
    }

    /**
     * Returns the image uri for an answer.
     *
     * @param answer
     */

    public getSceneQuestionAnswerImageUri(answer: SceneQuestionAnswer): string|null
    {
        if (!answer.id || !answer.image)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/scenes/${ answer.question?.scene?.id }/questions/${ answer.question?.id }/answers/${ answer.id }/${ answer.image }?token=${ this.getApiToken() }`;
    }

    /**
     * Uploads any updated files.
     *
     * @param scene
     * @param formScene
     */

    public async uploadFiles(scene: Scene, formScene: Scene)
    {
        // Scene image.
        if (formScene.imageFile)
        {
            await this.uploadSceneImage(scene.id, formScene.imageFile);
        }

        // Question images.
        for (const questionIndex in scene.questions)
        {
            const question = scene.questions[questionIndex];
            const formQuestion = formScene.questions[questionIndex];

            if (formQuestion.imageFile)
            {
                await this.uploadSceneQuestionImage(
                    scene.id,
                    question.id,
                    formQuestion.imageFile,
                );
            }

            // Answer images.
            for (const answerIndex in question.answers)
            {
                const answer = question.answers[answerIndex];
                const formAnswer = formQuestion.answers[answerIndex];

                if (formAnswer.imageFile)
                {
                    await this.uploadSceneQuestionAnswerImage(
                        scene.id,
                        question.id,
                        answer.id,
                        formAnswer.imageFile,
                    );
                }
            }
        }
    }

    /**
     * Uploads a new image for a scene.
     *
     * @param sceneId
     * @param file
     */

    public async uploadSceneImage(sceneId: number, file: File)
    {
        const task = new UploadFileTask();
        task.path = `/scenes/${ sceneId }/image.jpg`;
        task.name = 'file';
        task.file = file;

        return task.run();
    }

    /**
     * Uploads a new image for a question.
     *
     * @param sceneId
     * @param questionId
     * @param file
     */

    public async uploadSceneQuestionImage(
        sceneId: number,
        questionId: number,
        file: File,
    )
    {
        const task = new UploadFileTask();
        task.path = `/scenes/${ sceneId }/questions/${ questionId }/image.jpg`;
        task.name = 'file';
        task.file = file;

        return task.run();
    }

    /**
     * Uploads a new image for an answer.
     *
     * @param sceneId
     * @param questionId
     * @param answerId
     * @param file
     */

    public async uploadSceneQuestionAnswerImage(
        sceneId: number,
        questionId: number,
        answerId: number,
        file: File,
    )
    {
        const task = new UploadFileTask();
        task.path = `/scenes/${ sceneId }/questions/${ questionId }/answers/${ answerId }/image.jpg`;
        task.name = 'file';
        task.file = file;

        return task.run();
    }

    /**
     * Returns the API token
     *
     * @returns
     */

    private getApiToken(): string
    {
        return store.getters['app/user']?.token ?? '';
    }
}