import FileApiInterface from '../../vos/media/file-api-interface';
import Location from './location';

export default class SafetyAmbassador
{
    id = 0;
    firstName = '';
    lastName = '';
    image: FileApiInterface|null = null;
    locationId: number|null = null;
    location: Location|null = null;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
}