import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import AnswerSelectionApiInterface from '../../../vos/webscenes/answer-selection-api-interface';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import SceneQuestionAnswer from '../../../entities/webscenes/scene-question-answer';

export default class PostUserAnswerTask extends AbstractApiPostTask
{
    private _answer!: SceneQuestionAnswer;

    public async run(): Promise<AnswerSelectionApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return response.data.data;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set answer(answer: SceneQuestionAnswer)
    {
        this._answer = answer;
    }

    protected getUrl(): string
    {
        return `/scenes/${ this._answer.question?.scene?.id }/questions/${ this._answer.question?.id }/answers/${ this._answer.id }/select`;
    }

    protected getData(): any
    {
        return {
            locale: LocaleHelper.getLocale(),
        };
    }
}