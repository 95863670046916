import Role from '@/model/entities/organisation/role';
import RoleApiInterface from '@/model/entities/organisation/role-api-interface';

export default class RoleTransformer
{
    /**
     * Transforms an API role representation into a Role entity.
     *
     * @param apiRole
     */

    public static fromApi(apiRole: RoleApiInterface): Role
    {
        const role = new Role();
        role.id = apiRole.id;
        role.name = apiRole.name;
        role.description = apiRole.description;

        return role;
    }

    /**
     * Transforms a Role entity into an API representation of a role.
     *
     * @param role
     */

    public static toApi(role: Role): RoleApiInterface
    {
        return {
            id: role.id,
            name: role.name,
            description: role.description,
        };
    }
}