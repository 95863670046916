import ActivityService from './rewards/activity-service';
import AuthenticationService from './authentication/authentication-service';
import BannerService from './media/banner-service';
import CategoryService from './webscenes/category-service';
import EmployeeService from './organisation/employee-service';
import FirstAiderService from './organisation/first-aider-service';
import LocationService from './organisation/location-service';
import NewsletterService from './media/newsletter-service';
import NotificationService from './notifications/notification-service';
import PostService from './blog/post-service';
import RewardService from './rewards/reward-service';
import RoleService from './organisation/role-service';
import SafetyAmbassadorService from './organisation/safety-ambassador-service';
import SceneService from './webscenes/scene-service';
import TagService from './categorisation/tag-service';
import TeamService from './organisation/team-service';
import TranslationService from './translations/translation-service';
import UserService from './organisation/user-service';
import VideoService from './media/video-service';

export default class ServiceContainer
{
    private activityService!: ActivityService;
    private authenticationService!: AuthenticationService;
    private bannerService!: BannerService;
    private categoryService!: CategoryService;
    private employeeService!: EmployeeService;
    private firstAiderService!: FirstAiderService;
    private locationService!: LocationService;
    private newsletterService!: NewsletterService;
    private notificationService!: NotificationService;
    private postService!: PostService;
    private rewardService!: RewardService;
    private roleService!: RoleService;
    private safetyAmbassadorService!: SafetyAmbassadorService;
    private sceneService!: SceneService;
    private tagService!: TagService;
    private teamService!: TeamService;
    private translationService!: TranslationService;
    private userService!: UserService;
    private videoService!: VideoService;

    /**
     * Returns the Activity Service.
     */

    public getActivityService(): ActivityService
    {
        if (!this.activityService)
        {
            this.activityService = new ActivityService();
        }

        return this.activityService;
    }

    /**
     * Returns the Authentication Service.
     */

    public getAuthenticationService(): AuthenticationService
    {
        if (!this.authenticationService)
        {
            this.authenticationService = new AuthenticationService();
        }

        return this.authenticationService;
    }

    /**
     * Returns the Banner Service.
     */

    public getBannerService(): BannerService
    {
        if (!this.bannerService)
        {
            this.bannerService = new BannerService();
        }

        return this.bannerService;
    }

    /**
     * Returns the Category Service.
     */

    public getCategoryService(): CategoryService
    {
        if (!this.categoryService)
        {
            this.categoryService = new CategoryService();
        }

        return this.categoryService;
    }

    /**
     * Returns the Employee Service.
     */

    public getEmployeeService(): EmployeeService
    {
        if (!this.employeeService)
        {
            this.employeeService = new EmployeeService();
        }

        return this.employeeService;
    }

    /**
     * Returns the First Aider Service.
     */

    public getFirstAiderService(): FirstAiderService
    {
        if (!this.firstAiderService)
        {
            this.firstAiderService = new FirstAiderService();
        }

        return this.firstAiderService;
    }

    /**
     * Returns the Location Service.
     */

    public getLocationService(): LocationService
    {
        if (!this.locationService)
        {
            this.locationService = new LocationService();
        }

        return this.locationService;
    }

    /**
     * Returns the Newsletter Service.
     */

    public getNewsletterService(): NewsletterService
    {
        if (!this.newsletterService)
        {
            this.newsletterService = new NewsletterService();
        }

        return this.newsletterService;
    }

    /**
     * Returns the Notification Service.
     */

    public getNotificationService(): NotificationService
    {
        if (!this.notificationService)
        {
            this.notificationService = new NotificationService();
        }

        return this.notificationService;
    }

    /**
     * Returns the Post Service.
     */

    public getPostService(): PostService
    {
        if (!this.postService)
        {
            this.postService = new PostService();
        }

        return this.postService;
    }

    /**
     * Returns the Reward Service.
     */

    public getRewardService(): RewardService
    {
        if (!this.rewardService)
        {
            this.rewardService = new RewardService();
        }

        return this.rewardService;
    }

    /**
     * Returns the Role Service.
     */

    public getRoleService(): RoleService
    {
        if (!this.roleService)
        {
            this.roleService = new RoleService();
        }

        return this.roleService;
    }

    /**
     * Returns the Safety Ambassador Service.
     */

    public getSafetyAmbassadorService(): SafetyAmbassadorService
    {
        if (!this.safetyAmbassadorService)
        {
            this.safetyAmbassadorService = new SafetyAmbassadorService();
        }

        return this.safetyAmbassadorService;
    }

    /**
     * Returns the Scene Service.
     */

    public getSceneService(): SceneService
    {
        if (!this.sceneService)
        {
            this.sceneService = new SceneService();
        }

        return this.sceneService;
    }

    /**
     * Returns the Tag Service.
     */

    public getTagService(): TagService
    {
        if (!this.tagService)
        {
            this.tagService = new TagService();
        }

        return this.tagService;
    }

    /**
     * Returns the Team Service.
     */

    public getTeamService(): TeamService
    {
        if (!this.teamService)
        {
            this.teamService = new TeamService();
        }

        return this.teamService;
    }

    /**
     * Returns the Translation Service.
     */

    public getTranslationService(): TranslationService
    {
        if (!this.translationService)
        {
            this.translationService = new TranslationService();
        }

        return this.translationService;
    }

    /**
     * Returns the User Service.
     */

    public getUserService(): UserService
    {
        if (!this.userService)
        {
            this.userService = new UserService();
        }

        return this.userService;
    }

    /**
     * Returns the Video Service.
     */

    public getVideoService(): VideoService
    {
        if (!this.videoService)
        {
            this.videoService = new VideoService();
        }

        return this.videoService;
    }
}