import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Notification from '../../../../../model/entities/notifications/notification';
import NotificationResultsApiInterface from '../../../../../model/vos/notifications/notification-results-interface';
import NotificationSearchConditions from '../../../../../model/vos/notifications/notification-search-conditions';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminNotificationIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.notifications.message.index_title'),
                disabled: true,
                to: { name: 'admin-notification-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: 'Id',
                align: 'left',
                value: 'id',
            },
            {
                text: 'Title',
                align: 'left',
                value: 'title',
            },
            {
                text: 'Enabled',
                align: 'left',
                value: 'enabled',
            },
            {
                text: this.$t('general.field.options'),
                align: 'center',
                value: '',
                sortable: false,
            },
        ];
    }

    private results: NotificationResultsApiInterface = {
        results: [],
        total_results: 0,
        results_per_page: 50,
        total_pages: 0,
        page: 1,
        sort_by: 'id',
        descending: false,
    };

    private isLoading = true;

    private conditions: SearchConditionsInterface = {};

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        const searchConditions: NotificationSearchConditions = Object.assign(
            {}, conditions, {
                includeDisabled: true,
            },
        );

        this.conditions = Object.assign({}, searchConditions);

        const results = await this.$serviceContainer.getNotificationService().searchNotifications(searchConditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-notification-create' });
    }

    /**
     * Handles opening of notification.
     *
     * @param notification
     */

    private onOpenNotification(notification: Notification)
    {
        NavigationHelper.pushRoute({
            name: 'admin-notification-edit',
            params: { id: notification.id.toString() },
        });
    }

    /**
     * Handles deleting of notifications.
     *
     * @param notification
     */

    private onDeleteNotification(notification: Notification)
    {
        EventBus.instance.$emit(
            'confirm-dialog', this.$t('admin.notifications.message.delete_title'), this.$t('admin.notifications.message.delete_description'), () => this.deleteNotification(notification), true,
        );
    }

    /**
     * Deletes a notification
     *
     * @param notification
     */

    private async deleteNotification(notification: Notification)
    {
        try
        {
            await this.$serviceContainer.getNotificationService().deleteNotification(notification.id);
            this.loadResults(this.conditions);

            EventBus.instance.$emit(
                'notification', this.$t('admin.notifications.message.notification_deleted'), 'success',
            );
        }
        catch (error)
        {
            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_deleting_server_error'), 'error',
            );
        }
    }
}