import { Component, Vue } from 'vue-property-decorator';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import PostFeedbackTask from '../../../model/tasks/api/feedback/post-feedback';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
    },
})
export default class FeedbackComponent extends Vue
{
    private feedback = '';

    private isSubmitting = false;
    private isSubmitted = false;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.feedback'),
                disabled: false,
                to: { name: 'feedback' },
                exact: true,
            },
        ];
    }

    private onFeedbackUpdated(text: string)
    {
        this.feedback = text;
    }

    /**
     * Handles feedback submissions.
     */

    private async onSubmit()
    {
        this.isSubmitting = true;

        const task = new PostFeedbackTask();
        task.feedback = this.feedback;

        await task.run();

        this.isSubmitting = false;
        this.isSubmitted = true;
    }
}