import Category from './category';
import SceneQuestion from './scene-question';
import SceneSceneImageInterface from './scene-scene-image-interface';
import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Scene
{
    id = 0;
    ref = '';
    type = '';
    name: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    introduction: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: string|null = null;
    imageFile: File|null = null;
    sceneImage: SceneSceneImageInterface|null = null;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
    categoryIds: number[] = [];
    categories: Category[] = [];
    locationIds: number[] = [];
    questions: SceneQuestion[] = [];
}