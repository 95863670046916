import { Component, Vue } from 'vue-property-decorator';
import Activity from '../../../../../model/entities/rewards/activity';
import ActivityResultsApiInterface from '../../../../../model/vos/rewards/activity-results-api-interface';
import ButtonComponent from '@/components/widgets/button/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminActivityIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.activities.message.index_title'),
                disabled: true,
                to: { name: 'admin-activity-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            { text: this.$t('general.field.id'),
                align: 'left',
                value: 'id' },
            { text: this.$t('general.field.name'),
                align: 'left',
                value: 'name' },
            { text: this.$t('general.field.points'),
                align: 'left',
                value: 'points' },
            { text: this.$t('general.field.enabled'),
                align: 'left',
                value: 'enabled' },
        ];
    }

    private results: ActivityResultsApiInterface =
        {
            results: [],
            total_results: 0,
            results_per_page: 50,
            total_pages: 0,
            page: 1,
            sort_by: 'name',
            descending: false,
        };

    private isLoading = true;

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        const results = await this.$serviceContainer.getActivityService().searchActivities(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-activity-create' });
    }

    /**
     * Handles opening of activities.
     *
     * @param activity
     */

    private onOpenActivity(activity: Activity)
    {
        NavigationHelper.pushRoute({
            name: 'admin-activity-edit',
            params: { id: activity.id.toString() },
        });
    }
}