import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Activity
{
    id = 0;
    name: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    points = 0;
    isEnabled = false;
}