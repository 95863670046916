import AbstractValidator from '../abstract-validator';
import Team from '../../entities/organisation/team';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class TeamValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a team.
     *
     * @param Team
     */

    public validate(team: Team): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateName(team, this.errors);

        return this.errors;
    }

    private validateName(team: Team, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(team.name))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.name = errors;
        }
    }
}