import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Role from '@/model/entities/organisation/role';
import UserSearchConditionsInterface from '@/model/vos/organisation/user-search-conditions-interface';

@Component({})
export default class UserFiltersComponent extends Vue
{
    @Prop()
    private filters!: UserSearchConditionsInterface;

    @Prop()
    private roles?: Role[];

    private formFilters: UserSearchConditionsInterface = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        roleId: undefined,
        isDisabled: undefined,
        includeDisabled: false,
        isNewsletterRegistered: undefined,
        isMarketingRegistered: undefined,
        isPrivacyNoticeAccepted: undefined,
    };

    private filterKeys: (keyof UserSearchConditionsInterface)[] =
        [
            'firstName',
            'lastName',
            'email',
            'roleId',
            'isDisabled',
            'includeDisabled',
            'isNewsletterRegistered',
            'isMarketingRegistered',
            'isPrivacyNoticeAccepted',
        ];

    created()
    {
        Object.assign(this.formFilters, this.filters);
    }

    /**
     * Returns whether any filters are active.
     */

    get isFiltersActive(): boolean
    {
        for (const key of this.filterKeys)
        {
            if (this.filters[key] || this.filters[key] === false)
            {
                return true;
            }
        }

        return false;
    }

    @Watch('filters')
    private onFiltersChange()
    {
        Object.assign(this.formFilters, this.filters);
    }

    /**
     * Handles reset requests.
     */

    private onResetFilters()
    {
        for (const key of this.filterKeys)
        {
            this.formFilters[key] = undefined;
        }

        this.$emit('update', this.formFilters);
    }

    /**
     * Handles update requests.
     */

    private onUpdateFilters()
    {
        this.$emit('update', this.formFilters);
    }
}