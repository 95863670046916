import { Component, Vue } from 'vue-property-decorator';
import DividerComponent from '@/components/widgets/divider/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Reward from '../../../../model/entities/rewards/reward';

@Component({
    components:
    {
        'app-divider': DividerComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class RewardIndexPageComponent extends Vue
{
    private rewards: Reward[] = [];
    private isLoading = true;

    private expandedPanels: number[] = [];

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.rewards'),
                disabled: true,
                to: { name: 'reward-index' },
                exact: true,
            },
        ];
    }

    get panels()
    {
        const panels: {[points: number]: Reward[]} = {};

        for (const reward of this.rewards)
        {
            if (!(reward.points in panels))
            {
                panels[reward.points] = [];
            }

            panels[reward.points].push(reward);
        }

        return panels;
    }

    created()
    {
        this.loadRewards();
    }

    /**
     * Returns the URI for a reward image.
     *
     * @param reward
     */

    private getRewardImageUri(reward: Reward): string|null
    {
        return this.$serviceContainer.getRewardService().getRewardImageUri(reward);
    }

    /**
     * Loads the rewards.
     */

    private async loadRewards()
    {
        this.isLoading = true;
        this.rewards = await this.$serviceContainer.getRewardService().getActiveRewards();

        for (let i = 0; i < this.rewards.length; i++)
        {
            this.expandedPanels.push(i);
        }

        this.isLoading = false;
    }

    /**
     * Handles claim button.
     */

    private onClaim()
    {
        window.open('/assets/documents/NB Safety Fax Reward - Online.pdf', '_blank');
    }
}