import Activity from '../../entities/rewards/activity';
import ActivityResultsApiInterface from '../../vos/rewards/activity-results-api-interface';
import DeleteActivityTask from '../../tasks/api/rewards/delete-activity';
import GetActivityTask from '../../tasks/api/rewards/get-activity';
import PatchActivityTask from '../../tasks/api/rewards/patch-activity';
import PostActivityTask from '../../tasks/api/rewards/post-activity';
import SearchActivitiesTask from '../../tasks/api/rewards/search-activities';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

export default class ActivityService
{
    /**
     * Returns activitys matching the conditions.
     *
     * @param conditions
     */

    public async searchActivities(conditions: SearchConditionsInterface): Promise<ActivityResultsApiInterface|null>
    {
        const task = new SearchActivitiesTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a activity.
     *
     * @param id
     */

    public async loadActivity(id: number|string): Promise<Activity|null>
    {
        const task = new GetActivityTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a activity.
     *
     * @param activity
     */

    public async createActivity(activity: Activity): Promise<Activity>
    {
        const task = new PostActivityTask();
        task.activity = activity;

        return task.run();
    }

    /**
     * Updates a activity.
     *
     * @param activity
     */

    public async updateActivity(activity: Activity): Promise<Activity>
    {
        const task = new PatchActivityTask();
        task.activity = activity;

        return task.run();
    }

    /**
     * Deletes an activity.
     *
     * @param id
     */

    public async deleteActivity(id: number): Promise<void>
    {
        const task = new DeleteActivityTask();
        task.id = id;

        return task.run();
    }
}