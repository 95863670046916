import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Reward from '../../../../../model/entities/rewards/reward';
import RewardFormComponent from '@/components/widgets/reward-form/index.vue';

@Component({
    components:
    {
        'app-reward-form': RewardFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminRewardCreatePageComponent extends Vue
{
    private reward: Reward|null = null;

    created()
    {
        this.reward = Object.assign({}, new Reward());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.rewards.message.index_title'),
                disabled: false,
                to: { name: 'admin-reward-index' },
                exact: true,
            },
            {
                text: this.$t('admin.rewards.message.create_title'),
                disabled: true,
                to: { name: 'admin-reward-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param reward
     */

    private async onSave(reward: Reward)
    {
        try
        {
            // Create the reward.
            const created = await this.$serviceContainer.getRewardService().createReward(reward);

            // Upload image.
            await this.$serviceContainer.getRewardService().uploadRewardImage(created, reward);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.rewards.message.reward_created'));

            // Redirect to the reward.
            NavigationHelper.pushRoute({
                name: 'admin-reward-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}