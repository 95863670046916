import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import MenuItemInterface from '@/model/vos/navigation/menu-item-interface';

@Component({
    components:
    {},
})
export default class AdminNavigationDrawerComponent extends Vue
{
    private isVisible = false;

    private items: MenuItemInterface[] =
        [
            {
                route: { name: 'admin-activity-index' },
                icon: 'mdi-history',
                title: 'admin.nav.activities',
                active: false,
            },
            {
                route: { name: 'admin-banner-index' },
                icon: 'mdi-image',
                title: 'admin.nav.banners',
                active: false,
            },
            {
                route: { name: 'admin-category-index' },
                icon: 'mdi-shape',
                title: 'admin.nav.categories',
                active: false,
            },
            {
                route: { name: 'admin-employee-index' },
                icon: 'mdi-account-clock',
                title: 'admin.nav.employees',
                active: false,
            },
            {
                route: { name: 'admin-first-aider-index' },
                icon: 'mdi-bandage',
                title: 'admin.nav.first_aiders',
                active: false,
            },
            {
                route: { name: 'admin-newsletter-index' },
                icon: 'mdi-newspaper',
                title: 'admin.nav.newsletters',
                active: false,
            },
            {
                route: { name: 'admin-notification-index' },
                icon: 'mdi-bell-ring',
                title: 'admin.nav.notifications',
                active: false,
            },

            // {
            //     route: {name: 'admin-post-index'},
            //     icon: 'mdi-newspaper',
            //     title: 'admin.nav.posts',
            //     active: false
            // },
            {
                route: { name: 'admin-reward-index' },
                icon: 'mdi-trophy',
                title: 'admin.nav.rewards',
                active: false,
            },
            {
                route: { name: 'admin-safety-ambassador-index' },
                icon: 'mdi-safety-goggles',
                title: 'admin.nav.safety_ambassadors',
                active: false,
            },
            {
                route: { name: 'admin-scene-index' },
                icon: 'mdi-image-outline',
                title: 'admin.nav.scenes',
                active: false,
            },
            {
                route: { name: 'admin-team-index' },
                icon: 'mdi-account-group',
                title: 'admin.nav.teams',
                active: false,
            },
            {
                route: { name: 'admin-interface-translations-edit' },
                icon: 'mdi-alphabetical',
                title: 'admin.nav.translations',
                active: false,
            },
            {
                route: { name: 'admin-user-index' },
                icon: 'mdi-account',
                title: 'admin.nav.users',
                active: false,
            },
            {
                route: { name: 'admin-video-index' },
                icon: 'mdi-video',
                title: 'admin.nav.videos',
                active: false,
            },
        ];

    created()
    {
        EventBus.instance.$on('toggle-admin-nav', () => this.toggle());
    }

    /**
     * Toggles visibility.
     */

    private toggle()
    {
        this.isVisible = !this.isVisible;
    }
}