import AbstractValidator from '../abstract-validator';
import SafetyAmbassador from '../../entities/organisation/safety-ambassador';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class SafetyAmbassadorValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a safetyAmbassador.
     *
     * @param SafetyAmbassador
     */

    public validate(safetyAmbassador: SafetyAmbassador): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateFirstName(safetyAmbassador, this.errors);
        this.validateLastName(safetyAmbassador, this.errors);

        return this.errors;
    }

    private validateFirstName(safetyAmbassador: SafetyAmbassador, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(safetyAmbassador.firstName))
        {
            errors.push(this.i18n.t('authentication.message.please_enter_a_first_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.firstName = errors;
        }
    }

    private validateLastName(safetyAmbassador: SafetyAmbassador, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(safetyAmbassador.lastName))
        {
            errors.push(this.i18n.t('authentication.message.please_enter_a_last_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.lastName = errors;
        }
    }
}