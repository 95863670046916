import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Post from '../../../model/entities/blog/post';
import PostResultsApiInterface from '../../../model/vos/blog/post-results-api-interface';
import PostSearchConditionsInterface from '../../../model/vos/blog/post-search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class BlogComponent extends Vue
{
    private results: PostResultsApiInterface|null = null;
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.blog'),
                disabled: false,
                to: { name: 'blog' },
                exact: true,
            },
        ];
    }

    created()
    {
        this.loadPosts();
    }

    /**
     * Returns the current page
     */

    get page(): number
    {
        if ('page' in this.$route.query)
        {
            let page = parseInt(this.$route.query.page.toString());
            if (isNaN(page))
            {
                page = 1;
            }

            return page;
        }
        else
        {
            return 1;
        }
    }

    /**
     * Returns the uri for a post's image
     *
     * @param post
     */

    private getPostImageUri(post: Post)
    {
        if (!post.image)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + post.image.uri;
    }

    private async loadPosts()
    {
        const conditions: PostSearchConditionsInterface = {
            searchTerm: undefined,
            limit: 20,
            page: this.page,
            sortBy: 'published',
            descending: true,
        };

        this.isLoading = true;

        const results = await this.$serviceContainer.getPostService().searchPosts(conditions);
        this.results = Object.assign({}, results);

        this.isLoading = false;
    }
}