import AssociationsApiInterface from '../../entities/associations-api-interface';
import SceneQuestion from '../../entities/webscenes/scene-question';
import SceneQuestionAnswerApiInterface from '../../entities/webscenes/scene-question-answer-api-interface';
import SceneQuestionAnswerTransformer from './scene-question-answer-transformer';
import SceneQuestionApiInterface from '../../entities/webscenes/scene-question-api-interface';
import SceneTransformer from './scene-transformer';

export default class SceneQuestionTransformer
{
    /**
     * Transforms an API scene question representation into a SceneQuestion entity.
     *
     * @param apiScene
     */

    public static fromApi(apiQuestion: SceneQuestionApiInterface, associations?: AssociationsApiInterface): SceneQuestion
    {
        const question = new SceneQuestion();
        question.id = apiQuestion.id;
        question.sceneId = apiQuestion.scene_id;
        question.text = apiQuestion.text;
        question.image = apiQuestion.image;
        question.sceneImage = apiQuestion.scene_image ?? null;
        question.sceneImageGlow = apiQuestion.scene_image_glow ?? null;
        question.displayOrder = apiQuestion.display_order;
        question.isEnabled = apiQuestion.enabled;

        // Associations.
        if (associations)
        {
            if (associations.scenes && associations.scenes[question.sceneId])
            {
                const scene = SceneTransformer.fromApi(associations.scenes[question.sceneId]);
                question.scene = scene;
            }

            if (apiQuestion.answer_ids)
            {
                for (const answerId of apiQuestion.answer_ids)
                {
                    if (!associations.scenes_questions_answers?.[answerId])
                    {
                        continue;
                    }

                    const answer = SceneQuestionAnswerTransformer.fromApi(associations.scenes_questions_answers[answerId], associations);
                    question.answers.push(answer);
                }
            }
        }

        return question;
    }

    /**
     * Transforms a SceneQuestion entity into an API representation of a scene question.
     *
     * @param question
     */

    public static toApi(question: SceneQuestion): SceneQuestionApiInterface
    {
        // Get associations.
        const answers: SceneQuestionAnswerApiInterface[] = [];
        for (const answer of question.answers)
        {
            answers.push(SceneQuestionAnswerTransformer.toApi(answer));
        }

        return {
            id: question.id,
            scene_id: question.sceneId,
            text: question.text,
            image: question.image,
            scene_image: question.sceneImage ?? undefined,
            scene_image_glow: question.sceneImageGlow ?? undefined,
            display_order: question.displayOrder,
            enabled: question.isEnabled,
            associations:
            {
                scenes_questions_answers: answers,
            },
        };
    }
}