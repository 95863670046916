import { Component, Prop, Vue } from 'vue-property-decorator';
import Category from '../../../model/entities/webscenes/category';

@Component({
    components:
    {},
})
export default class CategoryButtonComponent extends Vue
{
    @Prop()
    private category!: Category;
}