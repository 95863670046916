import Category from '../../entities/webscenes/category';
import CategoryResultsApiInterface from '../../vos/webscenes/category-results-api-interface';
import DeleteCategoryTask from '../../tasks/api/webscenes/delete-category';
import GetActiveCategoriesTask from '../../tasks/api/webscenes/get-active-categories';
import GetCategoryTask from '../../tasks/api/webscenes/get-category';
import PatchCategoryTask from '../../tasks/api/webscenes/patch-category';
import PostCategoryTask from '../../tasks/api/webscenes/post-category';
import SearchCategoriesTask from '../../tasks/api/webscenes/search-categories';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

export default class CategoryService
{
    /**
     * Returns all the active categories.
     *
     */

    public async getActiveCategories(): Promise<Category[]>
    {
        const task = new GetActiveCategoriesTask();

        return task.run();
    }

    /**
     * Returns categories matching the conditions.
     *
     * @param conditions
     */

    public async searchCategories(conditions: SearchConditionsInterface): Promise<CategoryResultsApiInterface|null>
    {
        const task = new SearchCategoriesTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a category.
     *
     * @param id
     */

    public async loadCategory(id: number|string): Promise<Category|null>
    {
        const task = new GetCategoryTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a category.
     *
     * @param category
     */

    public async createCategory(category: Category): Promise<Category>
    {
        const task = new PostCategoryTask();
        task.category = category;

        return task.run();
    }

    /**
     * Updates a category.
     *
     * @param category
     */

    public async updateCategory(category: Category): Promise<Category>
    {
        const task = new PatchCategoryTask();
        task.category = category;

        return task.run();
    }

    /**
     * Deletes an category.
     *
     * @param id
     */

    public async deleteCategory(id: number): Promise<void>
    {
        const task = new DeleteCategoryTask();
        task.id = id;

        return task.run();
    }
}