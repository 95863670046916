import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import FirstAider from '../../../entities/organisation/first-aider';
import FirstAiderApiInterface from '../../../entities/organisation/first-aider-api-interface';
import FirstAiderTransformer from '../../../transformers/organisation/first-aider-transformer';

export default class GetActiveFirstAidersTask extends AbstractApiGetTask
{
    private _type!: string;

    public async run(): Promise<FirstAider[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiFirstAiders: FirstAiderApiInterface[] = response.data.data;

                const firstAiders: FirstAider[] = [];
                for (const apiFirstAider of apiFirstAiders)
                {
                    try
                    {
                        // Parse the first aider.
                        firstAiders.push(FirstAiderTransformer.fromApi(apiFirstAider, apiFirstAider.associations));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse FirstAider');
                        console.error(error);
                    }
                }

                return firstAiders;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    public set type(type: string)
    {
        this._type = type;
    }

    protected getUrl(): string
    {
        return `/first-aiders/active?type=${ this._type }`;
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}