import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Newsletter from '../../entities/media/newsletter';
import NewsletterApiInterface from '../../entities/media/newsletter-api-interface';

export default class NewsletterTransformer
{
    /**
     * Transforms an API newsletter representation into a Newsletter entity.
     *
     * @param apiNewsletter
     */

    public static fromApi(apiNewsletter: NewsletterApiInterface, associations?: AssociationsApiInterface): Newsletter
    {
        const newsletter = new Newsletter();
        newsletter.id = apiNewsletter.id;
        newsletter.title = apiNewsletter.title;
        newsletter.description = apiNewsletter.description;
        newsletter.file = apiNewsletter.file;
        newsletter.releaseDate = parseISO(apiNewsletter.release_date);
        newsletter.isEnabled = apiNewsletter.enabled;
        newsletter.created = parseISO(apiNewsletter.created);
        newsletter.updated = parseISO(apiNewsletter.updated);

        return newsletter;
    }

    /**
     * Transforms a Newsletter entity into an API representation of a newsletter.
     *
     * @param newsletter
     */

    public static toApi(newsletter: Newsletter): NewsletterApiInterface
    {
        return {
            id: newsletter.id,
            title: newsletter.title,
            description: newsletter.description,
            file: newsletter.file,
            release_date: formatISO(newsletter.releaseDate),
            enabled: newsletter.isEnabled,
            created: formatISO(newsletter.created),
            updated: formatISO(newsletter.updated),
        };
    }
}