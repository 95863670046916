import { Component, Vue } from 'vue-property-decorator';
import Banner from '../../../../../model/entities/media/banner';
import BannerFileInterface from '../../../../../model/vos/media/banner-file-interface';
import BannerFormComponent from '@/components/widgets/banner-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-banner-form': BannerFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminBannerCreatePageComponent extends Vue
{
    private banner: Banner|null = null;

    created()
    {
        this.banner = Object.assign({}, new Banner());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.banners.message.index_title'),
                disabled: false,
                to: { name: 'admin-banner-index' },
                exact: true,
            },
            {
                text: this.$t('admin.banners.message.create_title'),
                disabled: true,
                to: { name: 'admin-banner-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param banner
     * @param files
     */

    private async onSave(banner: Banner, files: BannerFileInterface[])
    {
        try
        {
            // Create the banner.
            const created = await this.$serviceContainer.getBannerService().createBanner(banner);

            // Upload files.
            if (files.length > 0)
            {
                await this.uploadFiles(created, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.banners.message.banner_created'));

            // Redirect to the banner.
            NavigationHelper.pushRoute({
                name: 'admin-banner-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads banner files.
     *
     * @param banner
     * @param files
     */

    private async uploadFiles(banner: Banner, files: BannerFileInterface[]): Promise<void>
    {
        for (const file of files)
        {
            await this.$serviceContainer.getBannerService().uploadBannerFile(
                banner.id,
                file.field,
                file.file,
            );
        }
    }
}