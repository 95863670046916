import { Component, Vue } from 'vue-property-decorator';
import Employee from '../../../../../model/entities/organisation/employee';
import EmployeeFormComponent from '@/components/widgets/employee-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-employee-form': EmployeeFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminEmployeeCreatePageComponent extends Vue
{
    private employee: Employee|null = null;

    created()
    {
        this.employee = Object.assign({}, new Employee());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.employees.message.index_title'),
                disabled: false,
                to: { name: 'admin-employee-index' },
                exact: true,
            },
            {
                text: this.$t('admin.employees.message.create_title'),
                disabled: true,
                to: { name: 'admin-employee-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param employee
     */

    private async onSave(employee: Employee)
    {
        try
        {
            // Create the employee.
            const created = await this.$serviceContainer.getEmployeeService().createEmployee(employee);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.employees.message.employee_created'));

            // Redirect to the employee.
            NavigationHelper.pushRoute({
                name: 'admin-employee-edit',
                params: { id: created.userId.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}