import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Notification from '../../entities/notifications/notification';
import NotificationApiInterface from '../../entities/notifications/notification-api-interface';
import UserTransformer from '../organisation/user-transformer';

export default class NotificationTransformer
{
    /**
     * Transforms an API notification representation into a Notification entity.
     *
     * @param apiNotification
     */

    public static fromApi(apiNotification: NotificationApiInterface, associations?: AssociationsApiInterface): Notification
    {
        const notification = new Notification();
        notification.id = apiNotification.id;
        notification.title = apiNotification.title;
        notification.message = apiNotification.message;
        notification.uri = apiNotification.uri;
        notification.isEnabled = apiNotification.enabled;
        notification.isRead = apiNotification.read ?? false;
        notification.created = parseISO(apiNotification.created);
        notification.updated = parseISO(apiNotification.updated);

        // Associations.
        if (associations)
        {
            if (associations.users && apiNotification.user_ids)
            {
                for (const userId of apiNotification.user_ids)
                {
                    const user = UserTransformer.fromApi(associations.users[userId]);
                    notification.users.push(user);
                }
            }
        }

        return notification;
    }

    /**
     * Transforms a Notification entity into an API representation of a Notification.
     *
     * @param Notification
     */

    public static toApi(notification: Notification): NotificationApiInterface
    {
        const users = [];
        for (const user of notification.users)
        {
            users.push(UserTransformer.toApi(user));
        }

        return {
            id: notification.id,
            title: notification.title,
            message: notification.message,
            uri: notification.uri,
            enabled: notification.isEnabled,
            created: formatISO(notification.created),
            updated: formatISO(notification.updated),
            associations:
            {
                users,
            },
        };
    }
}