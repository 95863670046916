import { Component, Vue } from 'vue-property-decorator';
import Banner from '../../../model/entities/media/banner';
import BannerComponent from '@/components/widgets/banner/index.vue';
import LatestNotificationsComponent from '@/components/widgets/latest-notifications/index.vue';

@Component({
    components:
    {
        'app-banner': BannerComponent,
        'app-latest-notifications': LatestNotificationsComponent,
    },
})
export default class HomePageComponent extends Vue
{
    private banners: Banner[] = [];
    private currentBannerIndex = 0;

    private isLoading = true;

    created()
    {
        this.loadBanners();
    }

    /**
     * Returns the current banner.
     */

    get currentBanner(): Banner
    {
        return this.banners[this.currentBannerIndex];
    }

    /**
     * Returns the current image uri.
     */

    get currentImageUri(): string
    {
        return process.env.VUE_APP_API_BASE_URL + this.banners?.[this.currentBannerIndex]?.image?.uri;
    }

    /**
     * Loads the banners
     */

    private async loadBanners()
    {
        this.isLoading = true;

        this.banners = await this.$serviceContainer.getBannerService().getActiveBanners();

        this.isLoading = false;
    }

    /**
     * Handles carousel changes.
     *
     * @param index
     */

    private onCarouselChange(index: number)
    {
        this.currentBannerIndex = index;
    }
}