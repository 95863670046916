import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Post from '../../entities/blog/post';
import PostApiInterface from '../../entities/blog/post-api-interface';

export default class PostTransformer
{
    /**
     * Transforms an API post representation into a Post entity.
     *
     * @param apiPost
     */

    public static fromApi(apiPost: PostApiInterface, associations?: AssociationsApiInterface): Post
    {
        const post = new Post();
        post.id = apiPost.id;
        post.slug = apiPost.slug;
        post.title = apiPost.title;
        post.body = apiPost.body;
        post.image = apiPost.image;
        post.publishedDate = parseISO(apiPost.published_date);
        post.isEnabled = apiPost.enabled;
        post.created = parseISO(apiPost.created);
        post.updated = parseISO(apiPost.updated);
        post.tags = apiPost.tags ? apiPost.tags.slice(0) : [];

        return post;
    }

    /**
     * Transforms a Post entity into an API representation of a post.
     *
     * @param post
     */

    public static toApi(post: Post): PostApiInterface
    {
        return {
            id: post.id,
            slug: post.slug,
            title: post.title,
            body: post.body,
            image: post.image,
            published_date: formatISO(post.publishedDate),
            enabled: post.isEnabled,
            created: formatISO(post.created),
            updated: formatISO(post.updated),
            tags: post.tags.slice(0),
        };
    }
}