import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Banner from '../../../entities/media/banner';
import BannerApiInterface from '../../../entities/media/banner-api-interface';
import BannerTransformer from '../../../transformers/media/banner-transformer';

export default class UploadBannerFileTask extends AbstractApiPostTask
{
    private _bannerId!: number;
    private _field!: string;
    private _file!: File;

    public async run(): Promise<Banner|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiBanner: BannerApiInterface = response.data.data;

                if (apiBanner)
                {
                    return BannerTransformer.fromApi(apiBanner);
                }
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set bannerId(bannerId: number)
    {
        this._bannerId = bannerId;
    }

    set field(field: string)
    {
        this._field = field;
    }

    set file(file: File)
    {
        this._file = file;
    }

    protected getUrl(): string
    {
        return `/banners/${ this._bannerId }/file-upload`;
    }

    protected getData(): FormData
    {
        const data = new FormData();
        data.append('banner_id', this._bannerId.toString());
        data.append('field', this._field.toString());
        data.append('file', this._file);

        return data;
    }
}