import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Post from '../../../../../model/entities/blog/post';
import PostFileInterface from '../../../../../model/vos/blog/post-file-interface';
import PostFormComponent from '@/components/widgets/post-form/index.vue';

@Component({
    components:
    {
        'app-post-form': PostFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminPostEditPageComponent extends Vue
{
    private post: Post|null = null;
    private isLoading = true;

    created()
    {
        this.loadPost();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.posts.message.index_title'),
                disabled: false,
                to: { name: 'admin-post-index' },
                exact: true,
            },
            {
                text: this.$t('admin.posts.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the title of the post for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.post ? this.$et(this.post.title) : this.$t('admin.posts.edit_title').toString();
    }

    /**
     * Loads the post.
     */

    private async loadPost()
    {
        this.isLoading = true;
        const post = await this.$serviceContainer.getPostService().loadPost(parseInt(this.$route.params.id));
        this.post = Object.assign({}, post);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param post
     * @param files
     */

    private async onSave(post: Post, files: PostFileInterface[])
    {
        try
        {
            // Update the post.
            let updated = await this.$serviceContainer.getPostService().updatePost(post);

            // Upload files.
            if (files.length > 0)
            {
                updated = await this.uploadFiles(updated, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.posts.message.post_updated'));

            this.post = updated;
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads post files.
     *
     * @param post
     * @param files
     */

    private async uploadFiles(post: Post, files: PostFileInterface[]): Promise<Post>
    {
        let updated: Post = post;

        for (const file of files)
        {
            const returned = await this.$serviceContainer.getPostService().uploadPostFile(
                post.id,
                file.field,
                file.file,
            );

            if (returned)
            {
                updated = returned;
            }
        }

        return updated;
    }
}