import FileApiInterface from '../../vos/media/file-api-interface';
import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Post
{
    id = 0;
    slug: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    title: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    body: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: FileApiInterface|null = null;
    publishedDate: Date = new Date();
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
    tags: string[] = [];
}