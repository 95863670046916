import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import UserSceneResultsApiInterface from '../../../vos/webscenes/user-scene-results-api-interface';

export default class GetUserSceneResultsTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<UserSceneResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return response.data.data;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/scenes/${ this._id }/results`;
    }
}