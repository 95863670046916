import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Reward from '../../../../../model/entities/rewards/reward';
import RewardResultsApiInterface from '../../../../../model/vos/rewards/reward-results-api-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminRewardIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.rewards.message.index_title'),
                disabled: true,
                to: { name: 'admin-reward-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.id'),
                align: 'left',
                value: 'id',
            },
            {
                text: this.$t('general.field.name'),
                align: 'left',
                value: 'name',
            },
            {
                text: this.$t('general.field.image'),
                align: 'center',
                value: 'image',
            },
            {
                text: this.$t('general.field.points'),
                align: 'center',
                value: 'points',
            },
            {
                text: this.$t('general.field.enabled'),
                align: 'center',
                value: 'enabled',
            },
        ];
    }

    private results: RewardResultsApiInterface =
        {
            results: [],
            total_results: 0,
            results_per_page: 50,
            total_pages: 0,
            page: 1,
            sort_by: 'name',
            descending: false,
        };

    private isLoading = true;

    /**
     * Returns the image URI for the reward.
     */

    private getImageUri(reward: Reward): string|null
    {
        return this.$serviceContainer.getRewardService().getRewardImageUri(reward);
    }

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        const results = await this.$serviceContainer.getRewardService().searchRewards(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-reward-create' });
    }

    /**
     * Handles opening of rewards.
     *
     * @param reward
     */

    private onOpenReward(reward: Reward)
    {
        NavigationHelper.pushRoute({
            name: 'admin-reward-edit',
            params: { id: reward.id.toString() },
        });
    }
}