import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Category
{
    id = 0;
    ref = '';
    name: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: string|null = null;
    hasNewScenes = false;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
    totalScenes = 0;
}