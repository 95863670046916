import AssociationsApiInterface from '../../entities/associations-api-interface';
import LocationTransformer from './location-transformer';
import Team from '../../entities/organisation/team';
import TeamApiInterface from '../../entities/organisation/team-api-interface';

export default class TeamTransformer
{
    /**
     * Transforms an API team representation into a Team entity.
     *
     * @param apiTeam
     */

    public static fromApi(apiTeam: TeamApiInterface, associations?: AssociationsApiInterface): Team
    {
        const team = new Team();
        team.id = apiTeam.id;
        team.locationId = apiTeam.location_id;
        team.name = apiTeam.name;

        // Associations.
        if (associations)
        {
            if (team.locationId && associations.locations?.[team.locationId])
            {
                const location = LocationTransformer.fromApi(associations.locations[team.locationId], associations);
                team.location = location;
            }
        }

        return team;
    }

    /**
     * Transforms a Team entity into an API representation of a team.
     *
     * @param team
     */

    public static toApi(team: Team): TeamApiInterface
    {
        return {
            id: team.id,
            location_id: team.locationId,
            name: team.name,
        };
    }
}