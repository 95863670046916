import DeleteLocationTask from '../../tasks/api/organisation/delete-location';
import GetLocationTask from '../../tasks/api/organisation/get-location';
import Location from '../../entities/organisation/location';
import LocationResultsApiInterface from '../../vos/organisation/location-results-api-interface';
import PatchLocationTask from '../../tasks/api/organisation/patch-location';
import PostLocationTask from '../../tasks/api/organisation/post-location';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchLocationsTask from '../../tasks/api/organisation/search-locations';

export default class LocationService
{
    /**
     * Returns locations matching the conditions.
     *
     * @param conditions
     */

    public async searchLocations(conditions: SearchConditionsInterface): Promise<LocationResultsApiInterface|null>
    {
        const task = new SearchLocationsTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a location.
     *
     * @param id
     */

    public async loadLocation(id: number): Promise<Location|null>
    {
        const task = new GetLocationTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a location.
     *
     * @param location
     */

    public async createLocation(location: Location): Promise<Location>
    {
        const task = new PostLocationTask();
        task.location = location;

        return task.run();
    }

    /**
     * Updates a location.
     *
     * @param location
     */

    public async updateLocation(location: Location): Promise<Location>
    {
        const task = new PatchLocationTask();
        task.location = location;

        return task.run();
    }

    /**
     * Deletes an location.
     *
     * @param id
     */

    public async deleteLocation(id: number): Promise<void>
    {
        const task = new DeleteLocationTask();
        task.id = id;

        return task.run();
    }
}