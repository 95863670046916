import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SafetyAmbassador from '../../../../../model/entities/organisation/safety-ambassador';
import SafetyAmbassadorFileInterface from '../../../../../model/vos/organisation/safety-ambassador-file-interface';
import SafetyAmbassadorFormComponent from '@/components/widgets/safety-ambassador-form/index.vue';

@Component({
    components:
    {
        'app-safety-ambassador-form': SafetyAmbassadorFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminSafetyAmbassadorCreatePageComponent extends Vue
{
    private safetyAmbassador: SafetyAmbassador|null = null;

    created()
    {
        this.safetyAmbassador = Object.assign({}, new SafetyAmbassador());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.safety_ambassadors.message.index_title'),
                disabled: false,
                to: { name: 'admin-safety-ambassador-index' },
                exact: true,
            },
            {
                text: this.$t('admin.safety_ambassadors.message.create_title'),
                disabled: true,
                to: { name: 'admin-safety-ambassador-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param safetyAmbassador
     * @param files
     */

    private async onSave(safetyAmbassador: SafetyAmbassador, files: SafetyAmbassadorFileInterface[])
    {
        try
        {
            // Create the safety ambassador.
            const created = await this.$serviceContainer.getSafetyAmbassadorService().createSafetyAmbassador(safetyAmbassador);

            // Upload files.
            if (files.length > 0)
            {
                await this.uploadFiles(created, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.safety_ambassadors.message.safety_ambassador_created'));

            // Redirect to the safety ambassador.
            NavigationHelper.pushRoute({
                name: 'admin-safety-ambassador-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads safety ambassador files.
     *
     * @param safetyAmbassador
     * @param files
     */

    private async uploadFiles(safetyAmbassador: SafetyAmbassador, files: SafetyAmbassadorFileInterface[]): Promise<void>
    {
        for (const file of files)
        {
            await this.$serviceContainer.getSafetyAmbassadorService().uploadSafetyAmbassadorFile(
                safetyAmbassador.id,
                file.field,
                file.file,
            );
        }
    }
}