import { Component, Vue } from 'vue-property-decorator';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';

@Component({
    components:
    {},
})
export default class LocaleSwitcherComponent extends Vue
{
    /**
     * Handles locale switching.
     *
     * @param locale
     */

    private onSwitchLocale(locale: string)
    {
        LocaleHelper.setLocale(locale, 'en');
        this.$store.commit('ui/locale', locale);
    }
}