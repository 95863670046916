import AbstractApiPostTask from '../abstract-api-post-task';

export default class RequestResetPasswordTask extends AbstractApiPostTask
{
    private _email!: string;

    public async run(): Promise<boolean>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return true;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return false;
    }

    set email(email: string)
    {
        this._email = email;
    }

    protected getUrl(): string
    {
        return '/request-reset-password';
    }

    protected getData(): any
    {
        return {
            email: this._email,
        };
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}