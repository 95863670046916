import AbstractApiPostTask from '../abstract-api-post-task';
import User from '@/model/entities/organisation/user';
import UserApiInterface from '@/model/entities/organisation/user-api-interface';
import UserTransformer from '@/model/transformers/organisation/user-transformer';

export default class PostUserTask extends AbstractApiPostTask
{
    private _user!: User;

    public async run(): Promise<User>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return UserTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._user;
    }

    set user(user: User)
    {
        this._user = user;
    }

    protected getUrl(): string
    {
        return '/users';
    }

    protected getData(): UserApiInterface
    {
        return UserTransformer.toApi(this._user);
    }
}