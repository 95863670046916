import AssociationsApiInterface from '../../entities/associations-api-interface';
import Location from '../../entities/organisation/location';
import LocationApiInterface from '../../entities/organisation/location-api-interface';

export default class LocationTransformer
{
    /**
     * Transforms an API location representation into a Location entity.
     *
     * @param apiLocation
     */

    public static fromApi(apiLocation: LocationApiInterface, associations?: AssociationsApiInterface): Location
    {
        const location = new Location();
        location.id = apiLocation.id;
        location.name = apiLocation.name;

        return location;
    }

    /**
     * Transforms a Location entity into an API representation of a location.
     *
     * @param location
     */

    public static toApi(location: Location): LocationApiInterface
    {
        return {
            id: location.id,
            name: location.name,
        };
    }
}