import AbstractValidator from '../abstract-validator';
import Category from '../../entities/webscenes/category';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class CategoryValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a category.
     *
     * @param Category
     */

    public validate(category: Category): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateName(category, this.errors);
        this.validateDescription(category, this.errors);

        return this.errors;
    }

    private validateName(category: Category, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(category.name.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.name = errors;
        }
    }

    private validateDescription(category: Category, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(category.description.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_description').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.description = errors;
        }
    }
}