import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import FirstAider from '../../../entities/organisation/first-aider';
import FirstAiderApiInterface from '@/model/entities/organisation/first-aider-api-interface';
import FirstAiderTransformer from '../../../transformers/organisation/first-aider-transformer';

export default class PostFirstAiderTask extends AbstractApiPostTask
{
    private _firstAider!: FirstAider;

    public async run(): Promise<FirstAider>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return FirstAiderTransformer.fromApi(response.data.data, response.data.data.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._firstAider;
    }

    set firstAider(firstAider: FirstAider)
    {
        this._firstAider = firstAider;
    }

    protected getUrl(): string
    {
        return '/first-aiders';
    }

    protected getData(): FirstAiderApiInterface
    {
        return FirstAiderTransformer.toApi(this._firstAider);
    }
}