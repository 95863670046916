import { Route } from 'vue-router';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchConditionsService from '../search/search-conditions-service';
import UserSearchConditionsInterface from '@/model/vos/organisation/user-search-conditions-interface';

export default class UserSearchConditionsService extends SearchConditionsService
{
    public getConditionsFromQueryParams(
        route: Route, defaultLimit: number, defaultSortBy: string,
    ): UserSearchConditionsInterface
    {
        const conditions: UserSearchConditionsInterface = super.getConditionsFromQueryParams(
            route, defaultLimit, defaultSortBy,
        );

        this.setStringCondition(
            route, 'first_name', 'firstName', conditions,
        );
        this.setStringCondition(
            route, 'last_name', 'lastName', conditions,
        );
        this.setStringCondition(
            route, 'email', 'email', conditions,
        );
        this.setStringCondition(
            route, 'role', 'roleId', conditions,
        );
        this.setBooleanCondition(
            route, 'newsletter', 'isNewsletterRegistered', conditions,
        );
        this.setBooleanCondition(
            route, 'marketing', 'isMarketingRegistered', conditions,
        );
        this.setBooleanCondition(
            route, 'privacy', 'isPrivacyNoticeAccepted', conditions,
        );
        this.setBooleanCondition(
            route, 'disabled', 'isDisabled', conditions,
        );
        this.setBooleanCondition(
            route, 'include_disabled', 'includeDisabled', conditions,
        );

        return conditions;
    }

    public getQueryParamsFromConditions(
        conditions: SearchConditionsInterface, defaultLimit: number, defaultSortBy: string,
    )
    {
        const params = super.getQueryParamsFromConditions(
            conditions, defaultLimit, defaultSortBy,
        );

        this.setStringParam(
            'first_name', 'firstName', conditions, params,
        );
        this.setStringParam(
            'last_name', 'lastName', conditions, params,
        );
        this.setStringParam(
            'email', 'email', conditions, params,
        );
        this.setStringParam(
            'role', 'roleId', conditions, params,
        );
        this.setBooleanParam(
            'newsletter', 'isNewsletterRegistered', conditions, params,
        );
        this.setBooleanParam(
            'marketing', 'isMarketingRegistered', conditions, params,
        );
        this.setBooleanParam(
            'privacy', 'isPrivacyNoticeAccepted', conditions, params,
        );
        this.setBooleanParam(
            'disabled', 'isDisabled', conditions, params,
        );
        this.setBooleanParam(
            'include_disabled', 'includeDisabled', conditions, params,
        );

        return params;
    }
}