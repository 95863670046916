import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';

export default class PostInterfaceTranslationsTask extends AbstractApiPostTask
{
    private _translations: any;

    public async run(): Promise<boolean>
    {
        try
        {
            await this.request();

            return true;
        }
        catch (e: any)
        {
            this.handleError(e.response);

            return false;
        }
    }

    set translations(translations: any)
    {
        this._translations = translations;
    }

    protected getUrl(): string
    {
        return '/translations/interface.json';
    }

    protected getData(): any
    {
        return this._translations;
    }
}