import DeleteSafetyAmbassadorTask from '../../tasks/api/organisation/delete-safety-ambassador';
import GetActiveSafetyAmbassadorsTask from '../../tasks/api/organisation/get-active-safety-ambassadors';
import GetSafetyAmbassadorTask from '../../tasks/api/organisation/get-safety-ambassador';
import PatchSafetyAmbassadorTask from '../../tasks/api/organisation/patch-safety-ambassador';
import PostSafetyAmbassadorTask from '../../tasks/api/organisation/post-safety-ambassador';
import SafetyAmbassador from '../../entities/organisation/safety-ambassador';
import SafetyAmbassadorResultsApiInterface from '../../vos/organisation/safety-ambassador-results-api-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchSafetyAmbassadorsTask from '../../tasks/api/organisation/search-safety-ambassadors';
import UploadSafetyAmbassadorFileTask from '../../tasks/api/organisation/upload-safety-ambassador-file';

export default class SafetyAmbassadorService
{
    /**
     * Returns all the active safety ambassadors.
     */

    public async getActiveSafetyAmbassadors(): Promise<SafetyAmbassador[]>
    {
        const task = new GetActiveSafetyAmbassadorsTask();

        return task.run();
    }

    /**
     * Returns safety ambassadors matching the conditions.
     *
     * @param conditions
     */

    public async searchSafetyAmbassadors(conditions: SearchConditionsInterface): Promise<SafetyAmbassadorResultsApiInterface|null>
    {
        const task = new SearchSafetyAmbassadorsTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a safety ambassador.
     *
     * @param id
     */

    public async loadSafetyAmbassador(id: number|string): Promise<SafetyAmbassador|null>
    {
        const task = new GetSafetyAmbassadorTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a safety ambassador.
     *
     * @param safetyAmbassador
     */

    public async createSafetyAmbassador(safetyAmbassador: SafetyAmbassador): Promise<SafetyAmbassador>
    {
        const task = new PostSafetyAmbassadorTask();
        task.safetyAmbassador = safetyAmbassador;

        return task.run();
    }

    /**
     * Updates a safety ambassador.
     *
     * @param safetyAmbassador
     */

    public async updateSafetyAmbassador(safetyAmbassador: SafetyAmbassador): Promise<SafetyAmbassador>
    {
        const task = new PatchSafetyAmbassadorTask();
        task.safetyAmbassador = safetyAmbassador;

        return task.run();
    }

    /**
     * Deletes an safety ambassador.
     *
     * @param id
     */

    public async deleteSafetyAmbassador(id: number): Promise<void>
    {
        const task = new DeleteSafetyAmbassadorTask();
        task.id = id;

        return task.run();
    }

    /**
     * Uploads a safety ambassador file.
     *
     * @param safetyAmbassadorId
     * @param field
     * @param locale
     * @param file
     */

    public async uploadSafetyAmbassadorFile(
        safetyAmbassadorId: number,
        field: string,
        file: File,
    ): Promise<SafetyAmbassador|null>
    {
        const task = new UploadSafetyAmbassadorFileTask();
        task.safetyAmbassadorId = safetyAmbassadorId;
        task.field = field;
        task.file = file;

        return task.run();
    }
}