import GetRolesTask from '@/model/tasks/api/organisation/get-roles';
import Role from '@/model/entities/organisation/role';

export default class RoleService
{
    /**
     * Returns all roles
     */

    public async getRoles(): Promise<Role[]>
    {
        const task = new GetRolesTask();

        return task.run();
    }
}