import { Component, Vue } from 'vue-property-decorator';
import { SupportedLocales } from '@/model/services/translations/translation-service';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Video from '../../../model/entities/media/video';
import VideoSearchConditionsInterface from '../../../model/vos/media/video-search-conditions-interface';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
    },
})
export default class VideosComponent extends Vue
{
    private videos: Video[] = [];
    private video: Video|null = null;
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.videos'),
                disabled: false,
                to: { name: 'videos' },
                exact: true,
            },
        ];
    }

    created()
    {
        this.loadVideos();
    }

    /**
     * Returns the current page
     */

    get page(): number
    {
        if ('page' in this.$route.query)
        {
            let page = parseInt(this.$route.query.page.toString());
            if (isNaN(page))
            {
                page = 1;
            }

            return page;
        }
        else
        {
            return 1;
        }
    }

    /**
     * Returns the name for the locale
     *
     * @param locale
     */

    private getLocaleName(locale: SupportedLocales): string
    {
        switch (locale)
        {
        case 'nl-BE':
            return 'Nederlands';

        case 'en':
        default:
            return 'English';
        }
    }

    private async loadVideos()
    {
        this.isLoading = true;

        const conditions: VideoSearchConditionsInterface =
        {
            limit: 20,
            page: this.page,
            sortBy: 'order',
            descending: false,
        };

        const results = await this.$serviceContainer.getVideoService().searchVideos(conditions);
        if (results)
        {
            this.videos = results.results;
        }

        if (this.videos.length > 0)
        {
            this.onSelectVideo(this.videos[0]);
        }

        this.isLoading = false;
    }

    private onSelectVideo(video: Video)
    {
        this.video = Object.assign(new Video(), video);
    }
}