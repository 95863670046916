import { Component, Vue } from 'vue-property-decorator';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
    },
})
export default class HelpComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.help'),
                disabled: false,
                to: { name: 'help' },
                exact: true,
            },
        ];
    }
}