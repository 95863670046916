import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Post from '../../../entities/blog/post';
import PostApiInterface from '../../../entities/blog/post-api-interface';
import PostTransformer from '../../../transformers/blog/post-transformer';

export default class GetPostTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<Post|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiPost: PostApiInterface = response.data.data;

                return PostTransformer.fromApi(apiPost);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/posts/${ this._id }`;
    }
}