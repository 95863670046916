import { Component, Vue } from 'vue-property-decorator';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import MenuItemInterface from '@/model/vos/navigation/menu-item-interface';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-translation': TranslationComponent,
    },
})
export default class NavComponent extends Vue
{
    get items(): MenuItemInterface[]
    {
        if (LocaleHelper.getLocale() === 'nl-BE')
        {
            return [
                {
                    route: { name: 'category-index' },
                    icon: undefined,
                    title: 'nav.safety_categories',
                    active: false,
                },
                {
                    route: undefined,
                    icon: undefined,
                    title: 'nav.resources',
                    active: false,
                    children:
                    [
                        {
                            route: { name: 'blog' },
                            icon: undefined,
                            title: 'nav.blog',
                            active: false,
                        },
                        {
                            route: { name: 'videos' },
                            icon: undefined,
                            title: 'nav.videos',
                            active: false,
                        },
                    ],
                },
                {
                    route: { name: 'feedback' },
                    icon: undefined,
                    title: 'nav.feedback',
                    active: false,
                },
                {
                    route: { name: 'help' },
                    icon: undefined,
                    title: 'nav.help',
                    active: false,
                },
            ];
        }
        else
        {
            return [
                {
                    route: { name: 'category-index' },
                    icon: undefined,
                    title: 'nav.safety_categories',
                    active: false,
                },
                {
                    route: undefined,
                    icon: undefined,
                    title: 'nav.points_tables',
                    active: false,
                    children:
                    [
                        {
                            route: { name: 'my-points' },
                            icon: undefined,
                            title: 'nav.your_points',
                            active: false,
                        },
                        {
                            route: { name: 'leaderboard' },
                            icon: undefined,
                            title: 'nav.your_leaderboard',
                            active: false,
                        },
                        {
                            route: { name: 'team-leaderboard' },
                            icon: undefined,
                            title: 'nav.team_leaderboard',
                            active: false,
                        },
                    ],
                },
                {
                    route: { name: 'reward-index' },
                    icon: undefined,
                    title: 'nav.rewards',
                    active: false,
                },
                {
                    route: undefined,
                    icon: undefined,
                    title: 'nav.resources',
                    active: false,
                    children:
                    [
                        {
                            route: { name: 'newsletters' },
                            icon: undefined,
                            title: 'nav.newsletters',
                            active: false,
                        },

                        // {
                        //     uri: '/assets/documents/safety-topics.xlsx',
                        //     icon: null,
                        //     title: 'nav.safety_topics',
                        //     active: false
                        // },
                        {
                            route: { name: 'videos' },
                            icon: undefined,
                            title: 'nav.videos',
                            active: false,
                        },
                        {
                            route: { name: 'first-aiders',
                                params: { type: 'mental-health' } },
                            icon: undefined,
                            title: 'nav.first_aiders_mental_health',
                            active: false,
                        },
                        {
                            route: { name: 'safety-ambassadors' },
                            icon: undefined,
                            title: 'nav.safety_ambassadors',
                            active: false,
                        },
                    ],
                },
                {
                    route: { name: 'feedback' },
                    icon: undefined,
                    title: 'nav.feedback',
                    active: false,
                },
                {
                    route: { name: 'help' },
                    icon: undefined,
                    title: 'nav.help',
                    active: false,
                },
            ];
        }
    }
}