import FileApiInterface from '../../vos/media/file-api-interface';
import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Newsletter
{
    id = 0;
    title: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    file: {'en': FileApiInterface|null, 'nl-BE': FileApiInterface|null} = {
        'en': null,
        'nl-BE': null,
    };

    releaseDate: Date = new Date();
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
}