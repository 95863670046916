import { messages as componentMessages } from '@vertical-plus/vue-js-components';
import { messages as microServiceComponentMessages } from '@vertical-plus/vue-js-micro-service-components';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import TranslationService from '@/model/services/translations/translation-service';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import appMessages from './translations/messages';
import store from '@/store';

Vue.use(VueI18n);

let locale = store.getters['ui/locale'];
if (!locale)
{
    if ([
        'nl-BE', 'nl-be', 'nl',
    ].includes(navigator.language))
    {
        locale = 'nl-BE';
    }
    else
    {
        locale = 'en';
    }
}

// Configure translations
const translationService = new TranslationService();

const i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {},
});

i18n.mergeLocaleMessage('en', componentMessages.en);
i18n.mergeLocaleMessage('en', microServiceComponentMessages.en);
i18n.mergeLocaleMessage('en', appMessages.en);

translationService.loadInterfaceTranslations().then(translations =>
{
    if (translations.en)
    {
        i18n.mergeLocaleMessage('en', translations.en);
    }

    if (translations['nl-BE'])
    {
        i18n.mergeLocaleMessage('nl-BE', translations['nl-BE']);
    }

    LocaleHelper.setI18n(i18n);
});

LocaleHelper.setI18n(i18n);
LocaleHelper.setSupportedLocales([ 'en', 'nl-BE' ]);

export default i18n;