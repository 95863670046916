import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';

export default class RestartSceneTask extends AbstractApiPostTask
{
    private _sceneId!: number;

    public async run(): Promise<void>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    set sceneId(sceneId: number)
    {
        this._sceneId = sceneId;
    }

    protected getUrl(): string
    {
        return `/scenes/${ this._sceneId }/restart`;
    }
}