import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Employee from '../../../entities/organisation/employee';
import EmployeeApiInterface from '../../../entities/organisation/employee-api-interface';
import EmployeeTransformer from '../../../transformers/organisation/employee-transformer';

export default class PostEmployeeTask extends AbstractApiPostTask
{
    private _employee!: Employee;

    public async run(): Promise<Employee>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiEmployee: EmployeeApiInterface = response.data.data;

                return EmployeeTransformer.fromApi(apiEmployee, apiEmployee.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._employee;
    }

    set employee(employee: Employee)
    {
        this._employee = employee;
    }

    protected getUrl(): string
    {
        return '/employees';
    }

    protected getData(): EmployeeApiInterface
    {
        const data = EmployeeTransformer.toApi(this._employee);

        data.first_name = this._employee.firstName;
        data.last_name = this._employee.lastName;
        data.email = this._employee.email;

        return data;
    }
}