import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Notification from '../../../entities/notifications/notification';
import NotificationApiInterface from '../../../entities/notifications/notification-api-interface';
import NotificationResultsInterface from '../../../vos/notifications/notification-results-interface';
import NotificationSearchConditions from '../../../vos/notifications/notification-search-conditions';
import NotificationTransformer from '../../../transformers/notifications/notification-transformer';

export default class SearchNotificationsTask extends AbstractApiGetTask
{
    private _conditions!: NotificationSearchConditions;

    public async run(): Promise<NotificationResultsInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiNotifications: NotificationApiInterface[] = response.data.data.results;

                const notifications: Notification[] = [];
                for (const apiNotification of apiNotifications)
                {
                    try
                    {
                        // Parse the category.
                        notifications.push(NotificationTransformer.fromApi(apiNotification));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse notification');
                        console.error(error);
                    }
                }

                return {
                    results: notifications,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: NotificationSearchConditions)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/notifications/search';
    }

    protected getParams(): any
    {
        const params: any = {};

        if (this._conditions.searchTerm)
        {
            params.search_term = this._conditions.searchTerm;
        }

        if (this._conditions.userId)
        {
            params.user_id = this._conditions.userId;
        }

        if (this._conditions.includeDisabled)
        {
            params.include_disabled = this._conditions.includeDisabled ? '1' : '0';
        }

        if (this._conditions.limit)
        {
            params.limit = this._conditions.limit;
        }

        if (this._conditions.page)
        {
            params.page = this._conditions.page;
        }

        if (this._conditions.sortBy)
        {
            params.sort_by = this._conditions.sortBy;
        }

        if (this._conditions.decending)
        {
            params.desc = this._conditions.decending ? '1' : '0';
        }

        return params;
    }
}