import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SafetyAmbassador from '../../../../../model/entities/organisation/safety-ambassador';
import SafetyAmbassadorResultsApiInterface from '../../../../../model/vos/organisation/safety-ambassador-results-api-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminSafetyAmbassadorIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.safety_ambassadors.message.index_title'),
                disabled: true,
                to: { name: 'admin-safety-ambassador-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.id'),
                align: 'left',
                value: 'id',
            },
            {
                text: this.$t('general.field.first_name'),
                align: 'left',
                value: 'firstName',
            },
            {
                text: this.$t('general.field.last_name'),
                align: 'left',
                value: 'lastName',
            },
            {
                text: this.$t('general.field.enabled'),
                align: 'center',
                value: 'enabled',
                sortable: false,
            },
            {
                text: this.$t('general.field.options'),
                align: 'center',
                value: '',
                sortable: false,
            },
        ];
    }

    private results: SafetyAmbassadorResultsApiInterface = {
        results: [],
        total_results: 0,
        results_per_page: 50,
        total_pages: 0,
        page: 1,
        sort_by: 'firstName',
        descending: false,
    };

    private isLoading = true;

    private conditions: SearchConditionsInterface = {};

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        this.conditions = Object.assign({}, conditions);
        const results = await this.$serviceContainer.getSafetyAmbassadorService().searchSafetyAmbassadors(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-safety-ambassador-create' });
    }

    /**
     * Handles opening of safety_ambassadors.
     *
     * @param safetyAmbassador
     */

    private onOpenSafetyAmbassador(safetyAmbassador: SafetyAmbassador)
    {
        NavigationHelper.pushRoute({
            name: 'admin-safety-ambassador-edit',
            params: { id: safetyAmbassador.id.toString() },
        });
    }

    /**
     * Handles deleting of safety_ambassadors.
     *
     * @param safetyAmbassador
     */

    private onDeleteSafetyAmbassador(safetyAmbassador: SafetyAmbassador)
    {
        EventBus.instance.$emit(
            'confirm-dialog', this.$t('admin.safety_ambassadors.message.delete_title'), this.$t('admin.safety_ambassadors.message.delete_description'), () => this.deleteSafetyAmbassador(safetyAmbassador), true,
        );
    }

    /**
     * Deletes a safetyAmbassador
     *
     * @param safetyAmbassador
     */

    private async deleteSafetyAmbassador(safetyAmbassador: SafetyAmbassador)
    {
        try
        {
            await this.$serviceContainer.getSafetyAmbassadorService().deleteSafetyAmbassador(safetyAmbassador.id);
            this.loadResults(this.conditions);

            EventBus.instance.$emit(
                'notification', this.$t('admin.safety_ambassadors.message.safety_ambassador_deleted'), 'success',
            );
        }
        catch (error)
        {
            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_deleting_server_error'), 'error',
            );
        }
    }
}