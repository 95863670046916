import DeleteNewsletterTask from '../../tasks/api/media/delete-newsletter';
import GetActiveNewslettersTask from '../../tasks/api/media/get-active-newsletters';
import GetNewsletterTask from '../../tasks/api/media/get-newsletter';
import Newsletter from '../../entities/media/newsletter';
import NewsletterResultsApiInterface from '../../vos/media/newsletter-results-api-interface';
import PatchNewsletterTask from '../../tasks/api/media/patch-newsletter';
import PostNewsletterTask from '../../tasks/api/media/post-newsletter';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchNewslettersTask from '../../tasks/api/media/search-newsletters';
import UploadNewsletterFileTask from '../../tasks/api/media/upload-newsletter-file';

export default class NewsletterService
{
    /**
     * Returns all the active newsletters.
     */

    public async getActiveNewsletters(): Promise<Newsletter[]>
    {
        const task = new GetActiveNewslettersTask();

        return task.run();
    }

    /**
     * Returns newsletters matching the conditions.
     *
     * @param conditions
     */

    public async searchNewsletters(conditions: SearchConditionsInterface): Promise<NewsletterResultsApiInterface|null>
    {
        const task = new SearchNewslettersTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a newsletter.
     *
     * @param id
     */

    public async loadNewsletter(id: number|string): Promise<Newsletter|null>
    {
        const task = new GetNewsletterTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a newsletter.
     *
     * @param newsletter
     */

    public async createNewsletter(newsletter: Newsletter): Promise<Newsletter>
    {
        const task = new PostNewsletterTask();
        task.newsletter = newsletter;

        return task.run();
    }

    /**
     * Updates a newsletter.
     *
     * @param newsletter
     */

    public async updateNewsletter(newsletter: Newsletter): Promise<Newsletter>
    {
        const task = new PatchNewsletterTask();
        task.newsletter = newsletter;

        return task.run();
    }

    /**
     * Deletes an newsletter.
     *
     * @param id
     */

    public async deleteNewsletter(id: number): Promise<void>
    {
        const task = new DeleteNewsletterTask();
        task.id = id;

        return task.run();
    }

    /**
     * Uploads a newsletter file.
     *
     * @param newsletterId
     * @param field
     * @param locale
     * @param file
     */

    public async uploadNewsletterFile(
        newsletterId: number,
        field: string,
        locale: string,
        file: File,
    ): Promise<Newsletter|null>
    {
        const task = new UploadNewsletterFileTask();
        task.newsletterId = newsletterId;
        task.field = field;
        task.locale = locale;
        task.file = file;

        return task.run();
    }
}