import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Reward from '../../../entities/rewards/reward';
import RewardApiInterface from '@/model/entities/rewards/reward-api-interface';
import RewardTransformer from '../../../transformers/rewards/reward-transformer';

export default class PatchRewardTask extends AbstractApiPatchTask
{
    private _reward!: Reward;

    public async run(): Promise<Reward>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return RewardTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._reward;
    }

    set reward(reward: Reward)
    {
        this._reward = reward;
    }

    protected getUrl(): string
    {
        return `/rewards/${ this._reward.id }`;
    }

    protected getData(): RewardApiInterface
    {
        return RewardTransformer.toApi(this._reward);
    }
}