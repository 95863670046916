import { Component, Vue } from 'vue-property-decorator';
import Category from '../../../../model/entities/webscenes/category';
import CategoryButtonComponent from '@/components/widgets/category-button/index.vue';
import IncompleteScenesComponent from '@/components/widgets/incomplete-scenes/index.vue';
import LatestScenesComponent from '@/components/widgets/latest-scenes/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-category-button': CategoryButtonComponent,
        'app-incomplete-scenes': IncompleteScenesComponent,
        'app-latest-scenes': LatestScenesComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class CategoryIndexPageComponent extends Vue
{
    private categories: Category[] = [];
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.safety_categories'),
                disabled: true,
                to: { name: 'category-index' },
                exact: true,
            },
        ];
    }

    created()
    {
        this.loadCategories();
    }

    get incompleteScenesPosition(): number
    {
        switch (this.$vuetify.breakpoint.name)
        {
        case 'xl':
            return 4;

        case 'lg':
            return 3;

        case 'md':
            return 1;

        case 'sm':
        case 'xs':
        default:
            return 1;
        }
    }

    get latestScenesPosition(): number
    {
        switch (this.$vuetify.breakpoint.name)
        {
        case 'xl':
            return 7;

        case 'lg':
            return 5;

        case 'md':
            return 1;

        case 'sm':
        case 'xs':
        default:
            return 1;
        }
    }

    private async loadCategories()
    {
        this.isLoading = true;
        this.categories = await this.$serviceContainer.getCategoryService().getActiveCategories();
        this.isLoading = false;
    }
}