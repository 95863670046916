import { Route } from 'vue-router';
import EmployeeSearchConditionsInterface from '../../vos/organisation/employee-search-conditions-interface';
import SearchConditionsService from '@/model/services/search/search-conditions-service';

export default class EmployeeSearchConditionsService extends SearchConditionsService
{
    public getConditionsFromQueryParams(
        route: Route, defaultLimit: number, defaultSortBy: string,
    ): EmployeeSearchConditionsInterface
    {
        const conditions: EmployeeSearchConditionsInterface = super.getConditionsFromQueryParams(
            route, defaultLimit, defaultSortBy,
        );

        this.setStringCondition(
            route, 'employee_number', 'employeeNumber', conditions,
        );
        this.setStringCondition(
            route, 'first_name', 'firstName', conditions,
        );
        this.setStringCondition(
            route, 'last_name', 'lastName', conditions,
        );
        this.setStringCondition(
            route, 'email', 'email', conditions,
        );
        this.setStringCondition(
            route, 'location', 'locationId', conditions,
        );
        this.setStringCondition(
            route, 'team', 'teamId', conditions,
        );

        return conditions;
    }

    public getQueryParamsFromConditions(
        conditions: EmployeeSearchConditionsInterface, defaultLimit: number, defaultSortBy: string,
    )
    {
        const params = super.getQueryParamsFromConditions(
            conditions, defaultLimit, defaultSortBy,
        );

        this.setStringParam(
            'employee_number', 'employeeNumber', conditions, params,
        );
        this.setStringParam(
            'first_name', 'firstName', conditions, params,
        );
        this.setStringParam(
            'last_name', 'lastName', conditions, params,
        );
        this.setStringParam(
            'email', 'email', conditions, params,
        );
        this.setStringParam(
            'location', 'locationId', conditions, params,
        );
        this.setStringParam(
            'team', 'teamId', conditions, params,
        );

        return params;
    }
}