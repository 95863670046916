import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Reward from '../../../model/entities/rewards/reward';
import RewardValidator from '../../../model/validator/rewards/reward-validator';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class RewardFormComponent extends Vue
{
    @Prop()
    private reward!: Reward;

    @Prop({ default: 'Save Reward' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formReward: Reward|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formReward = Object.assign(new Reward(), this.reward);
    }

    @Watch('reward')
    private onRewardUpdated()
    {
        this.formReward = Object.assign(new Reward(), this.reward);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formReward)
        {
            return false;
        }

        const validator: RewardValidator = new RewardValidator();
        this.validationErrors = validator.validate(this.formReward);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the URI for the image.
     */

    get imageUri(): string|null
    {
        if (!this.formReward)
        {
            return '';
        }

        return this.$serviceContainer.getRewardService().getRewardImageUri(this.formReward);
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Handles image file change.
     *
     * @param files
     */

    private onImageChange(files: FileList)
    {
        if (!this.formReward)
        {
            return;
        }

        this.formReward.imageFile = files[0];
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formReward)
        {
            throw new Error('Invalid reward properties');
        }

        const validator = new RewardValidator();
        const validationErrors = validator.validate(this.formReward);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid reward properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated reward.
            this.$emit('save', this.formReward);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}