import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import TeamLeaderboardApiInterface from '../../../vos/organisation/team-leaderboard-api-interface';

export default class GetTeamLeaderboardTask extends AbstractApiGetTask
{
    public async run(): Promise<TeamLeaderboardApiInterface[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return response.data.data;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            console.error(e);
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/teams/leaderboard';
    }
}