import { Component, Vue } from 'vue-property-decorator';
import { SupportedLocales } from '@/model/services/translations/translation-service';
import { format, parse } from 'date-fns';
import ButtonComponent from '@/components/widgets/button/index.vue';
import FileApiInterface from '../../../model/vos/media/file-api-interface';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Newsletter from '../../../model/entities/media/newsletter';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class NewslettersComponent extends Vue
{
    private newsletters: Newsletter[] = [];
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.newsletters'),
                disabled: false,
                to: { name: 'newsletters' },
                exact: true,
            },
        ];
    }

    created()
    {
        this.loadNewsletters();
    }

    private get newslettersByMonth()
    {
        // Arrange into months
        const newslettersByMonth: {[month: string]: Newsletter[]} = {};

        for (const newsletter of this.newsletters)
        {
            const month = format(newsletter.releaseDate, 'yyyy-MM');

            if (!(month in newslettersByMonth))
            {
                newslettersByMonth[month] = [];
            }

            newslettersByMonth[month].push(newsletter);
        }

        // Get sorted months
        const orderedMonths = Object.keys(newslettersByMonth).sort()
            .reverse();

        // Generate an aray of newletters in month order
        const newsletters = [];

        for (const month of orderedMonths)
        {
            newsletters.push({
                name: format(parse(
                    `${ month }-01`, 'yyyy-MM-dd', new Date(),
                ), 'MMMM yyyy'),
                newsletters: newslettersByMonth[month],
            });
        }

        return newsletters;
    }

    /**
     * Returns the uri for a newsletter's file
     *
     * @param newsletter
     */

    private getNewsletterFileUri(newsletter: Newsletter)
    {
        if (!newsletter.file)
        {
            return '#';
        }

        const locale = LocaleHelper.getLocale();

        let localisedFile: FileApiInterface|null = null;
        if (locale in newsletter.file)
        {
            localisedFile = newsletter.file[locale as SupportedLocales];
        }
        else
        {
            localisedFile = newsletter.file.en;
        }

        if (localisedFile)
        {
            return process.env.VUE_APP_API_BASE_URL + localisedFile.uri;
        }
        else
        {
            return '#';
        }
    }

    private async loadNewsletters()
    {
        this.isLoading = true;

        this.newsletters = await this.$serviceContainer.getNewsletterService().getActiveNewsletters();

        this.isLoading = false;
    }
}