import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import VueI18n from 'vue-i18n';

export default abstract class AbstractValidator
{
    protected i18n: VueI18n;

    public constructor()
    {
        this.i18n = LocaleHelper.getI18n();
    }

    /**
     * Validates a string.
     *
     * @param value
     * @param min
     * @param max
     */

    protected isValidString(
        value: string|null, min?: number, max?: number,
    )
    {
        const isString = value && value.trim();

        if (min || max)
        {
            return isString && (!min || value.length >= min) && (!max || value.length <= max);
        }
        else
        {
            return isString;
        }
    }

    /**
     * Validates a nullable hours field.
     *
     * @param hours
     */

    protected isValidNullableHours(hours: number)
    {
        if (hours && !hours.toString().match(/^\d+$/))
        {
            return false;
        }

        return true;
    }

    /**
     * Validates a nullable price field.
     *
     * @param price
     */

    protected isValidNullablePrice(price: string)
    {
        if (price && !price.match(/^\d{1,6}(\.\d{1,2})?$/))
        {
            return false;
        }

        return true;
    }
}