import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';

export default class PostEmployeeRewardTask extends AbstractApiPostTask
{
    private _rewardId!: number;
    private _userId!: number;
    private _points!: number;

    public async run(): Promise<void>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    set rewardId(rewardId: number)
    {
        this._rewardId = rewardId;
    }

    set userId(userId: number)
    {
        this._userId = userId;
    }

    set points(points: number)
    {
        this._points = points;
    }

    protected getUrl(): string
    {
        return `/rewards/${ this._rewardId }/assign`;
    }

    protected getData(): any
    {
        return {
            user_id: this._userId,
            points: this._points,
        };
    }
}