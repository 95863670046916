import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import FirstAider from '../../../model/entities/organisation/first-aider';
import FirstAiderService from '../../../model/services/organisation/first-aider-service';
import FirstAiderValidator from '../../../model/validator/organisation/first-aider-validator';
import Location from '../../../model/entities/organisation/location';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class FirstAiderFormComponent extends Vue
{
    @Prop()
    private firstAider!: FirstAider;

    @Prop({ default: 'Save First Aider' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formFirstAider: FirstAider|null = null;
    private locations: Location[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formFirstAider = Object.assign(new FirstAider(), this.firstAider);

        this.loadLocations();
    }

    @Watch('firstAider')
    private onFirstAiderUpdated()
    {
        this.formFirstAider = Object.assign(new FirstAider(), this.firstAider);
    }

    @Watch('formFirstAider.location')
    private onLocationChanged()
    {
        if (!this.formFirstAider)
        {
            return;
        }

        this.formFirstAider.locationId = this.formFirstAider.location ? this.formFirstAider.location.id : null;
    }

    /**
     * Returns the types.
     */

    get types(): {id: string, name: string}[]
    {
        return FirstAiderService.getTypes();
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formFirstAider)
        {
            return false;
        }

        const validator: FirstAiderValidator = new FirstAiderValidator();
        this.validationErrors = validator.validate(this.formFirstAider);

        return size(this.validationErrors) === 0;
    }

    /**
     * Loads the available locations.
     */

    private async loadLocations()
    {
        const results = await this.$serviceContainer.getLocationService().searchLocations({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.locations = results.results;
        }
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formFirstAider)
        {
            throw new Error('Invalid first aider properties');
        }

        const validator = new FirstAiderValidator();
        const validationErrors = validator.validate(this.formFirstAider);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid first aider properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated firstAider.
            this.$emit('save', this.formFirstAider);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}