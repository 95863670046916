import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Post from '../../../model/entities/blog/post';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class PostComponent extends Vue
{
    private post: Post|null = null;
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.blog'),
                disabled: false,
                to: { name: 'blog' },
                exact: true,
            },
            {
                text: this.title,
                disabled: true,
                to: null,
                exact: true,
            },
        ];
    }

    created()
    {
        this.loadPost();
    }

    get title(): string
    {
        return this.post ? this.$et(this.post.title) : this.$t('posts.message.breadcrumb').toString();
    }

    /**
     * Returns the uri for the post's image
     */

    get postImageUri(): string
    {
        if (!this.post?.image)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + this.post.image.uri;
    }

    /**
     * Loads the post
     */

    private async loadPost()
    {
        this.isLoading = true;

        const post = await this.$serviceContainer.getPostService().loadPostBySlug(this.$route.params.slug.toString());
        this.post = Object.assign({}, post);

        this.isLoading = false;
    }
}