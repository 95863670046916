import DeleteVideoTask from '../../tasks/api/media/delete-video';
import GetVideoTask from '../../tasks/api/media/get-video';
import PatchVideoTask from '../../tasks/api/media/patch-video';
import PostVideoTask from '../../tasks/api/media/post-video';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchVideosTask from '../../tasks/api/media/search-videos';
import Video from '../../entities/media/video';
import VideoResultsApiInterface from '../../vos/media/video-results-api-interface';

export default class VideoService
{
    /**
     * Returns videos matching the conditions.
     *
     * @param conditions
     */

    public async searchVideos(conditions: SearchConditionsInterface): Promise<VideoResultsApiInterface|null>
    {
        const task = new SearchVideosTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a video.
     *
     * @param id
     */

    public async loadVideo(id: number|string): Promise<Video|null>
    {
        const task = new GetVideoTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a video.
     *
     * @param video
     */

    public async createVideo(video: Video): Promise<Video>
    {
        const task = new PostVideoTask();
        task.video = video;

        return task.run();
    }

    /**
     * Updates a video.
     *
     * @param video
     */

    public async updateVideo(video: Video): Promise<Video>
    {
        const task = new PatchVideoTask();
        task.video = video;

        return task.run();
    }

    /**
     * Deletes an video.
     *
     * @param id
     */

    public async deleteVideo(id: number): Promise<void>
    {
        const task = new DeleteVideoTask();
        task.id = id;

        return task.run();
    }
}