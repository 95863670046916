import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Newsletter from '../../../../../model/entities/media/newsletter';
import NewsletterFileInterface from '../../../../../model/vos/media/newsletter-file-interface';
import NewsletterFormComponent from '@/components/widgets/newsletter-form/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-newsletter-form': NewsletterFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminNewsletterEditPageComponent extends Vue
{
    private newsletter: Newsletter|null = null;
    private isLoading = true;

    created()
    {
        this.loadNewsletter();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.newsletters.message.index_title'),
                disabled: false,
                to: { name: 'admin-newsletter-index' },
                exact: true,
            },
            {
                text: this.$t('admin.newsletters.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the title of the newsletter for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.newsletter ? this.$et(this.newsletter.title) : this.$t('admin.newsletters.edit_title').toString();
    }

    /**
     * Loads the newsletter.
     */

    private async loadNewsletter()
    {
        this.isLoading = true;
        const newsletter = await this.$serviceContainer.getNewsletterService().loadNewsletter(parseInt(this.$route.params.id));
        this.newsletter = Object.assign({}, newsletter);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param newsletter
     * @param files
     */

    private async onSave(newsletter: Newsletter, files: NewsletterFileInterface[])
    {
        try
        {
            // Update the newsletter.
            let updated = await this.$serviceContainer.getNewsletterService().updateNewsletter(newsletter);

            // Upload files.
            if (files.length > 0)
            {
                updated = await this.uploadFiles(updated, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.newsletters.message.newsletter_updated'));

            this.newsletter = updated;
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads newsletter files.
     *
     * @param newsletter
     * @param files
     */

    private async uploadFiles(newsletter: Newsletter, files: NewsletterFileInterface[]): Promise<Newsletter>
    {
        let updated: Newsletter = newsletter;

        for (const file of files)
        {
            const returned = await this.$serviceContainer.getNewsletterService().uploadNewsletterFile(
                newsletter.id,
                file.field,
                file.locale,
                file.file,
            );

            if (returned)
            {
                updated = returned;
            }
        }

        return updated;
    }
}