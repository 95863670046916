import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Scene from '../../../../../model/entities/webscenes/scene';
import SceneFormComponent from '@/components/widgets/scene-form/index.vue';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
        'app-scene-form': SceneFormComponent,
    },
})
export default class AdminSceneCreatePageComponent extends Vue
{
    private scene: Scene|null = null;
    private isSaving = false;

    created()
    {
        this.scene = Object.assign({}, new Scene());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.scenes.message.index_title'),
                disabled: false,
                to: { name: 'admin-scene-index' },
                exact: true,
            },
            {
                text: this.$t('admin.scenes.message.create_title'),
                disabled: true,
                to: { name: 'admin-scene-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param scene
     */

    private async onSave(scene: Scene)
    {
        try
        {
            // Mark as saving.
            this.isSaving = true;

            // Create the scene.
            const created = await this.$serviceContainer.getSceneService().createScene(scene);

            // Upload files.
            await this.$serviceContainer.getSceneService().uploadFiles(created, scene);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.scenes.message.scene_created'));

            // Redirect to the scene.
            NavigationHelper.pushRoute({
                name: 'admin-scene-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
        finally
        {
            // Mark as no longer saving.
            this.isSaving = false;
        }
    }
}