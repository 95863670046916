import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Post from '../../../../../model/entities/blog/post';
import PostResultsApiInterface from '../../../../../model/vos/blog/post-results-api-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminPostIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.posts.message.index_title'),
                disabled: true,
                to: { name: 'admin-post-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.id'),
                align: 'left',
                value: 'id',
            },
            {
                text: this.$t('general.field.title'),
                align: 'left',
                value: 'title',
            },
            {
                text: this.$t('general.field.tags'),
                align: 'left',
                value: 'tags',
                sortable: false,
            },
            {
                text: this.$t('admin.posts.field.published_date'),
                align: 'left',
                value: 'publishedDate',
            },
            {
                text: this.$t('general.field.enabled'),
                align: 'center',
                value: 'enabled',
                sortable: false,
            },
        ];
    }

    private results: PostResultsApiInterface = {
        results: [],
        total_results: 0,
        results_per_page: 50,
        total_pages: 0,
        page: 1,
        sort_by: 'title',
        descending: false,
    };

    private isLoading = true;

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        const results = await this.$serviceContainer.getPostService().searchPosts(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-post-create' });
    }

    /**
     * Handles opening of posts.
     *
     * @param post
     */

    private onOpenPost(post: Post)
    {
        NavigationHelper.pushRoute({
            name: 'admin-post-edit',
            params: { id: post.id.toString() },
        });
    }
}