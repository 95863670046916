import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import Team from '../../../../../model/entities/organisation/team';
import TeamResultsApiInterface from '../../../../../model/vos/organisation/team-results-api-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminTeamIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.teams.message.index_title'),
                disabled: true,
                to: { name: 'admin-team-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.id'),
                align: 'left',
                value: 'id',
            },
            {
                text: this.$t('general.field.name'),
                align: 'left',
                value: 'name',
            },
            {
                text: this.$t('general.field.location'),
                align: 'left',
                value: 'location',
            },
        ];
    }

    private results: TeamResultsApiInterface = {
        results: [],
        total_results: 0,
        results_per_page: 50,
        total_pages: 0,
        page: 1,
        sort_by: 'name',
        descending: false,
    };

    private isLoading = true;

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        const results = await this.$serviceContainer.getTeamService().searchTeams(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-team-create' });
    }

    /**
     * Handles opening of teams.
     *
     * @param team
     */

    private onOpenTeam(team: Team)
    {
        NavigationHelper.pushRoute({
            name: 'admin-team-edit',
            params: { id: team.id.toString() },
        });
    }
}