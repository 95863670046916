import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Activity from '../../../entities/rewards/activity';
import ActivityApiInterface from '@/model/entities/rewards/activity-api-interface';
import ActivityTransformer from '../../../transformers/rewards/activity-transformer';

export default class PatchActivityTask extends AbstractApiPatchTask
{
    private _activity!: Activity;

    public async run(): Promise<Activity>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return ActivityTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._activity;
    }

    set activity(activity: Activity)
    {
        this._activity = activity;
    }

    protected getUrl(): string
    {
        return `/activities/${ this._activity.id }`;
    }

    protected getData(): ActivityApiInterface
    {
        return ActivityTransformer.toApi(this._activity);
    }
}