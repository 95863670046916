import { Component, Prop, Vue } from 'vue-property-decorator';
import Category from '../../../model/entities/webscenes/category';
import Scene from '../../../model/entities/webscenes/scene';

@Component({
    components:
    {},
})
export default class SceneButtonComponent extends Vue
{
    @Prop()
    private category!: Category;

    @Prop()
    private scene!: Scene;

    /**
     * Returns the image uri for the scene.
     */

    get imageUri(): string|null
    {
        return this.$serviceContainer.getSceneService().getSceneImageUri(this.scene);
    }
}