import AbstractApiPostTask from '../abstract-api-post-task';
import User from '@/model/entities/organisation/user';
import UserApiInterface from '@/model/entities/organisation/user-api-interface';
import UserTransformer from '@/model/transformers/organisation/user-transformer';

export default class RegisterTask extends AbstractApiPostTask
{
    private _user!: User;
    private _password!: string;

    public async run(): Promise<boolean>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return true;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return false;
    }

    set user(user: User)
    {
        this._user = user;
    }

    set password(password: string)
    {
        this._password = password;
    }

    protected getUrl(): string
    {
        return '/register';
    }

    protected getData(): UserApiInterface
    {
        const data = UserTransformer.toApi(this._user);
        data.password = this._password;

        return data;
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}