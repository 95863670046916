/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route } from 'vue-router';
import { isNil } from 'lodash';
import SearchConditionsInterface from '../../vos/search/search-conditions-interface';

export default class SearchConditionsService
{
    /**
     * Updates the search conditions based on the query string of the page.
     *
     * @param route
     * @param defaultLimit
     * @param defaultSortBy
     */

    public getConditionsFromQueryParams(
        route: Route, defaultLimit: number, defaultSortBy: string,
    )
    {
        const conditions: SearchConditionsInterface = {};

        this.setStringCondition(
            route, 'search_term', 'searchTerm', conditions,
        );
        this.setStringCondition(
            route, 'limit', 'limit', conditions, defaultLimit,
        );
        this.setStringCondition(
            route, 'page', 'page', conditions,
        );
        this.setStringCondition(
            route, 'sort_by', 'sortBy', conditions, defaultSortBy,
        );
        this.setBooleanCondition(
            route, 'desc', 'descending', conditions, false,
        );

        return conditions;
    }

    /**
     * Updates the page query string based on the search conditions.
     *
     * @param conditions
     * @param defaultLimit
     * @param defaultSortBy
     */

    public getQueryParamsFromConditions(
        conditions: SearchConditionsInterface, defaultLimit: number, defaultSortBy: string,
    )
    {
        const params: any = {};

        this.setStringParam(
            'search_term', 'searchTerm', conditions, params,
        );

        if (conditions.limit && conditions.limit !== defaultLimit)
        {
            params.limit = conditions.limit;
        }

        if (conditions.page && conditions.page > 1)
        {
            params.page = conditions.page.toString();
        }

        if (conditions.sortBy && conditions.sortBy !== defaultSortBy)
        {
            params.sort_by = conditions.sortBy;
        }

        if (conditions.descending)
        {
            this.setBooleanParam(
                'desc', 'descending', conditions, params,
            );
        }

        return params;
    }

    /**
     * Sets a string condition from the query string.
     *
     * @param route
     * @param param
     * @param property
     * @param conditions
     * @param defaultValue
     */

    protected setStringCondition(
        route: Route, param: string, property: string, conditions: any, defaultValue?: string|number,
    )
    {
        if (param in route.query)
        {
            conditions[property] = route.query[param] as string;
        }
        else
        {
            conditions[property] = isNil(defaultValue) ? null : defaultValue;
        }
    }

    /**
     * Sets a string param from conditions.
     *
     * @param param
     * @param property
     * @param conditions
     * @param params
     */

    protected setStringParam(
        param: string, property: string, conditions: any, params: any,
    )
    {
        if (conditions[property])
        {
            params[param] = conditions[property];
        }
    }

    /**
     * Sets a boolean condition from the query string.
     *
     * @param route
     * @param param
     * @param property
     * @param conditions
     */

    protected setBooleanCondition(
        route: Route, param: string, property: string, conditions: any, defaultValue?: boolean,
    )
    {
        if (param in route.query)
        {
            conditions[property] = route.query[param] === '1';
        }
        else
        {
            conditions[property] = isNil(defaultValue) ? null : defaultValue;
        }
    }

    /**
     * Sets a boolean param from conditions.
     *
     * @param param
     * @param property
     * @param conditions
     * @param params
     */

    protected setBooleanParam(
        param: string, property: string, conditions: any, params: any,
    )
    {
        if (isNil(conditions[property]))
        {
            return;
        }

        params[param] = conditions[property] ? '1' : '0';
    }
}