import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Banner from '../../entities/media/banner';
import BannerApiInterface from '../../entities/media/banner-api-interface';

export default class BannerTransformer
{
    /**
     * Transforms an API banner representation into a Banner entity.
     *
     * @param apiBanner
     */

    public static fromApi(apiBanner: BannerApiInterface, associations?: AssociationsApiInterface): Banner
    {
        const banner = new Banner();
        banner.id = apiBanner.id;
        banner.headline = apiBanner.headline;
        banner.title = apiBanner.title;
        banner.description = apiBanner.description;
        banner.image = apiBanner.image;
        banner.displayOrder = apiBanner.display_order;
        banner.isEnabled = apiBanner.enabled;
        banner.created = parseISO(apiBanner.created);
        banner.updated = parseISO(apiBanner.updated);

        return banner;
    }

    /**
     * Transforms a Banner entity into an API representation of a banner.
     *
     * @param banner
     */

    public static toApi(banner: Banner): BannerApiInterface
    {
        return {
            id: banner.id,
            headline: banner.headline,
            title: banner.title,
            description: banner.description,
            image: banner.image,
            display_order: banner.displayOrder,
            enabled: banner.isEnabled,
            created: formatISO(banner.created),
            updated: formatISO(banner.updated),
        };
    }
}