import { Component, Vue } from 'vue-property-decorator';
import { size } from 'lodash';
import RegistrationFormValidator from '@/model/validator/authentication/registration-form-validator';
import User from '@/model/entities/organisation/user';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({})
export default class RegistrationFormComponent extends Vue
{
    private formUser: User|null = null;
    private password = '';
    private passwordConfirmation = '';

    private validationErrors: ValidationErrorsInterface = {};

    $refs!:
    {
        form: HTMLFormElement
    };

    created()
    {
        this.formUser = new User();
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formUser)
        {
            return false;
        }

        const validator: RegistrationFormValidator = new RegistrationFormValidator();
        this.validationErrors = validator.validate(
            this.formUser, this.password, this.passwordConfirmation,
        );

        return size(this.validationErrors) === 0;
    }

    /**
     * Handles submissions.
     */

    onSubmit()
    {
        // Validation.
        if (!this.isValid)
        {
            return;
        }

        this.$emit(
            'submitted', this.formUser, this.password,
        );
    }

    /**
     * Handles clear requests.
     */

    onClear()
    {
        this.$refs.form.reset();
    }
}