import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import EmployeePointsApiInterface from '../../../vos/organisation/employee-points-api-interface';

export default class GetEmployeePointsTask extends AbstractApiGetTask
{
    private _userId!: number;

    public async run(): Promise<EmployeePointsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return response.data.data;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set userId(userId: number)
    {
        this._userId = userId;
    }

    protected getUrl(): string
    {
        return `/employees/${ this._userId }/points`;
    }
}