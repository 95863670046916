import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Notification from '../../../../../model/entities/notifications/notification';
import NotificationFormComponent from '@/components/widgets/notification-form/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-notification-form': NotificationFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminNotificationCreatePageComponent extends Vue
{
    private notification: Notification|null = null;

    created()
    {
        this.notification = Object.assign({}, new Notification());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.notifications.message.index_title'),
                disabled: false,
                to: { name: 'admin-notification-index' },
                exact: true,
            },
            {
                text: this.$t('admin.notifications.message.create_title'),
                disabled: true,
                to: { name: 'admin-notification-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param notifications
     */

    private async onSave(notification: Notification)
    {
        try
        {
            // Create the notification.
            const created = await this.$serviceContainer.getNotificationService().createNotification(notification);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.notifications.message.notification_created'));

            // Redirect to the notification.
            NavigationHelper.pushRoute({
                name: 'admin-notification-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}