import DeleteTeamTask from '../../tasks/api/organisation/delete-team';
import GetLeaderboardTask from '../../tasks/api/organisation/get-leaderboard';
import GetTeamLeaderboardTask from '../../tasks/api/organisation/get-team-leaderboard';
import GetTeamTask from '../../tasks/api/organisation/get-team';
import LeaderboardApiInterface from '../../vos/organisation/leaderboard-api-interface';
import PatchTeamTask from '../../tasks/api/organisation/patch-team';
import PostTeamTask from '../../tasks/api/organisation/post-team';
import SearchTeamsTask from '../../tasks/api/organisation/search-teams';
import Team from '../../entities/organisation/team';
import TeamLeaderboardApiInterface from '../../vos/organisation/team-leaderboard-api-interface';
import TeamResultsApiInterface from '../../vos/organisation/team-results-api-interface';
import TeamSearchConditionsInterface from '../../vos/organisation/team-search-conditions-interface';

export default class TeamService
{
    /**
     * Returns teams matching the conditions.
     *
     * @param conditions
     */

    public async searchTeams(conditions: TeamSearchConditionsInterface): Promise<TeamResultsApiInterface|null>
    {
        const task = new SearchTeamsTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a team.
     *
     * @param id
     */

    public async loadTeam(id: number): Promise<Team|null>
    {
        const task = new GetTeamTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a team.
     *
     * @param team
     */

    public async createTeam(team: Team): Promise<Team>
    {
        const task = new PostTeamTask();
        task.team = team;

        return task.run();
    }

    /**
     * Updates a team.
     *
     * @param team
     */

    public async updateTeam(team: Team): Promise<Team>
    {
        const task = new PatchTeamTask();
        task.team = team;

        return task.run();
    }

    /**
     * Deletes an team.
     *
     * @param id
     */

    public async deleteTeam(id: number): Promise<void>
    {
        const task = new DeleteTeamTask();
        task.id = id;

        return task.run();
    }

    /**
     * Returns the leaderboard.
     *
     * @param teamId
     */

    public async getLeaderboard(teamId: number): Promise<LeaderboardApiInterface[]>
    {
        const task = new GetLeaderboardTask();
        task.teamId = teamId;

        return task.run();
    }

    /**
     * Returns the team leaderboard.
     *
     */

    public async getTeamLeaderboard(): Promise<TeamLeaderboardApiInterface[]>
    {
        const task = new GetTeamLeaderboardTask();

        return task.run();
    }
}