import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import Banner from '../../../model/entities/media/banner';
import BannerFileInterface from '../../../model/vos/media/banner-file-interface';
import BannerValidator from '../../../model/validator/media/banner-validator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class BannerFormComponent extends Vue
{
    @Prop()
    private banner!: Banner;

    @Prop({ default: 'Save Banner' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formBanner: Banner|null = null;
    private filesToUpload: BannerFileInterface[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formBanner = Object.assign(new Banner(), this.banner);
    }

    @Watch('banner')
    private onBannerUpdated()
    {
        this.formBanner = Object.assign(new Banner(), this.banner);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formBanner)
        {
            return false;
        }

        const validator: BannerValidator = new BannerValidator();
        this.validationErrors = validator.validate(this.formBanner);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Returns the URI for the image
     */

    get imageUri(): string
    {
        if (!this.formBanner || !this.formBanner.image)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + this.formBanner.image.uri;
    }

    /**
     * Returns the orginal filename for the image
     */

    get imageFilename(): string
    {
        if (!this.formBanner || !this.formBanner.image)
        {
            return 'no_file';
        }

        return this.formBanner.image.filename;
    }

    /**
     * Handles image updates
     *
     * @param file
     */

    private onImageChange(file: File)
    {
        this.filesToUpload.push({
            field: 'image',
            file,
        });
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formBanner)
        {
            throw new Error('Invalid banner properties');
        }

        const validator = new BannerValidator();
        const validationErrors = validator.validate(this.formBanner);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid banner properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated banner.
            this.$emit(
                'save', this.formBanner, this.filesToUpload.splice(0),
            );
            this.filesToUpload = [];
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}