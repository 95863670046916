import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Location from '../../../model/entities/organisation/location';
import SafetyAmbassador from '../../../model/entities/organisation/safety-ambassador';
import SafetyAmbassadorFileInterface from '../../../model/vos/organisation/safety-ambassador-file-interface';
import SafetyAmbassadorValidator from '../../../model/validator/organisation/safety-ambassador-validator';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class SafetyAmbassadorFormComponent extends Vue
{
    @Prop()
    private safetyAmbassador!: SafetyAmbassador;

    @Prop({ default: 'Save Safety Ambassador' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formSafetyAmbassador: SafetyAmbassador|null = null;
    private locations: Location[] = [];
    private filesToUpload: SafetyAmbassadorFileInterface[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formSafetyAmbassador = Object.assign(new SafetyAmbassador(), this.safetyAmbassador);

        this.loadLocations();
    }

    @Watch('safetyAmbassador')
    private onSafetyAmbassadorUpdated()
    {
        this.formSafetyAmbassador = Object.assign(new SafetyAmbassador(), this.safetyAmbassador);
    }

    @Watch('formSafetyAmbassador.location')
    private onLocationChanged()
    {
        if (!this.formSafetyAmbassador)
        {
            return;
        }

        this.formSafetyAmbassador.locationId = this.formSafetyAmbassador.location ? this.formSafetyAmbassador.location.id : null;
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formSafetyAmbassador)
        {
            return false;
        }

        const validator: SafetyAmbassadorValidator = new SafetyAmbassadorValidator();
        this.validationErrors = validator.validate(this.formSafetyAmbassador);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the URI for the image
     */

    get imageUri(): string
    {
        if (!this.formSafetyAmbassador || !this.formSafetyAmbassador.image)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + this.formSafetyAmbassador.image.uri;
    }

    /**
     * Returns the orginal filename for the image
     */

    get imageFilename(): string
    {
        if (!this.formSafetyAmbassador || !this.formSafetyAmbassador.image)
        {
            return 'no_file';
        }

        return this.formSafetyAmbassador.image.filename;
    }

    /**
     * Handles image updates
     *
     * @param image
     * @param locale
     */

    private onImageChange(image: File)
    {
        this.filesToUpload.push({
            field: 'file',
            file: image,
        });
    }

    /**
     * Loads the available locations.
     */

    private async loadLocations()
    {
        const results = await this.$serviceContainer.getLocationService().searchLocations({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.locations = results.results;
        }
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formSafetyAmbassador)
        {
            throw new Error('Invalid safety ambassador properties');
        }

        const validator = new SafetyAmbassadorValidator();
        const validationErrors = validator.validate(this.formSafetyAmbassador);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid safety ambassador properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated safetyAmbassador.
            this.$emit(
                'save', this.formSafetyAmbassador, this.filesToUpload.splice(0),
            );
            this.filesToUpload = [];
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}