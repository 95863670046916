import AbstractValidator from '../abstract-validator';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class ResetPasswordFormValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a login form.
     *
     * @param password
     * @param passwordConfirmation
     */

    public validate(password: string, passwordConfirmation: string): ValidationErrorsInterface
    {
        this.errors = {};

        this.validatePassword(
            password, passwordConfirmation, this.errors,
        );

        return this.errors;
    }

    private validatePassword(
        password: string, passwordConfirmation: string, validationErrors: ValidationErrorsInterface,
    )
    {
        const errors: string[] = [];
        const confirmationErrors: string[] = [];

        if (!this.isValidString(password, 8))
        {
            errors.push('Please enter a password (min 8 characters)');
        }

        if (password !== passwordConfirmation)
        {
            confirmationErrors.push('The passwords do not match');
        }

        if (errors.length > 0)
        {
            validationErrors.password = errors;
        }

        if (confirmationErrors.length > 0)
        {
            validationErrors.passwordConfirmation = confirmationErrors;
        }
    }
}