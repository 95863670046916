const messages =
{
    'en':
    {
        admin:
        {
            activities:
            {
                action:
                {
                    update_activity: 'Update Activity',
                },
                message:
                {
                    activity_created: 'Activity created',
                    activity_updated: 'Activity updated',
                    create_title: 'Create Activity',
                    create_description: 'Use the form below to create a new activity',
                    edit_title: 'Edit Activity',
                    edit_description: 'Use the form below to edit the current activity',
                    index_title: 'Activities',
                    index_description: 'Edit the activities in the system',
                },
                validation:
                {
                    please_enter_points: 'Please enter the points for the activity',
                },
            },
            banners:
            {
                action:
                {
                    update_banner: 'Update Banner',
                },
                field:
                {
                    headline: 'Headline',
                },
                message:
                {
                    create_title: 'Create Banner',
                    create_description: 'Use the form below to create a new banner',
                    delete_title: 'Delete Banner',
                    delete_description: 'Are you sure you want to delete this banner?\n\nThis action is permanent.',
                    edit_title: 'Edit Banner',
                    edit_description: 'Use the form below to edit the current banner',
                    index_title: 'Banners',
                    index_description: 'Edit the banners in the system',
                    banner_created: 'Banner created',
                    banner_deleted: 'Banner deleted',
                    banner_updated: 'Banner updated',
                },
                validation:
                {
                    please_enter_headline: 'Please enter the headline',
                },
            },
            categories:
            {
                action:
                {
                    update_category: 'Update Category',
                },
                field:
                {
                    has_new_scenes: 'Has New Scenes?',
                },
                message:
                {
                    category_created: 'Category created',
                    category_updated: 'Category updated',
                    create_title: 'Create Category',
                    create_description: 'Use the form below to create a new category',
                    edit_title: 'Edit Category',
                    edit_description: 'Use the form below to edit the current category',
                    index_title: 'Categories',
                    index_description: 'Edit the safety categories in the system',
                },
            },
            employees:
            {
                action:
                {
                    add_activity: 'Add Activity',
                    add_reward: 'Add Reward',
                    update_employee: 'Update Employee',
                },
                field:
                {
                    activity: 'Activity',
                    points_summary: 'Points Summary',
                    reward: 'Reward',
                },
                message:
                {
                    activity_assigned: 'Activity successfully assigned to employee',
                    add_activity_question: 'Add Activity?',
                    add_activity_confirmation: 'Are you sure you want to add this activity to the employee\'s account?\n\nThis will update their redeemable points.',
                    add_reward_question: 'Add Reward?',
                    add_reward_confirmation: 'Are you sure you want to add this reward to the employee\'s account?\n\nThis will update their redeemable points.',
                    create_title: 'Create Employee',
                    create_description: 'Use the form below to create a new employee',
                    delete_employee_question: 'Delete Employee?',
                    delete_employee_confirmation: 'Are you sure you want to delete this employee?\n\nThis action is permanent and all data associated with this employee will be deleted.',
                    edit_title: 'Edit Employee',
                    edit_description: 'Use the form below to edit the current employee',
                    employee_created: 'Employee created',
                    employee_deleted: 'Employee deleted',
                    employee_updated: 'Employee updated',
                    index_title: 'Employees',
                    index_description: 'Edit the employees in the system',
                    reward_assigned: 'Reward successfully assigned to employee',
                },
                validation:
                {
                    please_enter_a_job_level: 'Please enter a job level',
                    please_enter_the_employees_number: 'Please enter the employee\'s number',
                },
            },
            first_aiders:
            {
                action:
                {
                    update_first_aider: 'Update First Aider',
                },
                message:
                {
                    create_title: 'Create First Aider',
                    create_description: 'Use the form below to create a new first aider',
                    delete_title: 'Delete First Aider',
                    delete_description: 'Are you sure you want to delete this first aider?\n\nThis action is permanent.',
                    edit_title: 'Edit First Aider',
                    edit_description: 'Use the form below to edit the current first aider',
                    index_title: 'First Aiders',
                    index_description: 'Edit the first aiders in the system',
                    first_aider_created: 'First Aider created',
                    first_aider_deleted: 'First Aider deleted',
                    first_aider_updated: 'First Aider updated',
                },
                validation:
                {
                    please_enter_headline: 'Please enter the headline',
                },
            },
            nav:
            {
                activities: 'Activities',
                banners: 'Banners',
                categories: 'Categories',
                employees: 'Employees',
                first_aiders: 'First Aiders',
                locations: 'Locations',
                newsletters: 'Newsletters',
                notifications: 'Notifications',
                posts: 'Posts',
                rewards: 'Rewards',
                safety_ambassadors: 'Safety Ambassadors',
                scenes: 'Scenes',
                teams: 'Teams',
                translations: 'Translations',
                users: 'Users',
                videos: 'Videos',
            },
            newsletters:
            {
                action:
                {
                    update_newsletter: 'Update Newsletter',
                },
                field:
                {
                    release_date: 'Release Date',
                },
                message:
                {
                    create_title: 'Create Newsletter',
                    create_description: 'Use the form below to create a new newsletter',
                    delete_title: 'Delete Newsletter',
                    delete_description: 'Are you sure you want to delete this newsletter?\n\nThis action is permanent.',
                    edit_title: 'Edit Newsletter',
                    edit_description: 'Use the form below to edit the current newsletter',
                    index_title: 'Newsletters',
                    index_description: 'Edit the newsletters in the system',
                    newsletter_created: 'Newsletter created',
                    newsletter_deleted: 'Newsletter deleted',
                    newsletter_updated: 'Newsletter updated',
                },
                validation:
                {
                    please_enter_headline: 'Please enter the headline',
                },
            },
            notifications:
            {
                action:
                {
                    update_notification: 'Update Notification',
                },
                message:
                {
                    create_title: 'Create Notification',
                    create_description: 'Use the form below to create a new notification',
                    delete_title: 'Delete Notification',
                    delete_description: 'Are you sure you want to delete this notification?\n\nThis action is permanent.',
                    edit_title: 'Edit Notification',
                    edit_description: 'Use the form below to edit the current notification',
                    notification_created: 'Notification created',
                    notification_deleted: 'Notification deleted',
                    notification_updated: 'Notification updated',
                    index_title: 'Notifications',
                    index_description: 'Edit the notifications in the system',
                },
            },
            posts:
            {
                action:
                {
                    update_post: 'Update Post',
                },
                field:
                {
                    body: 'Body',
                    published_date: 'Published Date',
                    slug: 'Slug',
                },
                message:
                {
                    create_title: 'Create Post',
                    create_description: 'Use the form below to create a new post',
                    edit_title: 'Edit Post',
                    edit_description: 'Use the form below to edit the current post',
                    index_title: 'Posts',
                    index_description: 'Edit the posts in the system',
                    post_created: 'Post created',
                    post_updated: 'Post updated',
                },
                validation:
                {
                    please_enter_a_body: 'Please enter the body for the post',
                },
            },
            rewards:
            {
                action:
                {
                    update_reward: 'Update Reward',
                },
                message:
                {
                    create_title: 'Create Reward',
                    create_description: 'Use the form below to create a new reward',
                    edit_title: 'Edit Reward',
                    edit_description: 'Use the form below to edit the current reward',
                    index_title: 'Rewards',
                    index_description: 'Edit the rewards in the system',
                    reward_created: 'Reward created',
                    reward_updated: 'Reward updated',
                },
                validation:
                {
                    please_enter_points: 'Please enter the points required',
                },
            },
            safety_ambassadors:
            {
                action:
                {
                    update_safety_ambassador: 'Update Safety Ambassador',
                },
                message:
                {
                    create_title: 'Create Safety Ambassador',
                    create_description: 'Use the form below to create a new safety ambassador',
                    delete_title: 'Delete Safety Ambassador',
                    delete_description: 'Are you sure you want to delete this safety ambassador?\n\nThis action is permanent.',
                    edit_title: 'Edit Safety Ambassador',
                    edit_description: 'Use the form below to edit the current safety ambassador',
                    index_title: 'Safety Ambassadors',
                    index_description: 'Edit the safety ambassadors in the system',
                    safety_ambassador_created: 'Safety Ambassador created',
                    safety_ambassador_deleted: 'Safety Ambassador deleted',
                    safety_ambassador_updated: 'Safety Ambassador updated',
                },
                validation:
                {
                    please_enter_headline: 'Please enter the headline',
                },
            },
            scenes:
            {
                action:
                {
                    add_answer: 'Add Answer',
                    add_question: 'Add Question',
                    delete_answer: 'Delete Answer',
                    delete_question: 'Delete Question',
                    update_scene: 'Update Scene',
                },
                field:
                {
                    correct: 'Correct',
                    introduction: 'Introduction',
                    question: 'Question',
                    questions: 'Questions',
                    answer: 'Answer',
                    answers: 'Answers',
                    scene: 'Scene',
                    selection_text: 'Selection text',
                },
                message:
                {
                    create_title: 'Create Scene',
                    create_description: 'Use the form below to create a new scene',
                    edit_title: 'Edit Scene',
                    edit_description: 'Use the form below to edit the current scene',
                    index_title: 'Scenes',
                    index_description: 'Edit the scenes for a safety category',
                    scene_created: 'Scene created',
                    scene_updated: 'Scene updated',
                },
                validation:
                {
                    please_enter_an_introduction: 'Please enter an introduction',
                },
            },
            teams:
            {
                action:
                {
                    update_team: 'Update Team',
                },
                message:
                {
                    create_title: 'Create Team',
                    create_description: 'Use the form below to create a new team',
                    edit_title: 'Edit Team',
                    edit_description: 'Use the form below to edit the current team',
                    index_title: 'Teams',
                    index_description: 'Edit the teams in the system',
                    team_created: 'Team created',
                    team_updated: 'Team updated',
                },
            },
            translations:
            {
                message:
                {
                    edit_interface_title: 'Interface Translations',
                    edit_interface_description: 'This page allows you to update the text & translations used in the interface of the site',
                    heading: 'Update the translations and click save',
                    interface_translations_updated: 'The interface translations have been updated',
                },
            },
            users:
            {
                action:
                {
                    edit: 'Edit User',
                    update: 'Update User',
                },
                field:
                {
                    information: 'Information',
                    marketing: 'Marketing',
                    newsletter: 'Newsletter',
                    notifications: 'Notifications',
                    registered: 'Registered',
                },
                message:
                {
                    edit_description: 'Use the form below to edit the current user',
                    index_title: 'Users',
                    index_description: 'View and edit users',
                    user_updated: 'User updated',
                },
            },
            videos:
            {
                action:
                {
                    update_video: 'Update Video',
                },
                field:
                {
                    url: 'Url',
                },
                message:
                {
                    create_title: 'Create Video',
                    create_description: 'Use the form below to create a new video',
                    delete_title: 'Delete Video',
                    delete_description: 'Are you sure you want to delete this video?\n\nThis action is permanent.',
                    edit_title: 'Edit Video',
                    edit_description: 'Use the form below to edit the current video',
                    index_title: 'Videos',
                    index_description: 'Edit the videos in the system',
                    video_created: 'Video created',
                    video_deleted: 'Video deleted',
                    video_updated: 'Video updated',
                },
                validation:
                {
                    please_enter_url: 'Please enter the url to where the video is accessible',
                },
            },
        },
        authentication:
        {
            action:
            {
                click_here_to_login: 'Please click here to login',
                login: 'Login',
                logout: 'Logout',
                register: 'Register',
                register_account: 'Register Account',
                reset_password: 'Reset Password',
            },
            error:
            {
                access_denied: 'Access Denied',
                access_denied_description: 'You do not have access to this page',
                invalid_user: 'The account details were invalid',
                password_already_reset: 'Your password has recently been reset. Please wait 24 hours before trying again.',
                problem_logging_in: 'Sorry, there was a problem logging in. Please try again.',
                problem_registering: 'Sorry, there was a problem registering. Please try again.',
                problem_resetting_password: 'Sorry, there was a problem resetting your password.',
                passwords_do_not_match: 'The passwords do not match',
                user_already_exists: 'This email address is already in use',
            },
            field:
            {
                new_password: 'New Password',
                new_password_confirmation: 'New Password (confirmation)',
                password: 'Password',
                password_confirmation: 'Password (confirmation)',
            },
            message:
            {
                already_have_an_account: 'Already have an account?',
                been_logged_out: 'You have been logged out',
                delete_account_question: 'Delete account?',
                delete_account_confirmation: 'Are you sure you want to delete your account? All data will be lost. This action is not recoverable.',
                logging_in: 'Logging In...',
                please_enter_a_first_name: 'Please enter a first name',
                please_enter_a_last_name: 'Please enter a last name',
                please_enter_an_email: 'Please enter an email',
                please_enter_a_password_characters: 'Please enter a password (min {characters} characters)',
                please_enter_a_phone_number: 'Please enter a phone number',
                please_enter_a_role: 'Please enter a role',
                please_enter_your_email_address: 'Please enter your email address',
                please_enter_your_password: 'Please enter your password',
                please_login_to_access_content: 'Please login to access this content',
                please_select_a_location: 'Please select a location',
                please_select_a_type: 'Please select a type',
                registering: 'Creating account...',
                resetting_password: 'Resetting password...',
                reset_password_request_made: 'Your request has been made, please check your email for further instructions',
                reset_password_complete: 'Your password has been reset, please {action}',
                reset_password_complete_action: 'login',
                successfully_registered: 'You have successfully registered!',
            },
        },
        banners:
        {
            hand_safety:
            {
                catchphrase: 'The Power Is In Your Hands!',
                sub_title: 'Own It, Know It, Control It, Watch It.',
                title: 'Hand Safety.',
            },
            lorem:
            {
                catchphrase: 'Lorem ipsum',
                sub_title: 'Aliquam imperdiet diam erat',
                title: 'Lorem ipsum',
            },
        },
        blog:
        {
            action:
            {
                read_more: 'Read more',
            },
            message:
            {
                index_description: 'View the latest articles from the Stronger Together team',
            },
        },
        branding:
        {
            title: 'Online Safety Awareness',
        },
        categories:
        {
            message:
            {
                incomplete_scenes: 'Incomplete Scenes',
                index_description: 'Click on a category below to complete scenes relating to that section. Please note some webscenes may appear under more than one category.',
                latest_scenes: 'Latest Scenes',
            },
        },
        employees:
        {
            field:
            {
                employee_number: 'Employee Number',
                job_level: 'Job Level',
            },
        },
        feedback:
        {
            message:
            {
                description: 'Please give us your feedback. Use this form to comment on aspects of the Stronger Together safety initiative - your feedback is important.\n\nIf you would like a reply to your feedback please supply your Name, Telephone Number or Email Address, and we\'ll be in touch soon.',
                loader_text: 'Please wait, submitting feedback',
                please_enter_below: 'Please enter your feedback below',
                thank_you: 'Thank you for submitting your feedback',
            },
        },
        first_aiders:
        {
            field:
            {
                type: 'Type',
            },
            message:
            {
                mental_health: 'Mental Health',
                mental_health_description: 'Please find details of your Mental Health First Aiders below',
            },
        },
        general:
        {
            action:
            {
                add: 'Add',
                admin: 'Admin',
                cancel: 'Cancel',
                clear: 'Clear',
                close: 'Close',
                create: 'Create',
                delete: 'Delete',
                download: 'Download',
                edit: 'Edit',
                reset: 'Reset',
                save: 'Save',
                submit: 'Submit',
                update: 'Update',
                view: 'View',
            },
            error:
            {
                failed_deleting_server_error: 'Failed deleting - please contact support if this continues',
                failed_saving_invalid_details: 'Failed saving due to invalid details',
                failed_saving_server_error: 'Failed saving - please contact support if this continues',
            },
            field:
            {
                active: 'Active',
                categories: 'Categories',
                category: 'Category',
                created: 'Created',
                date: 'Date',
                description: 'Description',
                disabled: 'Disabled',
                email: 'Email',
                enabled: 'Enabled',
                filters: 'Filters',
                first_name: 'First Name',
                id: 'Id',
                image: 'Image',
                last_name: 'Last Name',
                latest_notifications: 'Latest Notifications',
                location: 'Location',
                locations: 'Locations',
                name: 'Name',
                new_notifications: 'New Notifications',
                notes: 'Notes',
                options: 'Options',
                order: 'Order',
                phone: 'Phone',
                points: 'Points',
                progress: 'Progress',
                reference: 'Reference',
                role: 'Role',
                roles: 'Roles',
                scenes: 'Scenes',
                status: 'Status',
                tags: 'Tags',
                title: 'Title',
                team: 'Team',
                text: 'Text',
                total: 'Total',
                updated: 'Updated',
                user: 'User',
            },
            message:
            {
                enter_username_here: 'Enter username here',
                ie11_support: 'IE11 is not supported',
                modern_browser: 'Please use any modern browser such as Chrome, Firefox, Safari or Microsoft Edge',
                not_logged_in: 'You are not logged in',
                welcome_back_name: 'Welcome back {name}',
            },
            validation:
            {
                please_enter_a_description: 'Please enter a description',
                please_enter_a_name: 'Please enter a name',
                please_enter_a_title: 'Please enter a title',
            },
        },
        help:
        {
            message:
            {
                description: 'Please use the information below to obtain help for your query',
            },
        },
        leaderboard:
        {
            field:
            {
                activity: 'Activity',
                members: 'Members',
                points_awarded: 'Points Awarded',
                redeemable_points: 'Redeemable Points',
                total_members: '{total} members',
            },
            message:
            {
                employee_not_configured: 'Your account has not yet been linked with your employee details',
                leaderboard: 'Leaderboard',
                leaderboard_description: 'The table below shows how you stand within your own team',
                not_member_of_team: 'You are not currently a member of a team',
                team_leaderboard: 'Team Leaderboard',
                team_leaderboard_description: 'The table below shows the positioning of the teams for your site. All the personal points earned by your individual team members count towards the teams total. The total shown for your team is the total earned by the team divided by the number of team members.\n\nImproving the position of your team is easy - encourage your other team members to carry out safety activities and get those points accumulated – all the while knowing that every safety activity carried out contributes to a safer work place.',
            },
        },
        nav:
        {
            blog: 'Blog',
            feedback: 'Feedback',
            first_aiders: 'First Aiders',
            first_aiders_mental_health: 'Mental Health First Aiders',
            help: 'Help',
            home: 'Home',
            newsletters: 'Newsletters',
            points_tables: 'Points Tables',
            resources: 'Resources',
            rewards: 'Rewards',
            safety_ambassadors: 'Safety Ambassadors',
            safety_categories: 'Web Scenes',
            safety_topics: 'Safety Topics',
            table: 'Table',
            team_leaderboard: 'Overall Team Leaderboard',
            videos: 'Videos',
            your_leaderboard: 'Your Team Leaderboard',
            your_points: 'Your Points',
        },
        newsletters:
        {
            message:
            {
                index_description: 'View the latest newsletters from the Stronger Together team',
            },
        },
        notifications:
        {
            message:
            {
                no_notifications: 'There are no notifications',
                notifications: 'Notifications',
            },
        },
        points:
        {
            message:
            {
                points: 'Your Points',
                points_description: 'View how many points you have earned for the current period and how many you have available to redeem',
            },
        },
        rewards:
        {
            action:
            {
                claim: 'Claim',
                claim_this_reward: 'Claim this reward',
            },
            message:
            {
                index_description: 'Browse rewards and claim with the points you\'ve earned',
                n_points: '{points} Points',
                n_point_rewards: '{points} Point Rewards',
            },
        },
        safety_ambassadors:
        {
            message:
            {
                index_description: 'Meet your Safety Ambassadors',
            },
        },
        scenes:
        {
            action:
            {
                back_to_categories: 'Back to Categories',
                back_to_category: 'Back to Category',
                search_scenes: 'Search Scenes',
            },
            field:
            {
                correct: 'Correct',
                other_scenes_in_category: 'Other scenes in this category',
            },
            message:
            {
                all_questions_answered: 'All questions have been answered',
                complete: 'Complete',
                continue: 'Continue',
                exit_scene: 'Exit Scene',
                failed: 'Failed',
                images_title: 'Click on an image to answer the associated question',
                no_incomplete_scenes: 'None found',
                no_results: 'No matching scenes were found. Please try again.',
                question_already_answered: 'This question has already been answered',
                restart_scene: 'Restart Scene',
                results_title: 'Results',
                scene_title: 'Navigate the scene and click on hazards to answer safety questions',
                search_results: 'Search Results',
                search_results_description: 'Showing the first 20 matches for your search term',
            },
        },
        videos:
        {
            message:
            {
                index_description: 'View the latest videos from the Stronger Together team',
            },
        },
    },
    'nl-BE':
    {
        admin:
        {
            activities:
            {
                action:
                {
                    update_activity: 'Update Activiteit',
                },
                message:
                {
                    activity_created: 'Activiteit Aangemaakt',
                    activity_updated: 'Activiteit Geupdate',
                    create_title: 'Activiteit Aanmaken',
                    create_description: 'Gebruik onderstaande template om een nieuwe activiteit aan te maken',
                    edit_title: 'Wijzig Activiteit',
                    edit_description: 'Gebruik onderstaande template om de activiteit te wijzigen',
                    index_title: 'Activiteiten',
                    index_description: 'Wijzig de activiteiten in het systeem',
                },
                validation:
                {
                    please_enter_points: 'Geef aub de punten in voor deze activiteit',
                },
            },
            categories:
            {
                action:
                {
                    update_category: 'Update Categorie',
                },
                message:
                {
                    category_created: 'Categorie aangemaakt',
                    category_updated: 'Categorie geupdate',
                    create_title: 'Categorie aanmaken',
                    create_description: 'Gebruik onderstaande template om een nieuwe categorie aan te maken',
                    edit_title: 'Wijzig Categorie',
                    edit_description: 'Gebruik onderstaande template om de categorie te wijzigen',
                    index_title: 'Categorieën',
                    index_description: 'Wijzig de veiligheidscategorieën in het systeem',
                },
            },
            employees:
            {
                action:
                {
                    add_activity: 'Activiteit toevoegen',
                    add_reward: 'Toevoegen beloning',
                    update_employee: 'Update gebruiker',
                },
                field:
                {
                    activity: 'Activiteit',
                    points_summary: 'Overzicht punten',
                    reward: 'Beloning',
                },
                message:
                {
                    activity_assigned: 'Activiteit goed toegewezen aan gebruiker',
                    add_activity_question: 'Toevoegen Activiteit?',
                    add_activity_confirmation: 'Ben je zeker dat je deze activiteit wilt toevoegen aan de gebruikers account?\nDit zal hun tegoed aan punten wijzigen.',
                    add_reward_question: 'Beloning toevoegen?',
                    add_reward_confirmation: 'Ben je zeker dat je deze beloning wilt toevoegen aan de gebruikers account?\nDit zal hun tegoed aan punten wijzigen.',
                    create_title: 'Gebruiker aanmaken',
                    create_description: 'Gebruik onderstaande template om nieuwe gebruiker aan te maken',
                    edit_title: 'Wijzig gebruiker',
                    edit_description: 'Gebruik onderstaande template om de gebruiker te wijzigen',
                    employee_created: 'Gebruiker aangemaakt',
                    employee_updated: 'Gebruiker geupdate',
                    index_title: 'Gebruikers',
                    index_description: 'Wijzig de gebruikers in het systeem',
                    reward_assigned: 'Beloning goed toegewezen aan de gebruiker',
                },
                validation:
                {
                    please_enter_a_job_level: 'Voeg een nieuwe functie toe',
                    please_enter_the_employees_number: 'Geef het personeelsnr in',
                },
            },
            nav:
            {
                activities: 'Activiteiten',
                categories: 'Categorieën',
                employees: 'Werknemers',
                locations: 'Locaties',
                notifications: 'Meldingen',
                rewards: 'Beloningen',
                scenes: 'Scenes',
                teams: 'Teams',
                users: 'Gebruikers',
            },
            notifications:
            {
                action:
                {
                    update_notification: 'Update Meldingen',
                },
                message:
                {
                    create_title: 'Maak melding aan',
                    create_description: 'Gebruik onderstaand template om nieuwe meldingen aan te maken',
                    edit_title: 'Wijzig melding',
                    edit_description: 'Gebruik onderstaand template om de huidige melding te wijzigen',
                    notification_created: 'Meldingen aangemaakt',
                    notification_updated: 'Melding geupdate',
                    index_title: 'Meldingen',
                    index_description: 'Wijzig de meldingen in het systeem',
                },
            },
            rewards:
            {
                action:
                {
                    update_reward: 'Update beloning',
                },
                message:
                {
                    create_title: 'Aanmaken beloning',
                    create_description: 'Gebruik de onderstaande template om een nieuwe beloning aan te maken',
                    edit_title: 'Wijzig beloning',
                    edit_description: 'Gebruik onderstaande template om de huidge beloning te wijzigen',
                    index_title: 'Beloningen',
                    index_description: 'Wijzig de beloningen in het systeem',
                    reward_created: 'Beloning aangemaakt',
                    reward_updated: 'Beloning geupdate',
                },
                validation:
                {
                    please_enter_points: 'Geef de vereiste punten in',
                },
            },
            scenes:
            {
                action:
                {
                    add_answer: 'Antwoord toevoegen',
                    add_question: 'Vraag toevoegen',
                    delete_answer: 'Antwoord verwijderen',
                    delete_question: 'Vraag verwijderen',
                    update_scene: 'Update Scene',
                },
                field:
                {
                    correct: 'Juist',
                    introduction: 'Introductie',
                    question: 'Vraag',
                    questions: 'Vragen',
                    answer: 'Antwoord',
                    answers: 'Antwoorden',
                    scene: 'Scene',
                    selection_text: 'Selecteer tekst',
                },
                message:
                {
                    create_title: 'Aanmaken Scene',
                    create_description: 'Gebruik onderstaande template om een nieuwe scene aan te maken',
                    edit_title: 'Wijzig Scene',
                    edit_description: 'Gebruik onderstaande template om de huidge scene te wijzigen',
                    index_title: 'Scenes',
                    index_description: 'Wijzig de scenes voor een veiligheidscategorie',
                    scene_created: 'Scene aangemaakt',
                    scene_updated: 'Scene geupdate',
                },
                validation:
                {
                    please_enter_an_introduction: 'Geef een introductie in',
                },
            },
            teams:
            {
                action:
                {
                    update_team: 'Update Team',
                },
                message:
                {
                    create_title: 'Aanmaken Team',
                    create_description: 'Gebruik onderstaande template om een nieuw team aan te maken',
                    edit_title: 'Wijzig Team',
                    edit_description: 'Gebruik onderstaande template om een huidig team te wijzigen',
                    index_title: 'Teams',
                    index_description: 'Wijzig de teams in het systeem',
                    team_created: 'Team aangemaakt',
                    team_updated: 'Team geupdate',
                },
            },
            users:
            {
                action:
                {
                    edit: 'Wijzig Gebruiker',
                    update: 'Update Gebruiker',
                },
                field:
                {
                    information: 'Informatie',
                    marketing: 'Marketing',
                    newsletter: 'Nieuwsbrief',
                    notifications: 'Meldingen',
                    registered: 'Geregistreerd',
                },
                message:
                {
                    edit_description: 'Gebruik onderstaande template om de gebruiker te wijzigen',
                    index_title: 'Gebruikers',
                    index_description: 'Bekijk en wijzig gebruikers',
                    user_updated: 'Gebruiker geupdate',
                },
            },
        },
        authentication:
        {
            action:
            {
                click_here_to_login: 'Klik hier om in te loggen',
                login: 'Login',
                logout: 'Logout',
                register: 'Registeer',
                register_account: 'Registeer Gebruiker',
                reset_password: 'Wijzig Wachtwoord',
            },
            error:
            {
                access_denied: 'Geen toegang',
                access_denied_description: 'Je hebt geen toegang tot deze pagina',
                invalid_user: 'De gebruikersgegevens zijn niet correct',
                password_already_reset: 'Uw wachtwoord werd recent gewijzigd. Gelieve 24 uur te wachten voor een nieuw poging.',
                problem_logging_in: 'Sorry, er was een probleem met het inloggen. Probeer aub opnieuw.',
                problem_registering: 'Sorry, er was een probleem met het registreren. Probeer aub opnieuw.',
                problem_resetting_password: 'Sorry, er was een probleem met het resetten van uw wachtwoord.',
                passwords_do_not_match: 'De wachtwoorden komen niet overeen',
                user_already_exists: 'Dit emailadres is reeds in gebruik',
            },
            field:
            {
                new_password: 'Nieuw wachtwoord',
                new_password_confirmation: 'Nieuw Wachtwoord (bevestiging)',
                password: 'Wachtwoord',
                password_confirmation: 'Wachtwoord (bevestiging)',
            },
            message:
            {
                already_have_an_account: 'Heb je al een account?',
                been_logged_out: 'Je bent uitgelogd',
                delete_account_question: 'Verwijder account?',
                delete_account_confirmation: 'Ben je zeker dat je deze account wilt verwijderen? Alle gegevens zullen verloren gaan. Deze actie kan niet ongedaan gemaakt worden.',
                logging_in: 'Aanmelden ...',
                please_enter_a_first_name: 'Geef je voornaam in',
                please_enter_a_last_name: 'Geef je achternaam in',
                please_enter_an_email: 'Geef je emailadres in',
                please_enter_a_password_characters: 'Geef een wachtwoord in (min {characters} karakters)',
                please_enter_your_email_address: 'Geef je emailadres in',
                please_enter_your_password: 'Geef je wachtwoord in',
                please_login_to_access_content: 'Login om toegang te krijgen tot deze account',
                registering: 'Aanmaken account ...',
                resetting_password: 'Vernieuwen wachtwoord ...',
                reset_password_request_made: 'Uw aanvraag is uitgevoerd, controleer aub uw emails voor verdere instructies',
                reset_password_complete: 'Uw wachtwoord werd gewijzigd, aub {action}',
                reset_password_complete_action: 'login',
                successfully_registered: 'U bent correct geregistreerd!',
            },
        },
        banners:
        {
            hand_safety:
            {
                catchphrase: 'De kracht ligt in uw handen!',
                sub_title: 'Own It, Know It, Control It, Watch It.',
                title: 'Hand Veiligheid.',
            },
            lorem:
            {
                catchphrase: 'Lorem ipsum',
                sub_title: 'Aliquam imperdiet diam erat',
                title: 'Lorem ipsum',
            },
        },
        branding:
        {
            title: 'Online Veiligheids bewustmaking',
        },
        categories:
        {
            message:
            {
                incomplete_scenes: 'Onvolledige Scenes',
                index_description: 'Klik op een van onderstaande categorieën om de gerelateerde scenes te vervolledigen. Sommige webscenes zijn gerelateerd aan meerdere categorieën.',
                latest_scenes: 'Meest recente Scenes',
            },
        },
        employees:
        {
            field:
            {
                employee_number: 'Personeelsnr',
                job_level: 'Functie',
            },
        },
        feedback:
        {
            message:
            {
                description_1: 'Uw feedback is gewenst. Gebruik dit formulier om je opmerkingen aangaande het Samen Sterker veiligheidsinitiatief kenbaar te maken - uw feedback is belangrijk.',
                description_2: 'Als je een antwoord wilt ontvangen op je gegeven feedback, vul dan je naam, telefoonnr of emailadres in en we contacteren je spoedig.',
                loader_text: 'Gelieve te wachten, feedback aan het doorsturen',
                please_enter_below: 'Geef hier je feedback in',
                thank_you: 'Bedankt voor je feedback',
            },
        },
        general:
        {
            action:
            {
                add: 'Toevoegen',
                admin: 'Admin',
                cancel: 'Annuleer',
                clear: 'Clear',
                close: 'Sluit',
                create: 'Aanmaak',
                delete: 'Verwijder',
                download: 'Downloaden',
                edit: 'Wijzig',
                reset: 'Reset',
                save: 'Bewaar',
                submit: 'Submit',
                update: 'Update',
                view: 'Bekijk',
            },
            error:
            {
                failed_saving_invalid_details: 'Fout bij het opslaan door ongeldige gegevens',
                failed_saving_server_error: 'Opslaan mislukt - neem contact op met uw leidinggevende indien dit probleem zich blijft voordoen',
            },
            field:
            {
                active: 'Actief',
                categories: 'Categorieën',
                category: 'Categorie',
                created: 'Aangemaakt',
                date: 'Datum',
                description: 'Omschrijving',
                disabled: 'Geblokkeerd',
                email: 'Email',
                enabled: 'Vrijgegeven',
                filters: 'Filters',
                first_name: 'Voornaam',
                id: 'Id',
                image: 'Afbeelding',
                last_name: 'Achternaam',
                latest_notifications: 'Meest recente meldingen',
                location: 'Locatie',
                locations: 'Locaties',
                name: 'Naam',
                new_notifications: 'Nieuwe meldingen',
                notes: 'Opmerkingen',
                phone: 'Telefoon',
                points: 'Punten',
                progress: 'Vooruitgang',
                reference: 'Referentie',
                role: 'Rol',
                roles: 'Rollen',
                scenes: 'Scenes',
                status: 'Status',
                team: 'Team',
                text: 'Tekst',
                total: 'Totaal',
                updated: 'Geupdate',
                user: 'Gebruiker',
            },
            message:
            {
                enter_username_here: 'Geef hier je gebruikersnaam in',
                ie11_support: 'IE11 wordt niet ondersteund',
                modern_browser: 'Gebruik Chrome, Firefox, Safari of Microsoft Edge',
                not_logged_in: 'U bent niet ingelogd',
                welcome_back_name: 'Welkom terug {name}',
            },
            validation:
            {
                please_enter_a_description: 'Geef een beschrijving in',
                please_enter_a_name: 'Geef een naam in',
                please_enter_a_title: 'Geef een functie in',
            },
        },
        help:
        {
            message:
            {
                description: 'Maak gebruik van onderstaande informatie om hulp te verkrijgen bij uw zoekopdracht',
            },
        },
        leaderboard:
        {
            field:
            {
                activity: 'Activiteit',
                members: 'Leden',
                points_awarded: 'Toegewezen punten',
                redeemable_points: 'Inwisselbare Punten',
                total_members: '{total} leden',
            },
            message:
            {
                employee_not_configured: 'Uw gebruiker werd nog niet gelinkt aan uw personeelsgegevens',
                leaderboard: 'Scorebord',
                leaderboard_description: 'Onderstaande tabel geeft een overzicht van uw zelf tov uw team',
                not_member_of_team: 'U bent momenteel geen lid van een team',
                team_leaderboard: 'Team Scorebord',
                team_leaderboard_description_1: 'De onderstaande tabel toont de positionering van de teams voor uw site. Alle persoonlijke punten verdiend door uw individuele teamleden tellen mee voor het totaal van de teams. Het totaal voor uw team is het totaal dat het team heeft verdiend, gedeeld door het aantal teamleden.',
                team_leaderboard_description_2: 'Het verbeteren van de positie van uw team is eenvoudig - moedig uw teamleden aan om veiligheidsactiviteiten uit te voeren en verzamel alzo punten - wetende dat elke veiligheidsactiviteit die wordt uitgevoerd bijdraagt aan een veiligere werkplek.',
            },
        },
        nav:
        {
            home: 'Home',
            safety_categories: 'Web Scenes',
            points_tables: 'Puntentabel',
            table: 'Tabel',
            rewards: 'Beloningen',
            feedback: 'Feedback',
            help: 'Help',
            team_leaderboard: 'Overzicht Team Scorebord',
            your_leaderboard: 'Uw Team Scorebord',
            your_points: 'Uw Punten',
        },
        notifications:
        {
            message:
            {
                no_notifications: 'Er zijn geen meldingen',
            },
            title: 'Meldingen',
        },
        points:
        {
            message:
            {
                points: 'Uw Punten',
                points_description: 'Bekijk hoeveel punten je hebt verdiend voor de huidige periode en hoeveel er beschikbaar zijn om in te wisselen',
            },
        },
        rewards:
        {
            action:
            {
                claim: 'Eis',
                claim_this_reward: 'Eis deze beloning op',
            },
            message:
            {
                index_description: 'Bekijk de beloningen en kies wat je wenst met je verdiende punten',
                n_points: '{points} Punten',
                n_point_rewards: '{points} beloningspunten',
            },
        },
        scenes:
        {
            action:
            {
                back_to_categories: 'Terug naar Categorieën',
                back_to_category: 'Terug naar Categorieën',
                search_scenes: 'Zoek Scenes',
            },
            field:
            {
                correct: 'Juist',
                other_scenes_in_category: 'Andere Scenes in deze categorie',
            },
            message:
            {
                all_questions_answered: 'Alle vragen werden beantwoord',
                complete: 'Volledig',
                continue: 'Ga door',
                exit_scene: 'Sluit Scene',
                failed: 'Mislukt',
                images_title: 'Klik op een afbeelding om de bijbehorende vraag te beantwoorden',
                no_incomplete_scenes: 'Niets gevonden',
                no_results: 'Geen overeenkomstige scenes gevonden. Probeer aub opnieuw',
                question_already_answered: 'Deze vraag werd reeds beantwoord',
                restart_scene: 'Herstart Scene',
                results_title: 'Resultaten',
                scene_title: 'Navigeer door de scene en klik op de gevaren om de veiligheidsvragen te beantwoorden',
                search_results: 'Zoekresultaten',
                search_results_description: 'Toon de 20 eerste overeenkomsten voor je zoekopdracht',
            },
        },
    },
};

export default messages;