import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import Activity from '../../../model/entities/rewards/activity';
import ActivityValidator from '../../../model/validator/rewards/activity-validator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class ActivityFormComponent extends Vue
{
    @Prop()
    private activity!: Activity;

    @Prop({ default: 'Save Activity' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formActivity: Activity|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.onActivityUpdated();
    }

    @Watch('activity')
    private onActivityUpdated()
    {
        this.formActivity = Object.assign({}, this.activity);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formActivity)
        {
            return false;
        }

        const validator: ActivityValidator = new ActivityValidator();
        this.validationErrors = validator.validate(this.formActivity);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formActivity)
        {
            throw new Error('Invalid activity properties');
        }

        const validator = new ActivityValidator();
        const validationErrors = validator.validate(this.formActivity);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid activity properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated activity.
            this.$emit('save', this.formActivity);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}