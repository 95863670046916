import { Component, Prop, Vue } from 'vue-property-decorator';
import Scene from '../../../model/entities/webscenes/scene';
import SceneQuestion from '../../../model/entities/webscenes/scene-question';
import UserSceneAnswer from '../../../model/entities/webscenes/user-scene-answer';

@Component({
    components:
    {},
})
export default class SceneSceneComponent extends Vue
{
    @Prop()
    private scene!: Scene;

    @Prop()
    private userAnswers!: UserSceneAnswer[];

    private viewportSize: {width: number; height: number} = {
        width: 0,
        height: 0,
    };

    private offsetX = 0;

    private isMovingLeft = false;
    private isMovingRight = false;

    private timer: number|null = null;

    private static SCROLL_BORDER = 100;
    private static SCROLL_INTERVAL = 25;
    private static MOVEMENT_PER_INTERVAL = 5;

    private canvases: {[questionId: string]: HTMLCanvasElement} = {};
    private canvasesReady: {[questionId: string]: boolean} = {};
    private hoveredQuestionId: number|null = null;

    $refs!:
    {
        container: HTMLElement
    };

    mounted()
    {
        this.onResize();
    }

    private onResize()
    {
        this.viewportSize =
        {
            width: this.$refs.container.clientWidth,
            height: this.$refs.container.clientHeight,
        };
    }

    get scrollerStyle(): any
    {
        if (!this.scene.sceneImage)
        {
            return {};
        }

        return {
            'width': `${ this.scene.sceneImage.width }px`,
            'height': `${ this.scene.sceneImage.height }px`,
            'margin-left': `${ this.offsetX }px`,
        };
    }

    get backgroundStyle(): any
    {
        if (!this.scene.sceneImage)
        {
            return {};
        }

        return {
            width: `${ this.scene.sceneImage.width }px`,
            height: `${ this.scene.sceneImage.height }px`,
            background: `url(${ this.sceneImageUri })`,
        };
    }

    private getHazardStyle(question: SceneQuestion): any
    {
        if (!question.sceneImage)
        {
            return {};
        }

        return {
            'top': `${ question.sceneImage.y }px`,
            'left': `${ question.sceneImage.x }px`,
            'width': `${ question.sceneImage.width }px`,
            'height': `${ question.sceneImage.height }px`,
            'background': `url(${ this.getHazardImageUri(question) }) no-repeat`,
            'z-index': question.sceneImage['z-index'],
        };
    }

    private getHazardGlowStyle(question: SceneQuestion): any
    {
        if (!question.sceneImageGlow)
        {
            return {};
        }

        return {
            'display': this.hoveredQuestionId === question.id || this.questionIsAnswered(question) ? 'block' : 'none',
            'top': `${ question.sceneImageGlow.y }px`,
            'left': `${ question.sceneImageGlow.x }px`,
            'width': `${ question.sceneImageGlow.width }px`,
            'height': `${ question.sceneImageGlow.height }px`,
            'background': `url(${ this.getHazardImageGlowUri(question) }) no-repeat`,
            'z-index': question.sceneImageGlow['z-index'],
        };
    }

    private getHazardImageUri(question: SceneQuestion): string|null
    {
        return this.$serviceContainer.getSceneService().getSceneQuestionSceneImageUri(question);
    }

    private getHazardImageGlowUri(question: SceneQuestion): string|null
    {
        return this.$serviceContainer.getSceneService().getSceneQuestionSceneGlowImageUri(question);
    }

    get sceneImageUri(): string|null
    {
        return this.$serviceContainer.getSceneService().getSceneSceneImageUri(this.scene);
    }

    /**
     * Returns whether the given question has been answered.
     *
     * @param question
     */

    private questionIsAnswered(question: SceneQuestion): boolean
    {
        for (const userAnswer of this.userAnswers)
        {
            if (userAnswer.questionId === question.id)
            {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns the image uri for a question.
     *
     * @param question
     */

    private getQuestionImageUri(question: SceneQuestion): string|null
    {
        return this.$serviceContainer.getSceneService().getSceneQuestionImageUri(question);
    }

    /**
     * Handles scroll left start events.
     */

    private onStartScrollLeft()
    {
        this.isMovingLeft = true;

        if (!this.timer)
        {
            this.timer = window.setInterval(() => this.scroll(), SceneSceneComponent.SCROLL_INTERVAL);
        }
    }

    /**
     * Handles scroll left end events.
     */

    private onEndScrollLeft()
    {
        this.isMovingLeft = false;

        if (!this.isMovingRight && this.timer)
        {
            window.clearInterval(this.timer);
            this.timer = null;
        }
    }

    /**
     * Handles scroll right start events.
     */

    private onStartScrollRight()
    {
        this.isMovingRight = true;

        if (!this.timer)
        {
            this.timer = window.setInterval(() => this.scroll(), SceneSceneComponent.SCROLL_INTERVAL);
        }
    }

    /**
     * Handles scroll right end events.
     */

    private onEndScrollRight()
    {
        this.isMovingRight = false;

        if (!this.isMovingLeft && this.timer)
        {
            window.clearInterval(this.timer);
            this.timer = null;
        }
    }

    /**
     * Handle scrolling.
     */

    private scroll()
    {
        if (this.isMovingLeft)
        {
            this.offsetX += SceneSceneComponent.MOVEMENT_PER_INTERVAL;
            if (this.offsetX >= 0)
            {
                this.offsetX = 0;
            }
        }
        else if (this.isMovingRight)
        {
            if (this.scene.sceneImage)
            {
                this.offsetX -= SceneSceneComponent.MOVEMENT_PER_INTERVAL;
                if (this.offsetX <= -(this.scene.sceneImage.width - this.viewportSize.width))
                {
                    this.offsetX = -(this.scene.sceneImage.width - this.viewportSize.width);
                }
            }
        }
        else if (this.timer)
        {
            window.clearInterval(this.timer);
            this.timer = null;
        }
    }

    /**
     * Handles hover events on the viewport.
     *
     * @param event
     */

    private onViewportHover(event: MouseEvent)
    {
        // If in the left side of the screen, scroll left.
        if (event.offsetX <= SceneSceneComponent.SCROLL_BORDER)
        {
            this.onEndScrollRight();
            this.onStartScrollLeft();
        }

        // If in the right side of the screen, scroll right.
        else if (event.offsetX >= (this.viewportSize.width - SceneSceneComponent.SCROLL_BORDER))
        {
            this.onEndScrollLeft();
            this.onStartScrollRight();
        }

        // Otherwise, don't scroll.
        else
        {
            this.onEndScrollLeft();
            this.onEndScrollRight();
        }

        // Check for hazard hover.
        const sceneX: number = event.offsetX + Math.abs(this.offsetX);
        const sceneY: number = event.offsetY;
        let isHoveredOverHazard = false;

        for (const question of this.scene.questions)
        {
            if (!question.sceneImage)
            {
                continue;
            }

            const questionMinX = question.sceneImage.x;
            const questionMaxX = question.sceneImage.x + question.sceneImage.width;

            const questionMinY = question.sceneImage.y;
            const questionMaxY = question.sceneImage.y + question.sceneImage.height;

            if (sceneX >= questionMinX && sceneX <= questionMaxX && sceneY >= questionMinY && sceneY <= questionMaxY)
            {
                const hazardX: number = sceneX - question.sceneImage.x;
                const hazardY: number = sceneY - question.sceneImage.y;

                const hovered = this.onHazardHover(
                    question, hazardX, hazardY,
                );
                if (hovered)
                {
                    isHoveredOverHazard = true;
                }
            }
            else
            {
                this.onEndHazardHover(question);
            }
        }

        // If no hazards are hovered, clear the hovered question id.
        if (!isHoveredOverHazard)
        {
            this.hoveredQuestionId = null;
        }
    }

    /**
     * Handles ending hovers on the viewport.
     */

    private onEndViewportHover()
    {
        this.hoveredQuestionId = null;

        this.onEndScrollLeft();
        this.onEndScrollRight();
    }

    /**
     * Handles hazard hovers.
     *
     * @param question
     * @param hazardX
     * @param hazardY
     */

    private onHazardHover(
        question: SceneQuestion, hazardX: number, hazardY: number,
    ): boolean
    {
        // Create a canvas element for the question.
        if (!(question.id in this.canvases) && question.sceneImage)
        {
            const canvas = document.createElement('canvas');
            canvas.width = question.sceneImage.width;
            canvas.height = question.sceneImage.height;

            const context = canvas.getContext('2d');
            if (context)
            {
                const image = new Image();
                image.onload = () =>
                {
                    context.drawImage(
                        image, 0, 0,
                    );
                    this.canvasesReady[question.id] = true;
                };

                image.crossOrigin = 'Anonymous';
                image.src = this.getHazardImageUri(question) ?? '';

                this.canvases[question.id] = canvas;
            }
        }

        // Check for a non-transparent pixel at the event location.
        if (question.id in this.canvasesReady && this.canvasesReady[question.id])
        {
            const context = this.canvases[question.id].getContext('2d');
            if (context)
            {
                const pixelData = context.getImageData(
                    hazardX, hazardY, 1, 1,
                );

                if (pixelData.data[3] > 0)
                {
                    this.hoveredQuestionId = question.id;

                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Handles ending hazard hovers.
     *
     * @param question
     */

    private onEndHazardHover(question: SceneQuestion)
    {
        if (question.id in this.canvases)
        {
            delete this.canvases[question.id];
        }

        if (question.id in this.canvasesReady)
        {
            this.canvasesReady[question.id] = false;
        }
    }

    /**
     * Handles viewport click events.
     */

    private onViewportClick()
    {
        if (!this.hoveredQuestionId)
        {
            return;
        }

        for (const question of this.scene.questions)
        {
            if (question.id === this.hoveredQuestionId)
            {
                this.onShowQuestion(question);
                break;
            }
        }
    }

    /**
     * Handles requests to show a question.
     *
     * @param question
     */

    private onShowQuestion(question: SceneQuestion)
    {
        this.$emit('show-question', question);
    }

    /**
     * Handle scroll events.
     *
     * @param event
     */

    private onScroll(event: WheelEvent)
    {
        event.preventDefault();

        if (!this.scene.sceneImage)
        {
            return;
        }

        this.offsetX -= event.deltaY;
        if (this.offsetX >= 0)
        {
            this.offsetX = 0;
        }
        else if (this.offsetX <= -(this.scene.sceneImage.width - this.viewportSize.width))
        {
            this.offsetX = -(this.scene.sceneImage.width - this.viewportSize.width);
        }
    }
}