import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import LeaderboardApiInterface from '../../../vos/organisation/leaderboard-api-interface';

export default class GetLeaderboardTask extends AbstractApiGetTask
{
    private _teamId!: number;

    public async run(): Promise<LeaderboardApiInterface[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return response.data.data;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            console.error(e);
            this.handleError(e.response);
        }

        return [];
    }

    set teamId(teamId: number)
    {
        this._teamId = teamId;
    }

    protected getUrl(): string
    {
        return `/teams/${ this._teamId }/leaderboard`;
    }
}