import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components:
    {},
})
export default class DividerComponent extends Vue
{
    @Prop({
        type: Boolean,
        default: false,
    })
    private dark!: boolean;
}