import { Component, Vue } from 'vue-property-decorator';
import AdminButtonComponent from '@/components/widgets/admin-button/index.vue';
import AdminNavigationDrawerComponent from '@/components/widgets/admin-navigation-drawer/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import FooterComponent from '@/components/widgets/footer/index.vue';
import HeaderComponent from '@/components/widgets/header/index.vue';
import NavbarComponent from '@/components/widgets/navbar/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import UserNotificationsComponent from '@/components/widgets/notifications/index.vue';
import UserService from '@/model/services/organisation/user-service';
import routes from '@/router/routes';

@Component({
    components:
    {
        'app-admin-button': AdminButtonComponent,
        'app-admin-navigation-drawer': AdminNavigationDrawerComponent,
        'app-footer': FooterComponent,
        'app-header': HeaderComponent,
        'app-navbar': NavbarComponent,
        'app-user-notifications': UserNotificationsComponent,
    },
})
export default class AppComponent extends Vue
{
    private refreshTokenTime = 60000; // 1 minute refresh
    private refreshTokenTimeout: number|null = null;

    $refs!:
    {
        confirmDialog: any
    };

    async created()
    {
        this.unregisterServiceWorkers();
        this.initEvents();
        this.refreshToken();
    }

    /**
     * Unregisters any existing service workers
     */

    private async unregisterServiceWorkers()
    {
        if ('serviceWorker' in navigator)
        {
            const registrations = await navigator.serviceWorker.getRegistrations();

            for (const registration of registrations)
            {
                registration.unregister();
            }
        }
    }

    /**
     * Return the public routes from the router.
     */

    get publicRoutes(): string[]
    {
        const publicRoutes: string[] = [];

        for (const route of routes)
        {
            if (!route.meta.requiresAuth)
            {
                publicRoutes.push(route.name);
            }
        }

        return publicRoutes;
    }

    /**
     * Returns whether the user is logged in.
     */

    get isLoggedIn(): boolean
    {
        return this.user !== null;
    }

    /**
     * Returns whether this is the home page.
     */

    get isHomePage(): boolean
    {
        return this.$route.name === 'home';
    }

    /**
     * Returns whether the current user is an admin.
     */

    get isAdmin(): boolean
    {
        return !!(this.user && UserService.userHasRole(this.user, [ 'ROLE_ADMIN' ]));
    }

    /**
     * Initialises events.
     */

    private initEvents()
    {
        EventBus.instance.$on('not-authenticated', () => this.handleNotAuthenticatedEvent());
        EventBus.instance.$on('logged-in', () => this.onLoggedIn());
        EventBus.instance.$on('logged-out', () => this.onLoggedOut());
        EventBus.instance.$on('delete-account', () => this.deleteAccount());
        EventBus.instance.$on('confirm-dialog', (
            title: string, message: string, onConfirm: () => void, warning?: boolean,
        ) => this.handleConfirmDialogEvent(
            title, message, onConfirm, warning,
        ));
    }

    /**
     * Handles logged-in events.
     */

    private async onLoggedIn()
    {
        await this.refreshToken();
        await this.loadEmployee();

        NavigationHelper.pushRoute({ name: 'home' });
    }

    /**
     * Handles logged-out events.
     */

    private onLoggedOut()
    {
        this.$store.commit('app/employee', null);
    }

    /**
     * Refreshes the user's api token.
     */

    private refreshToken()
    {
        // If (!this.user || this.publicRoutes.indexOf(this.$route.name ?? '') > -1)
        // {
        //     return;
        // }

        if (!this.user)
        {
            return;
        }

        // Clear any existing timeout.
        if (this.refreshTokenTimeout)
        {
            window.clearTimeout(this.refreshTokenTimeout);
        }

        // Refresh the token.
        this.$serviceContainer.getAuthenticationService().refreshToken();

        // Set a timeout to refresh the token again.
        this.refreshTokenTimeout = window.setTimeout(() => this.refreshToken(), this.refreshTokenTime);
    }

    /**
     * Loads the user's employee details.
     */

    private async loadEmployee()
    {
        if (!this.user?.id)
        {
            return;
        }

        try
        {
            // Load the employee for the current user.
            const employee = await this.$serviceContainer.getEmployeeService().loadEmployee(this.user.id);

            // Save the employee details to the session.
            this.$store.commit('app/employee', employee);
        }
        catch (error)
        {
            //
        }
    }

    /**
     * Handles Not Authenticated events.
     */

    private handleNotAuthenticatedEvent()
    {
        // Log the user out.
        this.$serviceContainer.getAuthenticationService().logout();
        this.$store.commit('app/employee', null);

        // If this is a public route, no need to do anything else.
        if (this.publicRoutes.indexOf(this.$route.name ?? '') > -1)
        {
            return;
        }

        // Emit an event to close all dialogs.
        EventBus.instance.$emit('close-dialogs');

        // Redirect to the login page.
        // NavigationHelper.pushRoute({name: 'login', query: {redirect_uri: window.location.href}});
        NavigationHelper.pushRoute({ name: 'home' });
    }

    /**
     * Handles account deletions.
     */

    private async deleteAccount()
    {
        const confirmed = await this.$refs.confirmDialog.open(
            this.$t('authentication.message.delete_account_question').toString(), this.$t('authentication.message.delete_account_confirmation').toString(), true,
        );

        if (confirmed)
        {
            return this.doDeleteAccount();
        }
    }

    /**
     * Actually deletes the user's account.
     */

    private async doDeleteAccount()
    {
        // Delete the account.
        await this.$serviceContainer.getUserService().deleteAccount();

        // Logout.
        this.$serviceContainer.getAuthenticationService().logout();
        this.$store.commit('app/employee', null);

        // Redirect to the login page.
        NavigationHelper.pushRoute({ name: 'login' });
    }

    /**
     * Handles Confirm events
     */

    private async handleConfirmDialogEvent(
        title: string, message: string, onConfirm: () => void, warning = false,
    )
    {
        const isConfirmed = await this.$refs.confirmDialog.open(
            title, message, warning,
        );
        if (isConfirmed)
        {
            onConfirm();
        }
    }

    /**
     * Handles login requests.
     */

    private onLogin()
    {
        NavigationHelper.pushRoute({ name: 'login' });
    }

    /**
     * Handles logout requests.
     */

    private onLogout()
    {
        NavigationHelper.pushRoute({ name: 'logout' });
    }
}