import Location from './location';

export default class FirstAider
{
    id = 0;
    firstName = '';
    lastName = '';
    locationId: number|null = null;
    location: Location|null = null;
    role: string|null = null;
    email: string|null = null;
    phone: string|null = null;
    type: string|null = null;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
}