import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';

export default class PostEmployeeActivityTask extends AbstractApiPostTask
{
    private _activityId!: number;
    private _userId!: number;
    private _notes!: string;
    private _pointsAwarded!: number;
    private _pointsRedeemable!: number;

    public async run(): Promise<void>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    set activityId(activityId: number)
    {
        this._activityId = activityId;
    }

    set userId(userId: number)
    {
        this._userId = userId;
    }

    set notes(notes: string)
    {
        this._notes = notes;
    }

    set pointsAwarded(pointsAwarded: number)
    {
        this._pointsAwarded = pointsAwarded;
    }

    set pointsRedeemable(pointsRedeemable: number)
    {
        this._pointsRedeemable = pointsRedeemable;
    }

    protected getUrl(): string
    {
        return `/activities/${ this._activityId }/assign`;
    }

    protected getData(): any
    {
        return {
            user_id: this._userId,
            notes: this._notes,
            points_awarded: this._pointsAwarded,
            points_redeemable: this._pointsRedeemable,
        };
    }
}