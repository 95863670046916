import { Component, Prop, Vue } from 'vue-property-decorator';
import Scene from '../../../model/entities/webscenes/scene';
import SceneQuestion from '../../../model/entities/webscenes/scene-question';
import UserSceneAnswer from '../../../model/entities/webscenes/user-scene-answer';

@Component({
    components:
    {},
})
export default class SceneImagesComponent extends Vue
{
    @Prop()
    private scene!: Scene;

    @Prop()
    private userAnswers!: UserSceneAnswer[];

    @Prop()
    private incorrectQuestions!: number[];

    @Prop()
    private reansweredQuestions!: number[];

    /**
     * Returns the rows for the questions.
     */

    get rows(): SceneQuestion[][]
    {
        // Generate the rows.
        const rows: SceneQuestion[][] = [];

        // Calculate how many columns should appear.
        let columns: number;
        switch (this.$vuetify.breakpoint.name)
        {
        case 'xl':
        case 'lg':
            columns = 4;
            break;

        case 'md':
            columns = 3;
            break;

        case 'sm':
        case 'xs':
        default:
            columns = 2;
            break;
        }

        let rowItems: SceneQuestion[] = [];
        for (let index = 0; index < this.scene.questions.length; index++)
        {
            const question = this.scene.questions[index];

            rowItems.push(question);

            // New row?
            if ((index + 1) % columns === 0)
            {
                rows.push(rowItems);
                rowItems = [];
            }
        }

        rows.push(rowItems);

        return rows;
    }

    /**
     * Returns the classes for an image.
     *
     * @param question
     */

    private getImageClasses(question: SceneQuestion)
    {
        return {
            'scene-images__image--answered': this.questionIsAnswered(question),
            'scene-images__image--incorrect': this.questionIsIncorrect(question),
            'scene-images__image--reanswered': this.questionIsReanswered(question),
        };
    }

    /**
     * Returns whether the given question has been answered.
     *
     * @param question
     */

    private questionIsAnswered(question: SceneQuestion): boolean
    {
        for (const userAnswer of this.userAnswers)
        {
            if (userAnswer.questionId === question.id)
            {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns whether the given question has been answered incorrectly.
     *
     * @param question
     */

    private questionIsIncorrect(question: SceneQuestion): boolean
    {
        if (!this.incorrectQuestions)
        {
            return false;
        }

        return this.incorrectQuestions.indexOf(question.id) > -1;
    }

    /**
     * Returns whether the given question has been reanswered.
     *
     * @param question
     */

    private questionIsReanswered(question: SceneQuestion): boolean
    {
        if (!this.reansweredQuestions)
        {
            return false;
        }

        return this.reansweredQuestions.indexOf(question.id) > -1;
    }

    /**
     * Returns the image uri for a question.
     *
     * @param question
     */

    private getQuestionImageUri(question: SceneQuestion): string|null
    {
        return this.$serviceContainer.getSceneService().getSceneQuestionImageUri(question);
    }

    /**
     * Handles requests to show a question.
     *
     * @param question
     */

    private onShowQuestion(question: SceneQuestion)
    {
        this.$emit('show-question', question);
    }
}