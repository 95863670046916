import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Reward from '../../../../../model/entities/rewards/reward';
import RewardFormComponent from '@/components/widgets/reward-form/index.vue';

@Component({
    components:
    {
        'app-reward-form': RewardFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminRewardEditPageComponent extends Vue
{
    private reward: Reward|null = null;
    private isLoading = true;

    created()
    {
        this.loadReward();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.rewards.message.index_title'),
                disabled: false,
                to: { name: 'admin-reward-index' },
                exact: true,
            },
            {
                text: this.$t('admin.rewards.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the reward for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.reward ? this.$et(this.reward.name) : this.$t('admin.rewards.edit_title').toString();
    }

    /**
     * Loads the reward.
     */

    private async loadReward()
    {
        this.isLoading = true;
        const reward = await this.$serviceContainer.getRewardService().loadReward(parseInt(this.$route.params.id));
        this.reward = Object.assign({}, reward);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param reward
     */

    private async onSave(reward: Reward)
    {
        try
        {
            // Update the reward.
            const updated = await this.$serviceContainer.getRewardService().updateReward(reward);

            // Upload image.
            await this.$serviceContainer.getRewardService().uploadRewardImage(updated, reward);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.rewards.message.reward_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}