import { Component, Prop, Vue } from 'vue-property-decorator';
import TranslationsComponent from '@/components/widgets/translations/index.vue';
import VueI18n from 'vue-i18n';

@Component({
    components:
    {
        'app-translations': TranslationsComponent,
    },
})
export default class TranslationGroupComponent extends Vue
{
    @Prop()
    private value!: VueI18n.LocaleMessages;

    @Prop()
    private parentKey!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private parentActive!: boolean;

    private active: number|null = null;

    created()
    {
        if ('key_path' in this.$route.query)
        {
            const activeKeyPath = (this.$route.query.key_path as string);

            for (let index = 0; index < this.translationKeys.length; index++)
            {
                const key = this.translationKeys[index];
                const keyPath = this.getKeyPath(key);

                if (activeKeyPath.startsWith(keyPath))
                {
                    this.active = index;
                    break;
                }
            }
        }
    }

    get translationKeys(): string[]
    {
        const keys = Object.keys(this.value);

        keys.sort();

        return keys;
    }

    /**
     * Returns the full key path for a key
     *
     * @param key
     */

    private getKeyPath(key: string): string
    {
        return this.parentKey ? `${ this.parentKey }.${ key }` : key;
    }

    /**
     * Returns whether the current item is a translation group
     *
     * @param item
     */

    private isTranslationGroup(item: any): boolean
    {
        for (const key in item)
        {
            const subItem = item[key];
            if (typeof subItem === 'object' && !('en' in subItem))
            {
                return true;
            }
        }

        return false;
    }
}