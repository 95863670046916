import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Scene from '../../../entities/webscenes/scene';
import SceneApiInterface from '../../../entities/webscenes/scene-api-interface';
import SceneTransformer from '../../../transformers/webscenes/scene-transformer';

export default class GetActiveScenesTask extends AbstractApiGetTask
{
    private _categoryId!: number;

    public async run(): Promise<Scene[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiScenes: SceneApiInterface[] = response.data.data;

                const scenes: Scene[] = [];
                for (const apiScene of apiScenes)
                {
                    try
                    {
                        // Parse the scene.
                        scenes.push(SceneTransformer.fromApi(apiScene));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Scene');
                        console.error(error);
                    }
                }

                return scenes;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    set categoryId(categoryId: number)
    {
        this._categoryId = categoryId;
    }

    protected getUrl(): string
    {
        return `/scenes/active?category_id=${ this._categoryId }`;
    }
}