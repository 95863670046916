import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Newsletter from '../../../../../model/entities/media/newsletter';
import NewsletterResultsApiInterface from '../../../../../model/vos/media/newsletter-results-api-interface';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminNewsletterIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.newsletters.message.index_title'),
                disabled: true,
                to: { name: 'admin-newsletter-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.id'),
                align: 'left',
                value: 'id',
            },
            {
                text: this.$t('general.field.title'),
                align: 'left',
                value: 'title',
            },
            {
                text: this.$t('admin.newsletters.field.release_date'),
                align: 'center',
                value: 'releaseDate',
            },
            {
                text: this.$t('general.field.enabled'),
                align: 'center',
                value: 'enabled',
                sortable: false,
            },
            {
                text: this.$t('general.field.options'),
                align: 'center',
                value: '',
                sortable: false,
            },
        ];
    }

    private results: NewsletterResultsApiInterface =
        {
            results: [],
            total_results: 0,
            results_per_page: 50,
            total_pages: 0,
            page: 1,
            sort_by: 'releaseDate',
            descending: false,
        };

    private isLoading = true;

    private conditions: SearchConditionsInterface = {};

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        this.conditions = Object.assign({}, conditions);
        const results = await this.$serviceContainer.getNewsletterService().searchNewsletters(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-newsletter-create' });
    }

    /**
     * Handles opening of newsletters.
     *
     * @param newsletter
     */

    private onOpenNewsletter(newsletter: Newsletter)
    {
        NavigationHelper.pushRoute({
            name: 'admin-newsletter-edit',
            params: { id: newsletter.id.toString() },
        });
    }

    /**
     * Handles deleting of newsletters.
     *
     * @param newsletter
     */

    private onDeleteNewsletter(newsletter: Newsletter)
    {
        EventBus.instance.$emit(
            'confirm-dialog', this.$t('admin.newsletters.message.delete_title'), this.$t('admin.newsletters.message.delete_description'), () => this.deleteNewsletter(newsletter), true,
        );
    }

    /**
     * Deletes a newsletter
     *
     * @param newsletter
     */

    private async deleteNewsletter(newsletter: Newsletter)
    {
        try
        {
            await this.$serviceContainer.getNewsletterService().deleteNewsletter(newsletter.id);
            this.loadResults(this.conditions);

            EventBus.instance.$emit(
                'notification', this.$t('admin.newsletters.message.newsletter_deleted'), 'success',
            );
        }
        catch (error)
        {
            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_deleting_server_error'), 'error',
            );
        }
    }
}