import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Scene from '../../../../../model/entities/webscenes/scene';
import SceneFormComponent from '@/components/widgets/scene-form/index.vue';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
        'app-scene-form': SceneFormComponent,
    },
})
export default class AdminSceneEditPageComponent extends Vue
{
    private scene: Scene|null = null;
    private isLoading = true;
    private isSaving = false;

    created()
    {
        this.loadScene();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.scenes.message.index_title'),
                disabled: false,
                to: { name: 'admin-scene-index' },
                exact: true,
            },
            {
                text: this.$t('admin.scenes.message.edit_title'),
                disabled: true,
                to: { name: 'admin-scene-edit',
                    params: this.$route.params },
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the scene for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.scene ? this.$et(this.scene.name) : this.$t('admin.scenes.message.edit_title').toString();
    }

    /**
     * Loads the scene.
     */

    private async loadScene()
    {
        this.isLoading = true;
        const scene = await this.$serviceContainer.getSceneService().loadScene(parseInt(this.$route.params.sceneId));
        this.scene = Object.assign({}, scene);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param scene
     */

    private async onSave(scene: Scene)
    {
        try
        {
            // Mark as saving.
            this.isSaving = true;

            // Update the scene.
            const updated = await this.$serviceContainer.getSceneService().updateScene(scene);

            // Upload files.
            await this.$serviceContainer.getSceneService().uploadFiles(updated, scene);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.scenes.message.scene_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
        finally
        {
            // Mark as no longer saving.
            this.isSaving = false;
        }
    }
}