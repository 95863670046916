import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Role from '@/model/entities/organisation/role';
import User from '@/model/entities/organisation/user';
import UserValidator from '@/model/validator/organisation/user-validator';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({})
export default class UserFormComponent extends Vue
{
    @Prop()
    private selectedUser!: User;

    @Prop({ default: 'Save User' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formUser: User|null = null;
    private roles: Role[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formUser = Object.assign(new User(), this.selectedUser);

        this.loadRoles();
    }

    @Watch('selectedUser')
    private onUserUpdated()
    {
        this.formUser = Object.assign(new User(), this.selectedUser);
    }

    /**
     * Loads the job categories.
     */

    private async loadRoles()
    {
        this.roles = await this.$serviceContainer.getRoleService().getRoles();
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formUser)
        {
            return false;
        }

        const validator: UserValidator = new UserValidator();
        this.validationErrors = validator.validate(this.formUser);

        return size(this.validationErrors) === 0;
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formUser)
        {
            throw new Error('Invalid job properties');
        }

        const validator = new UserValidator();
        const validationErrors = validator.validate(this.formUser);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid job properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated category.
            this.$emit('save', this.formUser);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}