import { Component, Prop, Vue } from 'vue-property-decorator';
import Banner from '../../../model/entities/media/banner';
import DividerComponent from '@/components/widgets/divider/index.vue';

@Component({
    components:
    {
        'app-divider': DividerComponent,
    },
})
export default class BannerComponent extends Vue
{
    @Prop()
    private banner!: Banner;
}