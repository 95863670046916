import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Role from '@/model/entities/organisation/role';
import User from '@/model/entities/organisation/user';
import UserFiltersComponent from '@/components/widgets/user-filters/index.vue';
import UserResultsInterface from '@/model/vos/organisation/user-results-interface';
import UserSearchConditionsInterface from '@/model/vos/organisation/user-search-conditions-interface';
import UserSearchConditionsService from '@/model/services/organisation/user-search-conditions-service';

@Component({
    components:
    {
        'vp-user-filters': UserFiltersComponent,
    },
})
export default class AdminUserIndexView extends Vue
{
    @Prop()
    private headers?: any[];

    private displayHeaders: any[] = [];

    private results: UserResultsInterface =
        {
            results: [],
            total_results: 0,
            results_per_page: 50,
            total_pages: 0,
            page: 1,
            sort_by: 'first_name',
            descending: false,
        };

    private roles: Role[] = [];
    private roleNames: {[id: string]: string} = {};

    private conditions: UserSearchConditionsInterface = {};
    private filters: UserSearchConditionsInterface = {};
    private searchConditionsService: UserSearchConditionsService = new UserSearchConditionsService();

    private isInitialising = true;
    private isLoading = true;
    private isFiltersVisible = false;

    async created()
    {
        this.isInitialising = true;
        this.searchConditionsService = new UserSearchConditionsService();
        this.filters = this.searchConditionsService.getConditionsFromQueryParams(
            this.$route, 50, 'first_name',
        );
        this.displayHeaders = this.headers ? this.headers : this.defaultHeaders;
        await this.loadRoles();
        this.isInitialising = false;
    }

    @Watch('headers')
    private onHeadersChange()
    {
        this.displayHeaders = this.headers ? this.headers : this.defaultHeaders;
    }

    /**
     * Returns the breadcrumb.
     */

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.users.message.index_title'),
                disabled: true,
                to: { name: 'admin-user-index' },
                exact: true,
            },
        ];
    }

    /**
     * Returns default headers.
     */

    get defaultHeaders(): any[]
    {
        return [
            { text: this.$t('general.field.id'),
                align: 'left',
                value: 'id' },
            { text: this.$t('general.field.name'),
                align: 'left',
                value: 'first_name' },
            { text: this.$t('general.field.email'),
                align: 'left',
                value: 'email' },
            { text: this.$t('general.field.role'),
                align: 'left',
                value: 'role',
                sortable: false },
            { text: this.$t('admin.users.field.registered'),
                align: 'left',
                value: 'registration_date' },
            { text: this.$t('general.field.active'),
                align: 'left',
                value: 'disabled',
                sortable: false },
        ];
    }

    /**
     * Returns filtered roles for a user.
     *
     * @param user
     */

    public getUserRoles(user: User): string[]
    {
        const roles: string[] = [];
        for (const role of user.roles)
        {
            // Remove user role if another role exists.
            if (role === 'ROLE_USER' && user.roles.length > 1)
            {
                continue;
            }

            roles.push(role);
        }

        return roles;
    }

    /**
     * Returns the name for a role.
     *
     * @param roleId
     */

    public getRoleName(roleId: string): string
    {
        return this.roleNames[roleId];
    }

    /**
     * Loads the roles.
     */

    private async loadRoles()
    {
        this.roles = await this.$serviceContainer.getRoleService().getRoles();

        this.roleNames = {};
        for (const role of this.roles)
        {
            if (!role.id || !role.name)
            {
                continue;
            }

            this.roleNames[role.id] = role.name;
        }
    }

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: UserSearchConditionsInterface)
    {
        this.isLoading = true;

        // Store the conditions.
        this.conditions = conditions;

        // Run the search.
        const results = await this.$serviceContainer.getUserService().searchUsers(this.conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles opening of user.
     *
     * @param user
     */

    private onOpenUser(user: User)
    {
        if (!user.id)
        {
            return;
        }

        NavigationHelper.pushRoute({
            name: 'admin-user-edit',
            params: { id: user.id.toString() },
        });
    }

    /**
     * Handles filter updates.
     *
     * @param filters
     */

    private onFiltersUpdate(filters: UserSearchConditionsInterface)
    {
        this.filters = Object.assign(this.filters, filters);
        this.conditions = Object.assign(this.conditions, this.filters);

        const query = this.searchConditionsService.getQueryParamsFromConditions(
            this.conditions, 50, 'first_name',
        );

        NavigationHelper.replaceRoute({ query });
    }
}