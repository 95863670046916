import { Component, Vue } from 'vue-property-decorator';
import Activity from '../../../../../model/entities/rewards/activity';
import Employee from '../../../../../model/entities/organisation/employee';
import EmployeeFormComponent from '@/components/widgets/employee-form/index.vue';
import EmployeePointsFormComponent from '@/components/widgets/employee-points-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Reward from '../../../../../model/entities/rewards/reward';

@Component({
    components:
    {
        'app-employee-form': EmployeeFormComponent,
        'app-employee-points-form': EmployeePointsFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminEmployeeEditPageComponent extends Vue
{
    private employee: Employee|null = null;
    private isLoading = true;

    private active = 0;

    $refs!:
    {
        confirmDialog: any
    };

    async created()
    {
        this.isLoading = true;

        this.loadEmployee();

        this.isLoading = false;
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.employees.message.index_title'),
                disabled: false,
                to: { name: 'admin-employee-index' },
                exact: true,
            },
            {
                text: this.$t('admin.employees.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the employee for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.employee ? this.$et(this.employee.user.fullName) : this.$t('admin.employees.edit_title').toString();
    }

    /**
     * Loads the employee.
     */

    private async loadEmployee()
    {
        const employee = await this.$serviceContainer.getEmployeeService().loadEmployee(parseInt(this.$route.params.id));
        this.employee = Object.assign({}, employee);
    }

    /**
     * Handles save events from the form.
     *
     * @param employee
     */

    private async onSave(employee: Employee)
    {
        try
        {
            // Update the employee.
            await this.$serviceContainer.getEmployeeService().updateEmployee(employee);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.employees.message.employee_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Handles delete requests
     */

    private async onDelete()
    {
        const confirmed = await this.$refs.confirmDialog.open(this.$t('admin.employees.message.delete_employee_question').toString(), this.$t('admin.employees.message.delete_employee_confirmation').toString());

        if (confirmed)
        {
            return this.deleteEmployee();
        }
    }

    /**
     * Actually deletes an employee
     */

    private async deleteEmployee()
    {
        if (!this.employee)
        {
            return;
        }

        try
        {
            // Delete the employee.
            await this.$serviceContainer.getEmployeeService().deleteEmployee(this.employee.userId);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.employees.message.employee_deleted'));

            // Redirect to the index.
            NavigationHelper.replaceRoute({ name: 'admin-employee-index' });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Assigns an activity to the employee.
     *
     * @param activity
     * @param notes
     * @param pointsAwarded
     * @param pointsRedeemable
     */

    private async onAddActivity(
        activity: Activity, notes: string, pointsAwarded: number, pointsRedeemable: number,
    )
    {
        if (!this.employee)
        {
            return;
        }

        try
        {
            // Assign the activity.
            await this.$serviceContainer.getEmployeeService().assignActivity(
                activity.id,
                this.employee.userId,
                notes,
                pointsAwarded,
                pointsRedeemable,
            );

            // Notify the user of success.
            EventBus.instance.$emit('notification', this.$t('admin.employees.message.activity_assigned'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Assigns a reward to the employee.
     *
     * @param reward
     * @param points
     */

    private async onAddReward(reward: Reward, points: number)
    {
        if (!this.employee)
        {
            return;
        }

        try
        {
            // Assign the reward.
            await this.$serviceContainer.getEmployeeService().assignReward(
                reward.id,
                this.employee.userId,
                points,
            );

            // Notify the user of success.
            EventBus.instance.$emit('notification', this.$t('admin.employees.message.reward_assigned'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}