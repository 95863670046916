import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Banner from '../../../entities/media/banner';
import BannerApiInterface from '../../../entities/media/banner-api-interface';
import BannerTransformer from '../../../transformers/media/banner-transformer';

export default class GetBannerTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<Banner|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiBanner: BannerApiInterface = response.data.data;

                return BannerTransformer.fromApi(apiBanner);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/banners/${ this._id }`;
    }
}