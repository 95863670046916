import { AxiosResponse } from 'axios';
import AbstractApiPostTask from '../abstract-api-post-task';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';

export default class RefreshTokenTask extends AbstractApiPostTask
{
    public async run()
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    protected getUrl(): string
    {
        return '/refresh-token';
    }

    protected handleError(response: AxiosResponse)
    {
        if (!response || !response.data || !response.data.error || !response.data.error.code)
        {
            EventBus.instance.$emit('not-authenticated');
        }
    }
}