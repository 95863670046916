import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';

export default class PostFeedbackTask extends AbstractApiPostTask
{
    private _feedback!: string;

    public async run(): Promise<void>
    {
        try
        {
            const response = await this.request();

            if (!this.isSuccessful(response))
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    set feedback(feedback: string)
    {
        this._feedback = feedback;
    }

    protected getUrl(): string
    {
        return '/feedback';
    }

    protected getData(): any
    {
        return {
            feedback: this._feedback,
        };
    }
}