import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Team from '../../../entities/organisation/team';
import TeamApiInterface from '../../../entities/organisation/team-api-interface';
import TeamTransformer from '../../../transformers/organisation/team-transformer';

export default class PostTeamTask extends AbstractApiPostTask
{
    private _team!: Team;

    public async run(): Promise<Team>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiTeam: TeamApiInterface = response.data.data;

                return TeamTransformer.fromApi(apiTeam, apiTeam.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._team;
    }

    set team(team: Team)
    {
        this._team = team;
    }

    protected getUrl(): string
    {
        return '/teams';
    }

    protected getData(): TeamApiInterface
    {
        return TeamTransformer.toApi(this._team);
    }
}