import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Post from '../../../entities/blog/post';
import PostApiInterface from '../../../entities/blog/post-api-interface';
import PostTransformer from '../../../transformers/blog/post-transformer';

export default class GetActivePostsTask extends AbstractApiGetTask
{
    public async run(): Promise<Post[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiPosts: PostApiInterface[] = response.data.data;

                const posts: Post[] = [];
                for (const apiPost of apiPosts)
                {
                    try
                    {
                        // Parse the post.
                        posts.push(PostTransformer.fromApi(apiPost));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Post');
                        console.error(error);
                    }
                }

                return posts;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/posts/active';
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}