import Location from './location';
import Team from './team';
import User from '@/model/entities/organisation/user';

export default class Employee
{
    userId = 0;
    user: User = new User();
    locationId = 0;
    location: Location = new Location();
    teamId = 0;
    team: Team = new Team();
    jobLevel = '';
    employeeNumber = '';

    firstName = '';
    lastName = '';
    email = '';
}