import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Employee from '../../../model/entities/organisation/employee';
import EmployeeService from '../../../model/services/organisation/employee-service';
import EmployeeValidator from '../../../model/validator/organisation/employee-validator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Location from '../../../model/entities/organisation/location';
import Team from '../../../model/entities/organisation/team';
import User from '@/model/entities/organisation/user';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class EmployeeFormComponent extends Vue
{
    @Prop()
    private employee!: Employee;

    @Prop({ default: 'Save Employee' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private deletable!: boolean;

    private formEmployee: Employee|null = null;
    private locations: Location[] = [];
    private teams: Team[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formEmployee = Object.assign(new Employee(), this.employee);

        this.loadLocations();
        this.loadTeams();
    }

    @Watch('employee')
    private onEmployeeUpdated()
    {
        this.formEmployee = Object.assign(new Employee(), this.employee);
    }

    @Watch('formEmployee.user')
    private onUserUpdated()
    {
        if (!this.formEmployee)
        {
            return;
        }

        this.formEmployee.userId = this.formEmployee.user.id ? this.formEmployee.user.id : 0;
    }

    @Watch('formEmployee.location')
    private onLocationUpdated()
    {
        if (!this.formEmployee)
        {
            return;
        }

        this.formEmployee.locationId = this.formEmployee.location ? this.formEmployee.location.id : 0;
    }

    @Watch('formEmployee.team')
    private onTeamUpdated()
    {
        if (!this.formEmployee)
        {
            return;
        }

        this.formEmployee.teamId = this.formEmployee.team ? this.formEmployee.team.id : 0;
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formEmployee)
        {
            return false;
        }

        const validator: EmployeeValidator = new EmployeeValidator();
        this.validationErrors = validator.validate(this.formEmployee);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the job levels.
     */

    get jobLevels(): {id: string, name: string}[]
    {
        return EmployeeService.getJobLevels();
    }

    /**
     * Loads the available locations.
     */

    private async loadLocations()
    {
        const results = await this.$serviceContainer.getLocationService().searchLocations({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.locations = results.results;
        }
    }

    /**
     * Loads the available teams.
     */

    private async loadTeams()
    {
        const results = await this.$serviceContainer.getTeamService().searchTeams({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.teams = results.results;
        }
    }

    /**
     * Handles user updates.
     *
     * @param user
     */

    private onUserChange(user: User)
    {
        if (!this.formEmployee)
        {
            return;
        }

        this.formEmployee.user = user ? Object.assign({}, user) : Object.assign({}, new User());
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formEmployee)
        {
            throw new Error('Invalid employee properties');
        }

        const validator = new EmployeeValidator();
        const validationErrors = validator.validate(this.formEmployee);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid employee properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated employee.
            this.$emit('save', this.formEmployee);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }

    /**
     * Handles deleting
     */

    private onDelete()
    {
        this.$emit('delete');
    }
}