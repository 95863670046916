import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Video from '../../../entities/media/video';
import VideoApiInterface from '../../../entities/media/video-api-interface';
import VideoTransformer from '../../../transformers/media/video-transformer';

export default class GetVideoTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<Video|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiVideo: VideoApiInterface = response.data.data;

                return VideoTransformer.fromApi(apiVideo);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/videos/${ this._id }`;
    }
}