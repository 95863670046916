import { Route } from 'vue-router';
import SearchConditionsService from '@/model/services/search/search-conditions-service';
import TeamSearchConditionsInterface from '../../vos/organisation/team-search-conditions-interface';

export default class TeamSearchConditionsService extends SearchConditionsService
{
    public getConditionsFromQueryParams(
        route: Route, defaultLimit: number, defaultSortBy: string,
    ): TeamSearchConditionsInterface
    {
        const conditions: TeamSearchConditionsInterface = super.getConditionsFromQueryParams(
            route, defaultLimit, defaultSortBy,
        );

        return conditions;
    }

    public getQueryParamsFromConditions(
        conditions: TeamSearchConditionsInterface, defaultLimit: number, defaultSortBy: string,
    )
    {
        const params = super.getQueryParamsFromConditions(
            conditions, defaultLimit, defaultSortBy,
        );

        return params;
    }
}