import Scene from './scene';
import SceneQuestionAnswer from './scene-question-answer';
import SceneQuestionSceneImageInterface from './scene-question-scene-image-interface';
import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class SceneQuestion
{
    id = 0;
    sceneId = 0;
    scene: Scene|null = null;
    text: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: string|null = null;
    imageFile: File|null = null;
    sceneImage: SceneQuestionSceneImageInterface|null = null;
    sceneImageGlow: SceneQuestionSceneImageInterface|null = null;
    displayOrder = 1;
    isEnabled = false;
    answers: SceneQuestionAnswer[] = [];
}