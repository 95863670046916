import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Scene from '../../../entities/webscenes/scene';
import SceneApiInterface from '../../../entities/webscenes/scene-api-interface';
import SceneTransformer from '../../../transformers/webscenes/scene-transformer';

export default class PostSceneTask extends AbstractApiPostTask
{
    private _scene!: Scene;

    public async run(): Promise<Scene>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiScene: SceneApiInterface = response.data.data;

                return SceneTransformer.fromApi(apiScene, apiScene.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._scene;
    }

    set scene(scene: Scene)
    {
        this._scene = scene;
    }

    protected getUrl(): string
    {
        return '/scenes';
    }

    protected getData(): SceneApiInterface
    {
        return SceneTransformer.toApi(this._scene);
    }
}