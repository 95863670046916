import { Component, Vue } from 'vue-property-decorator';
import Activity from '../../../../../model/entities/rewards/activity';
import ActivityFormComponent from '@/components/widgets/activity-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-activity-form': ActivityFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminActivityEditPageComponent extends Vue
{
    private activity: Activity|null = null;
    private isLoading = true;

    created()
    {
        this.loadActivity();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.activities.message.index_title'),
                disabled: false,
                to: { name: 'admin-activity-index' },
                exact: true,
            },
            {
                text: this.$t('admin.activities.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the activity for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.activity ? this.$et(this.activity.name) : this.$t('admin.activities.edit_title').toString();
    }

    /**
     * Loads the activity.
     */

    private async loadActivity()
    {
        this.isLoading = true;
        const activity = await this.$serviceContainer.getActivityService().loadActivity(parseInt(this.$route.params.id));
        this.activity = Object.assign({}, activity);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param activity
     */

    private async onSave(activity: Activity)
    {
        try
        {
            // Update the activity.
            await this.$serviceContainer.getActivityService().updateActivity(activity);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.activities.message.activity_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}