import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';

@Component({
    components:
    {},
})
export default class AdminButtonComponent extends Vue
{
    /**
     * Handles click events.
     */

    private onClick()
    {
        EventBus.instance.$emit('toggle-admin-nav');
    }
}