import { Component, Vue } from 'vue-property-decorator';
import { getYear } from 'date-fns';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import NavComponent from '@/components/widgets/nav-links/index.vue';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-nav': NavComponent,
        'app-translation': TranslationComponent,
    },
})
export default class FooterComponent extends Vue
{
    get mainLogoUri(): string
    {
        if (LocaleHelper.getLocale() === 'nl-BE')
        {
            return require('../../../assets/images/logos/stronger-together-logo-white_nl-BE.png');
        }
        else
        {
            return require('../../../assets/images/logos/stronger-together-logo-white.png');
        }
    }

    get norbordLogoUri(): string
    {
        return require('../../../assets/images/logos/norbord-logo-white.png');
    }

    get year()
    {
        return getYear(new Date());
    }
}