import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Reward from '../../../entities/rewards/reward';
import RewardApiInterface from '../../../entities/rewards/reward-api-interface';
import RewardResultsApiInterface from '../../../vos/rewards/reward-results-api-interface';
import RewardTransformer from '../../../transformers/rewards/reward-transformer';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchConditionsService from '@/model/services/search/search-conditions-service';

export default class SearchRewardsTask extends AbstractApiGetTask
{
    private _conditions!: SearchConditionsInterface;

    public async run(): Promise<RewardResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiRewards: RewardApiInterface[] = response.data.data.results;

                const rewards: Reward[] = [];
                for (const apiReward of apiRewards)
                {
                    try
                    {
                        // Parse the reward.
                        rewards.push(RewardTransformer.fromApi(apiReward));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Reward');
                        console.error(error);
                    }
                }

                return {
                    results: rewards,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: SearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/rewards';
    }

    protected getParams(): any
    {
        const searchConditionsService = new SearchConditionsService();
        const params = searchConditionsService.getQueryParamsFromConditions(
            this._conditions, this._conditions.limit ?? 20, this._conditions.sortBy ?? 'name',
        );

        params.limit = this._conditions.limit;

        return params;
    }
}