import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { unionBy } from 'lodash';
import User from '@/model/entities/organisation/user';

@Component({
    components:
    {
    },
})
export default class UserSelectInputComponent extends Vue
{
    @Prop()
    private label!: string;

    @Prop()
    private users!: User[];

    @Prop({
        type: Boolean,
        default: false,
    })
    private box!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private solo!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private readonly!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private error!: boolean;

    @Prop()
    private errorMessages!: string[];

    @Prop({
        type: Boolean,
        default: false,
    })
    private includeDisabled!: boolean;

    $refs!:
    {
        combobox: any
    };

    private searchTerm = '';
    private selectedUsers: User[] = [];
    private selectorUsers: User[] = [];
    private isLoading = false;

    private timeout: number|null = null;

    created()
    {
        this.updateSelectedUsersFromProp();
    }

    @Watch('users')
    private onUsersUpdated()
    {
        this.updateSelectedUsersFromProp();
    }

    /**
     * Returns the read-only value.
     */

    get readonlyValue()
    {
        const users: string[] = [];

        for (const user of this.users)
        {
            users.push(user.fullName);
        }

        return users.join(', ');
    }

    /**
     * Handles change events from the autocomplete.
     *
     * @param selectedUsers
     */

    private onChange(selectedUsers: any[])
    {
        // Convert selected contacts to actual email contacts.
        // Reason: NEW, unselected contacts will just be strings, not EmailContactInterface.
        const users = [];

        for (const user of selectedUsers)
        {
            if (typeof user === 'string')
            {
                console.log(user);
            }
            else if (typeof user === 'object')
            {
                users.push(user);
            }
        }

        // Emit a change event if the contacts are different than original props.
        if (JSON.stringify(users) !== JSON.stringify(this.selectedUsers))
        {
            this.$emit('change', users);
        }

        // Close the dropdown.
        this.$refs.combobox.isMenuActive = false;
    }

    /**
     * Handles the auto-complete lookups for user contacts.
     *
     * @param searchTerm
     */

    @Watch('searchTerm')
    private searchUser(searchTerm: string)
    {
        if (!searchTerm || searchTerm.length < 3)
        {
            return;
        }

        this.isLoading = true;

        if (this.timeout)
        {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(async() =>
        {
            const results = await this.$serviceContainer.getUserService().searchUsers({
                searchTerm,
                includeDisabled: this.includeDisabled,
            });

            this.selectorUsers = [];

            if (results)
            {
                for (const userResult of results.results)
                {
                    this.selectorUsers.push(userResult);
                }
            }

            this.isLoading = false;
        }, 500);
    }

    /**
     * Filters contacts in the dropdown relating to what's been typed.
     *
     * Needed due to the caching of items.
     *
     * @param item
     * @param searchTerm
     * @param itemText
     */

    private selectorFilter(
        item: User, searchTerm: string, itemText: string,
    ): boolean
    {
        const isNameMatch = (item.fullName && item.fullName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
        const isEmailMatch = item.email ? (item.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) : false;

        return isNameMatch || isEmailMatch;
    }

    /**
     * Updates the selected contacts based on the contacts prop.
     */

    private updateSelectedUsersFromProp()
    {
        this.selectedUsers = this.users ? this.users.slice() : [];

        if (this.users)
        {
            this.selectedUsers = unionBy(
                this.users, this.selectedUsers, 'id',
            );
        }
    }
}