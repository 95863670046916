import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Newsletter from '../../../entities/media/newsletter';
import NewsletterApiInterface from '@/model/entities/media/newsletter-api-interface';
import NewsletterTransformer from '../../../transformers/media/newsletter-transformer';

export default class PatchNewsletterTask extends AbstractApiPatchTask
{
    private _newsletter!: Newsletter;

    public async run(): Promise<Newsletter>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return NewsletterTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._newsletter;
    }

    set newsletter(newsletter: Newsletter)
    {
        this._newsletter = newsletter;
    }

    protected getUrl(): string
    {
        return `/newsletters/${ this._newsletter.id }`;
    }

    protected getData(): NewsletterApiInterface
    {
        return NewsletterTransformer.toApi(this._newsletter);
    }
}