import { Component, Vue } from 'vue-property-decorator';
import Banner from '../../../../../model/entities/media/banner';
import BannerResultsApiInterface from '../../../../../model/vos/media/banner-results-api-interface';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminBannerIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.banners.message.index_title'),
                disabled: true,
                to: { name: 'admin-banner-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            { text: this.$t('general.field.id'),
                align: 'left',
                value: 'id' },
            { text: this.$t('general.field.title'),
                align: 'left',
                value: 'title' },
            { text: this.$t('admin.banners.field.headline'),
                align: 'left',
                value: 'headline' },
            { text: this.$t('general.field.order'),
                align: 'center',
                value: 'displayOrder' },
            { text: this.$t('general.field.enabled'),
                align: 'center',
                value: 'enabled',
                sortable: false },
            { text: this.$t('general.field.options'),
                align: 'center',
                value: '',
                sortable: false },
        ];
    }

    private results: BannerResultsApiInterface =
        {
            results: [],
            total_results: 0,
            results_per_page: 50,
            total_pages: 0,
            page: 1,
            sort_by: 'displayOrder',
            descending: false,
        };

    private conditions: SearchConditionsInterface = {};

    private isLoading = true;

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        this.isLoading = true;

        this.conditions = Object.assign({}, conditions);
        const results = await this.$serviceContainer.getBannerService().searchBanners(conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-banner-create' });
    }

    /**
     * Handles opening of banners.
     *
     * @param banner
     */

    private onOpenBanner(banner: Banner)
    {
        NavigationHelper.pushRoute({
            name: 'admin-banner-edit',
            params: { id: banner.id.toString() },
        });
    }

    /**
     * Handles deleting of banners.
     *
     * @param banner
     */

    private onDeleteBanner(banner: Banner)
    {
        EventBus.instance.$emit(
            'confirm-dialog', this.$t('admin.banners.message.delete_title'), this.$t('admin.banners.message.delete_description'), () => this.deleteBanner(banner), true,
        );
    }

    /**
     * Deletes a banner
     *
     * @param banner
     */

    private async deleteBanner(banner: Banner)
    {
        try
        {
            await this.$serviceContainer.getBannerService().deleteBanner(banner.id);
            this.loadResults(this.conditions);

            EventBus.instance.$emit(
                'notification', this.$t('admin.banners.message.banner_deleted'), 'success',
            );
        }
        catch (error)
        {
            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_deleting_server_error'), 'error',
            );
        }
    }
}