import AbstractValidator from '../abstract-validator';
import Employee from '../../entities/organisation/employee';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class EmployeeValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a employee.
     *
     * @param Employee
     */

    public validate(employee: Employee): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateJobLevel(employee, this.errors);
        this.validateEmployeeNumber(employee, this.errors);

        this.validateFirstName(employee, this.errors);
        this.validateLastName(employee, this.errors);

        // This.validateEmail(employee, this.errors);

        return this.errors;
    }

    private validateJobLevel(employee: Employee, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(employee.jobLevel))
        {
            errors.push(this.i18n.t('admin.employees.validation.please_enter_a_job_level').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.jobLevel = errors;
        }
    }

    private validateEmployeeNumber(employee: Employee, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(employee.employeeNumber))
        {
            errors.push(this.i18n.t('admin.employees.validation.please_enter_the_employees_number').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.employeeNumber = errors;
        }
    }

    private validateFirstName(employee: Employee, validationErrors: ValidationErrorsInterface)
    {
        // Don't validate if a user is already set.
        if (employee.user)
        {
            return;
        }

        const errors: string[] = [];

        if (!this.isValidString(employee.firstName))
        {
            errors.push(this.i18n.t('authentication.message.please_enter_a_first_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.firstName = errors;
        }
    }

    private validateLastName(employee: Employee, validationErrors: ValidationErrorsInterface)
    {
        // Don't validate if a user is already set.
        if (employee.user)
        {
            return;
        }

        const errors: string[] = [];

        if (!this.isValidString(employee.lastName))
        {
            errors.push(this.i18n.t('authentication.message.please_enter_a_last_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.lastName = errors;
        }
    }

    private validateEmail(employee: Employee, validationErrors: ValidationErrorsInterface)
    {
        // Don't validate if a user is already set.
        if (employee.user)
        {
            return;
        }

        const errors: string[] = [];

        if (!this.isValidString(employee.email))
        {
            errors.push(this.i18n.t('authentication.message.please_enter_an_email').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.email = errors;
        }
    }
}