export default class User
{
    id = 0;
    firstName = '';
    lastName = '';
    email = '';
    phone: string|null = null;
    isDisabled = false;
    isNewsletterRegistered = false;
    isMarketingRegistered = false;
    isPrivacyNoticeAccepted = false;
    registrationDate: Date = new Date();
    lastLogin: Date|null = null;
    roles: string[] = [];
    token: string|null = null;

    /**
     * Returns the user's full name.
     */

    get fullName(): string
    {
        return (`${ this.firstName } ${ this.lastName }`).trim();
    }
}