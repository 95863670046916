import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Notification from '../../../model/entities/notifications/notification';
import NotificationComponent from '@/components/widgets/notification/index.vue';

@Component({
    components:
    {
        'app-notification': NotificationComponent,
    },
})

export default class UserNotificationsComponent extends Vue
{
    private isVisible = false;

    private notifications: Notification[] = [];

    created()
    {
        EventBus.instance.$on('open-notifications', () => this.openNotifications());
    }

    /**
     * Load notifications and pop it up
     */

    private async openNotifications()
    {
        this.notifications = await this.$serviceContainer.getNotificationService().getNotifications();

        this.isVisible = (this.notifications.length > 0);
    }

    /**
     * Mark a notification a read
     *
     * @param notificationId
     */

    private onRead(notificationId: number)
    {
        this.$serviceContainer.getNotificationService().readNotification(notificationId);
    }
}