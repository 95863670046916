import AssociationsApiInterface from '../../entities/associations-api-interface';
import Employee from '../../entities/organisation/employee';
import EmployeeApiInterface from '../../entities/organisation/employee-api-interface';
import LocationTransformer from './location-transformer';
import TeamTransformer from './team-transformer';
import UserTransformer from './user-transformer';

export default class EmployeeTransformer
{
    /**
     * Transforms an API employee representation into a Employee entity.
     *
     * @param apiEmployee
     */

    public static fromApi(apiEmployee: EmployeeApiInterface, associations?: AssociationsApiInterface): Employee
    {
        const employee = new Employee();
        employee.userId = apiEmployee.user_id;
        employee.locationId = apiEmployee.location_id;
        employee.teamId = apiEmployee.team_id;
        employee.jobLevel = apiEmployee.job_level;
        employee.employeeNumber = apiEmployee.employee_number;

        // Associations.
        if (associations)
        {
            if (associations.users && employee.userId in associations.users)
            {
                const user = UserTransformer.fromApi(associations.users[employee.userId]);
                employee.user = user;
            }

            if (associations.locations && employee.locationId in associations.locations)
            {
                const location = LocationTransformer.fromApi(associations.locations[employee.locationId], associations);
                employee.location = location;
            }

            if (associations.teams && employee.teamId in associations.teams)
            {
                const team = TeamTransformer.fromApi(associations.teams[employee.teamId], associations);
                employee.team = team;
            }
        }

        return employee;
    }

    /**
     * Transforms a Employee entity into an API representation of a employee.
     *
     * @param employee
     */

    public static toApi(employee: Employee): EmployeeApiInterface
    {
        return {
            user_id: employee.userId,
            location_id: employee.locationId,
            team_id: employee.teamId,
            job_level: employee.jobLevel,
            employee_number: employee.employeeNumber,
        };
    }
}