import { render, staticRenderFns } from "./_template.html?vue&type=template&id=47d94060&"
import script from "./_logic.ts?vue&type=script&lang=ts&"
export * from "./_logic.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports