import AbstractApiDeleteTask from '../abstract-api-delete-task';

export default class DeleteAccountTask extends AbstractApiDeleteTask
{
    public async run(): Promise<boolean>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return true;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return false;
    }

    protected getUrl(): string
    {
        return '/my-account';
    }
}