import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Post from '../../../../../model/entities/blog/post';
import PostFileInterface from '../../../../../model/vos/blog/post-file-interface';
import PostFormComponent from '@/components/widgets/post-form/index.vue';

@Component({
    components:
    {
        'app-post-form': PostFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminPostCreatePageComponent extends Vue
{
    private post: Post|null = null;

    created()
    {
        this.post = Object.assign({}, new Post());
        this.post.publishedDate = new Date();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.posts.message.index_title'),
                disabled: false,
                to: { name: 'admin-post-index' },
                exact: true,
            },
            {
                text: this.$t('admin.posts.message.create_title'),
                disabled: true,
                to: { name: 'admin-post-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param post
     * @param files
     */

    private async onSave(post: Post, files: PostFileInterface[])
    {
        try
        {
            // Create the post.
            const created = await this.$serviceContainer.getPostService().createPost(post);

            // Upload files.
            if (files.length > 0)
            {
                await this.uploadFiles(created, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.posts.message.post_created'));

            // Redirect to the post.
            NavigationHelper.pushRoute({
                name: 'admin-post-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads post files.
     *
     * @param post
     * @param files
     */

    private async uploadFiles(post: Post, files: PostFileInterface[]): Promise<void>
    {
        for (const file of files)
        {
            await this.$serviceContainer.getPostService().uploadPostFile(
                post.id,
                file.field,
                file.file,
            );
        }
    }
}