import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Location from '../../../entities/organisation/location';
import LocationApiInterface from '@/model/entities/organisation/location-api-interface';
import LocationTransformer from '../../../transformers/organisation/location-transformer';

export default class PatchLocationTask extends AbstractApiPatchTask
{
    private _location!: Location;

    public async run(): Promise<Location>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return LocationTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._location;
    }

    set location(location: Location)
    {
        this._location = location;
    }

    protected getUrl(): string
    {
        return `/locations/${ this._location.id }`;
    }

    protected getData(): LocationApiInterface
    {
        return LocationTransformer.toApi(this._location);
    }
}