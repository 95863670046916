import { Component, Vue } from 'vue-property-decorator';
import NavComponent from '@/components/widgets/nav-links/index.vue';

@Component({
    components:
    {
        'app-nav': NavComponent,
    },
})
export default class NavbarComponent extends Vue
{}