import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import LocationTransformer from './location-transformer';
import SafetyAmbassador from '../../entities/organisation/safety-ambassador';
import SafetyAmbassadorApiInterface from '../../entities/organisation/safety-ambassador-api-interface';

export default class SafetyAmbassadorTransformer
{
    /**
     * Transforms an API safetyAmbassador representation into a SafetyAmbassador entity.
     *
     * @param apiSafetyAmbassador
     */

    public static fromApi(apiSafetyAmbassador: SafetyAmbassadorApiInterface, associations?: AssociationsApiInterface): SafetyAmbassador
    {
        const safetyAmbassador = new SafetyAmbassador();
        safetyAmbassador.id = apiSafetyAmbassador.id;
        safetyAmbassador.firstName = apiSafetyAmbassador.first_name;
        safetyAmbassador.lastName = apiSafetyAmbassador.last_name;
        safetyAmbassador.image = apiSafetyAmbassador.image;
        safetyAmbassador.locationId = apiSafetyAmbassador.location_id;
        safetyAmbassador.isEnabled = apiSafetyAmbassador.enabled;
        safetyAmbassador.created = parseISO(apiSafetyAmbassador.created);
        safetyAmbassador.updated = parseISO(apiSafetyAmbassador.updated);

        // Associations.
        if (associations)
        {
            if (safetyAmbassador.locationId && associations.locations?.[safetyAmbassador.locationId])
            {
                const location = LocationTransformer.fromApi(associations.locations[safetyAmbassador.locationId], associations);
                safetyAmbassador.location = location;
            }
        }

        return safetyAmbassador;
    }

    /**
     * Transforms a SafetyAmbassador entity into an API representation of a safetyAmbassador.
     *
     * @param safetyAmbassador
     */

    public static toApi(safetyAmbassador: SafetyAmbassador): SafetyAmbassadorApiInterface
    {
        return {
            id: safetyAmbassador.id,
            first_name: safetyAmbassador.firstName,
            last_name: safetyAmbassador.lastName,
            image: safetyAmbassador.image,
            location_id: safetyAmbassador.locationId,
            enabled: safetyAmbassador.isEnabled,
            created: formatISO(safetyAmbassador.created),
            updated: formatISO(safetyAmbassador.updated),
        };
    }
}