import AbstractApiPostTask from '../abstract-api-post-task';
import User from '@/model/entities/organisation/user';
import UserTransformer from '@/model/transformers/organisation/user-transformer';

export default class LoginTask extends AbstractApiPostTask
{
    private _email!: string;
    private _password!: string;

    public async run(): Promise<User|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const user = UserTransformer.fromApi(response.data.data);

                return user;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set email(email: string)
    {
        this._email = email;
    }

    set password(password: string)
    {
        this._password = password;
    }

    protected getUrl(): string
    {
        return '/login';
    }

    protected getData(): any
    {
        return {
            email: this._email,
            password: this._password,
        };
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}