import { Component, Prop, Vue } from 'vue-property-decorator';
import Scene from '../../../model/entities/webscenes/scene';
import UserSceneResultsApiInterface from '../../../model/vos/webscenes/user-scene-results-api-interface';

@Component({
    components:
    {},
})
export default class SceneResultsComponent extends Vue
{
    @Prop()
    private scene!: Scene;

    @Prop()
    private results!: UserSceneResultsApiInterface;

    get resultsData()
    {
        return [
            {
                field: this.$t('scenes.field.correct'),
                value: `${ this.results.total_correct_answers }/${ this.results.total_questions }`,
                error: this.results.total_correct_answers < this.results.total_questions,
            },
            {
                field: this.$t('general.field.status'),
                value: this.results.complete ? this.$t('scenes.message.complete') : this.$t('scenes.message.failed'),
                error: !this.results.complete,
            },
        ];
    }

    private onContinueScene()
    {
        this.$emit('continue-scene');
    }

    private onRestartScene()
    {
        this.$emit('restart-scene');
    }

    private onExitScene()
    {
        this.$emit('exit-scene');
    }
}