import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Banner from '../../../entities/media/banner';
import BannerApiInterface from '../../../entities/media/banner-api-interface';
import BannerResultsApiInterface from '../../../vos/media/banner-results-api-interface';
import BannerTransformer from '../../../transformers/media/banner-transformer';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchConditionsService from '@/model/services/search/search-conditions-service';

export default class SearchBannersTask extends AbstractApiGetTask
{
    private _conditions!: SearchConditionsInterface;

    public async run(): Promise<BannerResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiBanners: BannerApiInterface[] = response.data.data.results;

                const banners: Banner[] = [];
                for (const apiBanner of apiBanners)
                {
                    try
                    {
                        // Parse the banner.
                        banners.push(BannerTransformer.fromApi(apiBanner));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Banner');
                        console.error(error);
                    }
                }

                return {
                    results: banners,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: SearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/banners';
    }

    protected getParams(): any
    {
        const searchConditionsService = new SearchConditionsService();
        const params = searchConditionsService.getQueryParamsFromConditions(
            this._conditions, 20, 'displayOrder',
        );

        params.limit = this._conditions.limit;

        return params;
    }
}