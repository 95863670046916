import CorrectAnswerTranslationInterface from '../../vos/webscenes/correct-answer-translation-interface';
import SceneQuestion from './scene-question';
import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class SceneQuestionAnswer
{
    id = 0;
    questionId: number|null = null;
    question: SceneQuestion|null = null;
    text: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    selectionText: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: string|null = null;
    imageFile: File|null = null;
    displayOrder = 1;
    isCorrect: CorrectAnswerTranslationInterface = { en: false };
    isEnabled = false;
}