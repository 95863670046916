/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Employee from '@/model/entities/organisation/employee';
import EmployeeApiInterface from '@/model/entities/organisation/employee-api-interface';
import EmployeeTransformer from '@/model/transformers/organisation/employee-transformer';
import User from '@/model/entities/organisation/user';
import UserApiInterface from '@/model/entities/organisation/user-api-interface';
import UserTransformer from '@/model/transformers/organisation/user-transformer';

export default {
    namespaced: true,
    state: (): any => ({
        employee: null,
        user: null,
    }),
    mutations: {
        employee(state: any, employee: Employee|null): void
        {
            if (employee)
            {
                state.employee = Object.assign({}, EmployeeTransformer.toApi(employee));
            }
            else
            {
                state.employee = null;
            }
        },
        user(state: any, user: User|null): void
        {
            if (user)
            {
                state.user = Object.assign({}, UserTransformer.toApi(user));
            }
            else
            {
                state.user = null;
            }
        },
    },
    actions: {},
    getters: {
        employee(state: any): EmployeeApiInterface|null
        {
            return state.employee;
        },
        user(state: any): UserApiInterface|null
        {
            return state.user;
        },
    },
};