import { Component, Vue, Watch } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import LocaleSwitcherComponent from '@/components/widgets/locale-switcher/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Notification from '../../../model/entities/notifications/notification';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-locale-switcher': LocaleSwitcherComponent,
        'app-translation': TranslationComponent,
    },
})
export default class HeaderComponent extends Vue
{
    private notifications: Notification[] = [];
    private employeeNumber = '';
    private sceneSearchTerm = '';

    created()
    {
        this.sceneSearchTerm = 'term' in this.$route.query ? this.$route.query.term as string : '';
        this.loadNotifications();
    }

    @Watch('$route.query')
    private onQueryUpdated()
    {
        this.sceneSearchTerm = 'term' in this.$route.query ? this.$route.query.term as string : '';
    }

    get mainLogoUri(): string
    {
        if (LocaleHelper.getLocale() === 'nl-BE')
        {
            return require('../../../assets/images/logos/stronger-together-logo-colour_nl-BE.png');
        }
        else
        {
            return require('../../../assets/images/logos/stronger-together-logo-colour.png');
        }
    }

    get norbordLogoUri(): string
    {
        return require('../../../assets/images/logos/norbord-logo-colour.png');
    }

    /**
     * Load the notifications
     */

    private async loadNotifications()
    {
        if (!this.user)
        {
            return;
        }

        try
        {
            this.notifications = await this.$serviceContainer.getNotificationService().getUnreadNotifications();
            if (!this.notifications)
            {
                this.notifications = [];
            }
        }
        catch (error)
        {
            //
        }
    }

    /**
     * Handles login requests.
     */

    private async onLogin()
    {
        if (!this.employeeNumber)
        {
            return;
        }

        try
        {
            // Login.
            await this.$serviceContainer.getEmployeeService().loginEmployee(this.employeeNumber);
            this.employeeNumber = '';

            // Send a logged-in event.
            EventBus.instance.$emit('logged-in');
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('authentication.error.problem_logging_in'), 'error',
            );
        }
    }

    /**
     * Handles logout requests.
     */

    private onLogout()
    {
        this.$emit('logout');
    }

    /**
     * Handle opening notifications.
     */

    private onNotifications()
    {
        EventBus.instance.$emit('open-notifications');
    }

    /**
     * Handles scene searches.
     */

    private onSceneSearch()
    {
        NavigationHelper.pushRoute({
            name: 'search',
            query: { term: this.sceneSearchTerm },
        });
    }
}