import { Component, Vue } from 'vue-property-decorator';
import { size } from 'lodash';
import ResetPasswordFormValidator from '@/model/validator/authentication/reset-password-form-validator';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({})
export default class ResetPasswordFormComponent extends Vue
{
    private password = '';
    private passwordConfirmation = '';

    private validationErrors: ValidationErrorsInterface = {};

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        const validator: ResetPasswordFormValidator = new ResetPasswordFormValidator();
        this.validationErrors = validator.validate(this.password, this.passwordConfirmation);

        return size(this.validationErrors) === 0;
    }

    /**
     * Handles submissions.
     */

    onSubmit()
    {
        if (!this.isValid)
        {
            return;
        }

        this.$emit('submitted', this.password);
    }
}