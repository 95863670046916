import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Newsletter from '../../../entities/media/newsletter';
import NewsletterApiInterface from '../../../entities/media/newsletter-api-interface';
import NewsletterTransformer from '../../../transformers/media/newsletter-transformer';

export default class UploadNewsletterFileTask extends AbstractApiPostTask
{
    private _newsletterId!: number;
    private _field!: string;
    private _locale!: string;
    private _file!: File;

    public async run(): Promise<Newsletter|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiNewsletter: NewsletterApiInterface = response.data.data;

                if (apiNewsletter)
                {
                    return NewsletterTransformer.fromApi(apiNewsletter);
                }
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set newsletterId(newsletterId: number)
    {
        this._newsletterId = newsletterId;
    }

    set field(field: string)
    {
        this._field = field;
    }

    set locale(locale: string)
    {
        this._locale = locale;
    }

    set file(file: File)
    {
        this._file = file;
    }

    protected getUrl(): string
    {
        return `/newsletters/${ this._newsletterId }/file-upload`;
    }

    protected getData(): FormData
    {
        const data = new FormData();
        data.append('newsletter_id', this._newsletterId.toString());
        data.append('field', this._field.toString());
        data.append('locale', this._locale.toString());
        data.append('file', this._file);

        return data;
    }
}