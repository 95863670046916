import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Team from '../../../../../model/entities/organisation/team';
import TeamFormComponent from '@/components/widgets/team-form/index.vue';

@Component({
    components:
    {
        'app-team-form': TeamFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminTeamEditPageComponent extends Vue
{
    private team: Team|null = null;
    private isLoading = true;

    created()
    {
        this.loadTeam();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.teams.message.index_title'),
                disabled: false,
                to: { name: 'admin-team-index' },
                exact: true,
            },
            {
                text: this.$t('admin.teams.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the team for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.team ? this.$et(this.team.name) : this.$t('admin.teams.edit_title').toString();
    }

    /**
     * Loads the team.
     */

    private async loadTeam()
    {
        this.isLoading = true;
        const team = await this.$serviceContainer.getTeamService().loadTeam(parseInt(this.$route.params.id));
        this.team = Object.assign({}, team);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param team
     */

    private async onSave(team: Team)
    {
        try
        {
            // Update the team.
            const updated = await this.$serviceContainer.getTeamService().updateTeam(team);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.teams.message.team_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}