import { Component, Vue } from 'vue-property-decorator';
import { get as _get, set as _set, merge } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import TranslationComponent from '@/components/widgets/translation/index.vue';
import VueI18n from 'vue-i18n';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
        'app-translation': TranslationComponent,
    },
})
export default class AdminInterfaceTranslationsEditPageComponent extends Vue
{
    private masterTranslations: VueI18n.LocaleMessages = {};
    private translations: {[key: string]: {
        'en': string,
        'nl-BE': string,
    }} = {};

    private isLoading = true;

    created()
    {
        this.loadTranslations();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.translations.message.edit_interface_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Loads the reward.
     */

    private async loadTranslations()
    {
        this.isLoading = true;

        // Load the translations
        this.masterTranslations = await this.$serviceContainer.getTranslationService().loadInterfaceTranslations();

        // Convert to translations where each key has each locale, rather than locale at the top level
        this.convertTranslations(this.masterTranslations.en, '');

        this.isLoading = false;
    }

    /**
     * Convert the translations to the format needed for the page
     *
     * @param translations
     * @param parentKeyPath
     */

    private convertTranslations(translations: VueI18n.LocaleMessageObject, parentKeyPath: string)
    {
        for (const key in translations)
        {
            let keyPath = `${ parentKeyPath }.${ key }`;
            if (keyPath[0] === '.')
            {
                keyPath = keyPath.substring(1);
            }

            const value = translations[key];

            if (typeof value === 'object')
            {
                this.convertTranslations(value as VueI18n.LocaleMessageObject, keyPath);
            }
            else
            {
                const converted: any = { en: value };

                for (const locale of this.supportedLocales)
                {
                    const localeKeyPath = `${ locale }.${ keyPath }`;
                    const localeValue = _get(
                        this.masterTranslations, localeKeyPath, '',
                    );

                    converted[locale] = localeValue;
                }

                _set(
                    this.translations, keyPath, converted,
                );
            }
        }
    }

    /**
     * Unconverts the translations to the format needed for the master translation file
     *
     * @param translations
     * @param parentKeyPath
     */

    private unconvertTranslations(translations: any, parentKeyPath: string): VueI18n.LocaleMessages
    {
        const unconverted = {};

        // Translations
        if ('en' in translations)
        {
            for (const locale in translations)
            {
                _set(
                    unconverted, `${ locale }.${ parentKeyPath }`, translations[locale],
                );
            }
        }

        // Category
        else
        {
            for (const key in translations)
            {
                let keyPath = `${ parentKeyPath }.${ key }`;
                if (keyPath[0] === '.')
                {
                    keyPath = keyPath.substring(1);
                }

                const subUnconverted = this.unconvertTranslations(translations[key], keyPath);

                merge(unconverted, subUnconverted);
            }
        }

        return unconverted;
    }

    /**
     * Handles save events from the form.
     */

    private async onSave()
    {
        try
        {
            // Unconvert the translations back to what is expected of the translation file
            const translations: VueI18n.LocaleMessages = this.unconvertTranslations(this.translations, '');

            // Update the translations
            const updated = await this.$serviceContainer.getTranslationService().saveInterfaceTranslations(translations);
            if (updated)
            {
                // Update the actual in-use translations
                for (const locale in translations)
                {
                    const messages: VueI18n.LocaleMessageObject = translations[locale];

                    this.$i18n.setLocaleMessage(locale, messages);
                }

                // Notify the user of a successful update
                EventBus.instance.$emit('notification', this.$t('admin.translations.message.interface_translations_updated'));
            }
            else
            {
                EventBus.instance.$emit(
                    'notification', this.$t('general.error.failed_saving_server_error'), 'error',
                );
            }
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}