import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import FirstAider from '../../../entities/organisation/first-aider';
import FirstAiderApiInterface from '../../../entities/organisation/first-aider-api-interface';
import FirstAiderTransformer from '../../../transformers/organisation/first-aider-transformer';

export default class GetFirstAiderTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<FirstAider|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiFirstAider: FirstAiderApiInterface = response.data.data;

                return FirstAiderTransformer.fromApi(apiFirstAider, apiFirstAider.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/first-aiders/${ this._id }`;
    }
}