import { Component, Vue, Watch } from 'vue-property-decorator';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Scene from '../../../model/entities/webscenes/scene';
import SceneButtonComponent from '@/components/widgets/scene-button/index.vue';
import SceneSearchConditionsInterface from '../../../model/vos/webscenes/scene-search-conditions-interface';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
        'app-scene-button': SceneButtonComponent,
    },
})
export default class SearchPageComponent extends Vue
{
    private isLoading = true;
    private scenes: Scene[] = [];

    async created()
    {
        this.isLoading = true;
        await this.loadScenes();
        this.isLoading = false;
    }

    @Watch('$route.query')
    private onQueryUpdated()
    {
        this.loadScenes();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('scenes.message.search_results'),
                disabled: false,
                to: { name: 'search',
                    params: this.$route.params },
                exact: true,
            },
        ];
    }

    /**
     * Returns rows containing 3 scenes each.
     */

    get rows(): Scene[][]
    {
        const rows: Scene[][] = [];

        // Calculate how many columns should appear.
        let columns: number;
        switch (this.$vuetify.breakpoint.name)
        {
        case 'xl':
        case 'lg':
            columns = 3;
            break;

        case 'md':
            columns = 2;
            break;

        case 'sm':
        case 'xs':
        default:
            columns = 1;
            break;
        }

        let rowItems: Scene[] = [];
        for (let index = 0; index < this.scenes.length; index++)
        {
            const scene = this.scenes[index];

            rowItems.push(scene);

            // New row?
            if ((index + 1) % columns === 0)
            {
                rows.push(rowItems);
                rowItems = [];
            }
        }

        rows.push(rowItems);

        return rows;
    }

    /**
     * Loads the scenes.
     */

    private async loadScenes()
    {
        const searchTerm = 'term' in this.$route.query ? this.$route.query.term as string : undefined;

        const searchConditions: SceneSearchConditionsInterface =
        {
            searchTerm,
            limit: 20,
        };

        const results = await this.$serviceContainer.getSceneService().searchScenes(searchConditions);
        if (results)
        {
            this.scenes = results.results;
        }
    }
}