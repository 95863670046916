import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import RegistrationFormComponent from '@/components/widgets/registration-form/index.vue';
import User from '@/model/entities/organisation/user';

@Component({
    components:
    {
        'vp-registration-form': RegistrationFormComponent,
    },
})
export default class RegisterView extends Vue
{
    private isRegistering = false;
    private isRegistered = false;

    private error: string|null = null;

    /**
     * Handles registration from the registration form.
     *
     * @param user
     * @param password
     */

    private async onRegister(user: User, password: string)
    {
        try
        {
            // No longer an error (until after).
            this.error = null;

            // Mark as registering.
            this.isRegistering = true;

            // Register the user.
            const created = await this.$serviceContainer.getAuthenticationService().registerUser(user, password);
            if (created)
            {
                // Mark as registered.
                this.isRegistered = true;
            }
        }
        catch (error: any)
        {
            console.error(error);

            switch (error.message)
            {
            case 'user_already_exists':
                this.error = this.$t('authentication.error.user_already_exists').toString();
                break;

            case 'invalid_user':
                this.error = this.$t('authentication.error.invalid_user').toString();
                break;

            default:
                EventBus.instance.$emit(
                    'notification', this.$t('authentication.error.problem_registering'), 'error',
                );
            }
        }
        finally
        {
            // No longer registering.
            this.isRegistering = false;
        }
    }
}