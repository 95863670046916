import GetTagsTask from '../../tasks/api/categorisation/get-tags';

export default class TagService
{
    /**
     * Returns the tags.
     *
     * @param searchTerm
     */

    public async getTags(searchTerm: string): Promise<string[]>
    {
        const task = new GetTagsTask();
        task.searchTerm = searchTerm;

        return task.run();
    }
}