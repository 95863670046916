import AccessDeniedView from '@/components/pages/access-denied/index.vue';
import AdminActivityCreatePageComponent from '@/components/pages/admin/activities/create/index.vue';
import AdminActivityEditPageComponent from '@/components/pages/admin/activities/edit/index.vue';
import AdminActivityIndexPageComponent from '@/components/pages/admin/activities/index/index.vue';
import AdminBannerCreatePageComponent from '@/components/pages/admin/banners/create/index.vue';
import AdminBannerEditPageComponent from '@/components/pages/admin/banners/edit/index.vue';
import AdminBannerIndexPageComponent from '@/components/pages/admin/banners/index/index.vue';
import AdminCategoryCreatePageComponent from '@/components/pages/admin/categories/create/index.vue';
import AdminCategoryEditPageComponent from '@/components/pages/admin/categories/edit/index.vue';
import AdminCategoryIndexPageComponent from '@/components/pages/admin/categories/index/index.vue';
import AdminEmployeeCreatePageComponent from '@/components/pages/admin/employees/create/index.vue';
import AdminEmployeeEditPageComponent from '@/components/pages/admin/employees/edit/index.vue';
import AdminEmployeeIndexPageComponent from '@/components/pages/admin/employees/index/index.vue';
import AdminFirstAiderCreatePageComponent from '@/components/pages/admin/first-aiders/create/index.vue';
import AdminFirstAiderEditPageComponent from '@/components/pages/admin/first-aiders/edit/index.vue';
import AdminFirstAiderIndexPageComponent from '@/components/pages/admin/first-aiders/index/index.vue';
import AdminInterfaceTranslationsEditPageComponent from '@/components/pages/admin/translations/interface/edit/index.vue';
import AdminNewsletterCreatePageComponent from '@/components/pages/admin/newsletters/create/index.vue';
import AdminNewsletterEditPageComponent from '@/components/pages/admin/newsletters/edit/index.vue';
import AdminNewsletterIndexPageComponent from '@/components/pages/admin/newsletters/index/index.vue';
import AdminNotificationCreatePageComponent from '@/components/pages/admin/notifications/create/index.vue';
import AdminNotificationEditPageComponent from '@/components/pages/admin/notifications/edit/index.vue';
import AdminNotificationIndexPageComponent from '@/components/pages/admin/notifications/index/index.vue';
import AdminPostCreatePageComponent from '@/components/pages/admin/posts/create/index.vue';
import AdminPostEditPageComponent from '@/components/pages/admin/posts/edit/index.vue';
import AdminPostIndexPageComponent from '@/components/pages/admin/posts/index/index.vue';
import AdminRewardCreatePageComponent from '@/components/pages/admin/rewards/create/index.vue';
import AdminRewardEditPageComponent from '@/components/pages/admin/rewards/edit/index.vue';
import AdminRewardIndexPageComponent from '@/components/pages/admin/rewards/index/index.vue';
import AdminSafetyAmbassadorCreatePageComponent from '@/components/pages/admin/safety-ambassadors/create/index.vue';
import AdminSafetyAmbassadorEditPageComponent from '@/components/pages/admin/safety-ambassadors/edit/index.vue';
import AdminSafetyAmbassadorIndexPageComponent from '@/components/pages/admin/safety-ambassadors/index/index.vue';
import AdminSceneCreatePageComponent from '@/components/pages/admin/scenes/create/index.vue';
import AdminSceneEditPageComponent from '@/components/pages/admin/scenes/edit/index.vue';
import AdminSceneIndexPageComponent from '@/components/pages/admin/scenes/index/index.vue';
import AdminTeamCreatePageComponent from '@/components/pages/admin/teams/create/index.vue';
import AdminTeamEditPageComponent from '@/components/pages/admin/teams/edit/index.vue';
import AdminTeamIndexPageComponent from '@/components/pages/admin/teams/index/index.vue';
import AdminUserEditView from '@/components/pages/admin/users/edit/index.vue';
import AdminUserIndexView from '@/components/pages/admin/users/index/index.vue';
import AdminVideoCreatePageComponent from '@/components/pages/admin/videos/create/index.vue';
import AdminVideoEditPageComponent from '@/components/pages/admin/videos/edit/index.vue';
import AdminVideoIndexPageComponent from '@/components/pages/admin/videos/index/index.vue';
import BlogComponent from '@/components/pages/blog/index.vue';
import CategoryIndexPageComponent from '@/components/pages/categories/index/index.vue';
import CategoryViewPageComponent from '@/components/pages/categories/view/index.vue';
import FeedbackComponent from '@/components/pages/feedback/index.vue';
import FirstAidersComponent from '@/components/pages/first-aiders/index.vue';
import HelpComponent from '@/components/pages/help/index.vue';
import HomePageComponent from '@/components/pages/home/index.vue';
import LeaderboardPageComponent from '@/components/pages/leaderboard/index.vue';
import LoginView from '@/components/pages/login/index.vue';
import LogoutView from '@/components/pages/logout/index.vue';
import NewslettersComponent from '@/components/pages/newsletters/index.vue';
import PointsPageComponent from '@/components/pages/points/index.vue';
import PostComponent from '@/components/pages/post/index.vue';
import RegisterView from '@/components/pages/register/index.vue';
import ResetPasswordView from '@/components/pages/reset-password/index.vue';
import RewardIndexPageComponent from '@/components/pages/rewards/index/index.vue';
import SafetyAmbassadorsComponent from '@/components/pages/safety-ambassadors/index.vue';
import ScenePageComponent from '@/components/pages/scene/index.vue';
import SearchPageComponent from '@/components/pages/search/index.vue';
import TeamLeaderboardPageComponent from '@/components/pages/team-leaderboard/index.vue';
import VideosComponent from '@/components/pages/videos/index.vue';

const routes =
[
    {
        path: '/access-denied',
        name: 'access-denied',
        component: AccessDeniedView,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/',
        name: 'home',
        component: HomePageComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/search',
        name: 'search',
        component: SearchPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/categories',
        name: 'category-index',
        component: CategoryIndexPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/categories/:categoryRef',
        name: 'category-view',
        component: CategoryViewPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/categories/:categoryRef/:sceneRef',
        name: 'scene-view',
        component: ScenePageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/my-points',
        name: 'my-points',
        component: PointsPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/leaderboard',
        name: 'leaderboard',
        component: LeaderboardPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/team-leaderboard',
        name: 'team-leaderboard',
        component: TeamLeaderboardPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/rewards',
        name: 'reward-index',
        component: RewardIndexPageComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: FeedbackComponent,
        meta:
        {
            requiresAuth: true,
        },
    },
    {
        path: '/help',
        name: 'help',
        component: HelpComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/blog/:slug',
        name: 'post',
        component: PostComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/first-aiders/:type',
        name: 'first-aiders',
        component: FirstAidersComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/newsletters',
        name: 'newsletters',
        component: NewslettersComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/safety-ambassadors',
        name: 'safety-ambassadors',
        component: SafetyAmbassadorsComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/videos',
        name: 'videos',
        component: VideosComponent,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/admin/activities',
        name: 'admin-activity-index',
        component: AdminActivityIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/activities/create',
        name: 'admin-activity-create',
        component: AdminActivityCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/activities/:id/edit',
        name: 'admin-activity-edit',
        component: AdminActivityEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/banners',
        name: 'admin-banner-index',
        component: AdminBannerIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/banners/create',
        name: 'admin-banner-create',
        component: AdminBannerCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/banners/:id/edit',
        name: 'admin-banner-edit',
        component: AdminBannerEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/categories',
        name: 'admin-category-index',
        component: AdminCategoryIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/categories/create',
        name: 'admin-category-create',
        component: AdminCategoryCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/categories/:id/edit',
        name: 'admin-category-edit',
        component: AdminCategoryEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/employees',
        name: 'admin-employee-index',
        component: AdminEmployeeIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/employees/create',
        name: 'admin-employee-create',
        component: AdminEmployeeCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/employees/:id/edit',
        name: 'admin-employee-edit',
        component: AdminEmployeeEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/first-aiders',
        name: 'admin-first-aider-index',
        component: AdminFirstAiderIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/first-aiders/create',
        name: 'admin-first-aider-create',
        component: AdminFirstAiderCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/first-aiders/:id/edit',
        name: 'admin-first-aider-edit',
        component: AdminFirstAiderEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/newsletters',
        name: 'admin-newsletter-index',
        component: AdminNewsletterIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/newsletters/create',
        name: 'admin-newsletter-create',
        component: AdminNewsletterCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/newsletters/:id/edit',
        name: 'admin-newsletter-edit',
        component: AdminNewsletterEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/posts',
        name: 'admin-post-index',
        component: AdminPostIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/posts/create',
        name: 'admin-post-create',
        component: AdminPostCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/posts/:id/edit',
        name: 'admin-post-edit',
        component: AdminPostEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/rewards',
        name: 'admin-reward-index',
        component: AdminRewardIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/rewards/create',
        name: 'admin-reward-create',
        component: AdminRewardCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/rewards/:id/edit',
        name: 'admin-reward-edit',
        component: AdminRewardEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/safety-ambassadors',
        name: 'admin-safety-ambassador-index',
        component: AdminSafetyAmbassadorIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/safety-ambassadors/create',
        name: 'admin-safety-ambassador-create',
        component: AdminSafetyAmbassadorCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/safety-ambassadors/:id/edit',
        name: 'admin-safety-ambassador-edit',
        component: AdminSafetyAmbassadorEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/scenes',
        name: 'admin-scene-index',
        component: AdminSceneIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/scenes/create',
        name: 'admin-scene-create',
        component: AdminSceneCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/scenes/:sceneId',
        name: 'admin-scene-edit',
        component: AdminSceneEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/teams',
        name: 'admin-team-index',
        component: AdminTeamIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/teams/create',
        name: 'admin-team-create',
        component: AdminTeamCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/teams/:id/edit',
        name: 'admin-team-edit',
        component: AdminTeamEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/users',
        name: 'admin-user-index',
        component: AdminUserIndexView,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/users/:id/edit',
        name: 'admin-user-edit',
        component: AdminUserEditView,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/videos',
        name: 'admin-video-index',
        component: AdminVideoIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/videos/create',
        name: 'admin-video-create',
        component: AdminVideoCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/videos/:id/edit',
        name: 'admin-video-edit',
        component: AdminVideoEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/notifications',
        name: 'admin-notification-index',
        component: AdminNotificationIndexPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/notifications/create',
        name: 'admin-notification-create',
        component: AdminNotificationCreatePageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/notifications/:id/edit',
        name: 'admin-notification-edit',
        component: AdminNotificationEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/admin/translations/interface',
        name: 'admin-interface-translations-edit',
        component: AdminInterfaceTranslationsEditPageComponent,
        meta:
        {
            requiresAuth: true,
            roles: [ 'ROLE_ADMIN' ],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutView,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        meta:
        {
            requiresAuth: false,
        },
    },
    {
        path: '/reset-password/:key?',
        name: 'reset-password',
        component: ResetPasswordView,
        meta:
        {
            requiresAuth: false,
        },
    },
];

export default routes;