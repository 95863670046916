import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Reward from '../../entities/rewards/reward';
import RewardApiInterface from '../../entities/rewards/reward-api-interface';

export default class RewardTransformer
{
    /**
     * Transforms an API reward representation into a Reward entity.
     *
     * @param apiReward
     */

    public static fromApi(apiReward: RewardApiInterface, associations?: AssociationsApiInterface): Reward
    {
        const reward = new Reward();
        reward.id = apiReward.id;
        reward.name = apiReward.name;
        reward.description = apiReward.description;
        reward.image = apiReward.image;
        reward.points = apiReward.points;
        reward.isEnabled = apiReward.enabled;
        reward.created = parseISO(apiReward.created);
        reward.updated = parseISO(apiReward.updated);

        return reward;
    }

    /**
     * Transforms a Reward entity into an API representation of a reward.
     *
     * @param reward
     */

    public static toApi(reward: Reward): RewardApiInterface
    {
        return {
            id: reward.id,
            name: reward.name,
            description: reward.description,
            image: reward.image,
            points: reward.points,
            enabled: reward.isEnabled,
            created: formatISO(reward.created),
            updated: formatISO(reward.updated),
        };
    }
}