import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Reward from '../../../entities/rewards/reward';
import RewardApiInterface from '../../../entities/rewards/reward-api-interface';
import RewardTransformer from '../../../transformers/rewards/reward-transformer';

export default class GetActiveRewardsTask extends AbstractApiGetTask
{
    public async run(): Promise<Reward[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiRewards: RewardApiInterface[] = response.data.data;

                const rewards: Reward[] = [];
                for (const apiReward of apiRewards)
                {
                    try
                    {
                        // Parse the reward.
                        rewards.push(RewardTransformer.fromApi(apiReward));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Reward');
                        console.error(error);
                    }
                }

                return rewards;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/rewards/active';
    }
}