import DeleteEmployeeTask from '../../tasks/api/organisation/delete-employee';
import Employee from '../../entities/organisation/employee';
import EmployeePointsApiInterface from '../../vos/organisation/employee-points-api-interface';
import EmployeeResultsApiInterface from '../../vos/organisation/employee-results-api-interface';
import EmployeeSearchConditionsInterface from '../../vos/organisation/employee-search-conditions-interface';
import GetEmployeePointsTask from '../../tasks/api/organisation/get-employee-points';
import GetEmployeeTask from '../../tasks/api/organisation/get-employee';
import GetPointsHistoryTask from '../../tasks/api/organisation/get-points-history';
import LoginEmployeeTask from '../../tasks/api/organisation/login-employee';
import PatchEmployeeTask from '../../tasks/api/organisation/patch-employee';
import PointsHistoryApiInterface from '../../vos/organisation/points-history-api-interface';
import PostEmployeeActivityTask from '../../tasks/api/organisation/post-employee-activity';
import PostEmployeeRewardTask from '../../tasks/api/organisation/post-employee-reward';
import PostEmployeeTask from '../../tasks/api/organisation/post-employee';
import SearchEmployeesTask from '../../tasks/api/organisation/search-employees';
import User from '@/model/entities/organisation/user';
import store from '@/store';

export default class EmployeeService
{
    /**
     * Returns employees matching the conditions.
     *
     * @param conditions
     */

    public async searchEmployees(conditions: EmployeeSearchConditionsInterface): Promise<EmployeeResultsApiInterface|null>
    {
        const task = new SearchEmployeesTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a employee.
     *
     * @param id
     */

    public async loadEmployee(id: number): Promise<Employee|null>
    {
        const task = new GetEmployeeTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a employee.
     *
     * @param employee
     */

    public async createEmployee(employee: Employee): Promise<Employee>
    {
        const task = new PostEmployeeTask();
        task.employee = employee;

        return task.run();
    }

    /**
     * Updates a employee.
     *
     * @param employee
     */

    public async updateEmployee(employee: Employee): Promise<Employee>
    {
        const task = new PatchEmployeeTask();
        task.employee = employee;

        return task.run();
    }

    /**
     * Deletes an employee.
     *
     * @param id
     */

    public async deleteEmployee(id: number): Promise<void>
    {
        const task = new DeleteEmployeeTask();
        task.id = id;

        return task.run();
    }

    /**
     * Attempts to log an employee in with their employee number.
     *
     * @param employeeNumber
     */

    public async loginEmployee(employeeNumber: string): Promise<User|null>
    {
        const task = new LoginEmployeeTask();
        task.employeeNumber = employeeNumber;

        const user = await task.run();

        // Update the store with the user.
        if (user)
        {
            store.commit('app/user', user);
        }

        return user;
    }

    /**
     * Returns the points for a user.
     *
     * @param userId
     */

    public async getUserPoints(userId: number): Promise<EmployeePointsApiInterface|null>
    {
        const task = new GetEmployeePointsTask();
        task.userId = userId;

        return task.run();
    }

    /**
     * Returns the points history for the current user.
     *
     */

    public async getPointsHistory(): Promise<PointsHistoryApiInterface|null>
    {
        const task = new GetPointsHistoryTask();

        return task.run();
    }

    /**
     * Assigns an activity to an employee.
     *
     * @param activityId
     * @param userId
     * @param notes
     * @param pointsAwarded
     * @param pointsRedeemable
     */

    public async assignActivity(
        activityId: number,
        userId: number,
        notes: string,
        pointsAwarded: number,
        pointsRedeemable: number,
    ): Promise<void>
    {
        const task = new PostEmployeeActivityTask();
        task.activityId = activityId;
        task.userId = userId;
        task.notes = notes;
        task.pointsAwarded = pointsAwarded;
        task.pointsRedeemable = pointsRedeemable;

        return task.run();
    }

    /**
     * Assigns a reward to an employee.
     *
     * @param rewardId
     * @param userId
     * @param points
     */

    public async assignReward(
        rewardId: number,
        userId: number,
        points: number,
    ): Promise<void>
    {
        const task = new PostEmployeeRewardTask();
        task.rewardId = rewardId;
        task.userId = userId;
        task.points = points;

        return task.run();
    }

    /**
     * Returns the job levels.
     */

    public static getJobLevels(): {id: string, name: string}[]
    {
        return [
            { id: 'manager',
                name: 'Manager' },
            { id: 'operator',
                name: 'Operator' },
            { id: 'staff',
                name: 'Staff' },
            { id: 'supervisor',
                name: 'Supervisor' },
        ];
    }
}