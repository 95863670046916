import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Notification from '../../../model/entities/notifications/notification';

@Component({
    components:
    {},
})

export default class NotificationComponent extends Vue
{
    @Prop()
    private notification!: Notification;

    @Watch('notification.isRead')
    private readNotification()
    {
        this.$emit('read', this.notification.id);
    }
}