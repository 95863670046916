import AbstractValidator from '../abstract-validator';
import Reward from '../../entities/rewards/reward';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class RewardValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a reward.
     *
     * @param Reward
     */

    public validate(reward: Reward): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateName(reward, this.errors);
        this.validateDescription(reward, this.errors);
        this.validatePoints(reward, this.errors);

        return this.errors;
    }

    private validateName(reward: Reward, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(reward.name.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.name = errors;
        }
    }

    private validateDescription(reward: Reward, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(reward.description.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_description').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.description = errors;
        }
    }

    private validatePoints(reward: Reward, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (isNaN(reward.points) || reward.points <= 0)
        {
            errors.push(this.i18n.t('admin.rewards.validation.please_enter_points').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.points = errors;
        }
    }
}