import { Component, Vue, Watch } from 'vue-property-decorator';
import Category from '../../../model/entities/webscenes/category';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Scene from '../../../model/entities/webscenes/scene';
import SceneComponent from '@/components/widgets/scene/index.vue';

@Component({
    components:
    {
        'app-page-title': PageTitleComponent,
        'app-scene': SceneComponent,
    },
})
export default class ScenePageComponent extends Vue
{
    private category: Category|null = null;
    private scene: Scene|null = null;
    private isLoading = true;

    created()
    {
        this.onSceneChanged();
    }

    @Watch('$route.params.sceneRef')
    private async onSceneChanged()
    {
        this.isLoading = true;
        await this.loadCategory();
        await this.loadScene();
        this.isLoading = false;
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.safety_categories'),
                disabled: false,
                to: { name: 'category-index' },
                exact: true,
            },
            {
                text: this.categoryName,
                disabled: false,
                to: { name: 'category-view',
                    params: { id: this.scene ? this.$route.params.categoryId : 0 } },
                exact: true,
            },
            {
                text: this.titleName,
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the category for use in the breadcrumb.
     */

    get categoryName(): string
    {
        return this.category ? this.$et(this.category.name) : '';
    }

    /**
     * Returns the name of the scene for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.scene ? this.$et(this.scene.name) : '';
    }

    /**
     * Loads the category.
     */

    private async loadCategory()
    {
        const category = await this.$serviceContainer.getCategoryService().loadCategory(this.$route.params.categoryRef);
        this.category = Object.assign({}, category);
    }

    /**
     * Loads the scene.
     */

    private async loadScene()
    {
        const scene = await this.$serviceContainer.getSceneService().loadScene(this.$route.params.sceneRef);
        this.scene = Object.assign({}, scene);
    }
}