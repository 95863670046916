import { Component, Vue } from 'vue-property-decorator';
import Notification from '../../../model/entities/notifications/notification';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-translation': TranslationComponent,
    },
})
export default class LatestNotificationsComponent extends Vue
{
    private notifications: Notification[] = [];
    private isLoading = true;
    private isNoNotifications = false;

    created()
    {
        this.loadLatestNotification();
    }

    /**
     * Loads the latest notification.
     */

    private async loadLatestNotification()
    {
        this.isLoading = true;

        try
        {
            this.notifications = await this.$serviceContainer.getNotificationService().loadLatestNotifications();
            if (this.notifications.length === 0)
            {
                this.isNoNotifications = true;
            }
            else
            {
                this.isNoNotifications = false;
            }
        }
        catch (error)
        {
            switch (error)
            {
            case 'notification_not_found':
                this.isNoNotifications = true;
                break;
            }
        }

        this.isLoading = false;
    }
}