import AbstractValidator from '../abstract-validator';
import Banner from '../../entities/media/banner';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class BannerValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a banner.
     *
     * @param Banner
     */

    public validate(banner: Banner): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateHeadline(banner, this.errors);
        this.validateTitle(banner, this.errors);
        this.validateDescription(banner, this.errors);

        return this.errors;
    }

    private validateHeadline(banner: Banner, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(banner.headline.en))
        {
            errors.push(this.i18n.t('admin.bannners.validation.please_enter_a_headline').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.headline = errors;
        }
    }

    private validateTitle(banner: Banner, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(banner.title.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_title').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.title = errors;
        }
    }

    private validateDescription(banner: Banner, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(banner.description.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_description').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.description = errors;
        }
    }
}