import { Component, Vue } from 'vue-property-decorator';
import Category from '../../../../../model/entities/webscenes/category';
import CategoryFormComponent from '@/components/widgets/category-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import User from '@/model/entities/organisation/user';

@Component({
    components:
    {
        'app-category-form': CategoryFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminCategoryEditPageComponent extends Vue
{
    private category: Category|null = null;
    private isLoading = true;

    created()
    {
        this.loadCategory();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.categories.message.index_title'),
                disabled: false,
                to: { name: 'admin-category-index' },
                exact: true,
            },
            {
                text: this.$t('admin.categories.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the category for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.category ? this.$et(this.category.name) : this.$t('admin.categories.edit_title').toString();
    }

    /**
     * Loads the category.
     */

    private async loadCategory()
    {
        this.isLoading = true;
        const category = await this.$serviceContainer.getCategoryService().loadCategory(parseInt(this.$route.params.id));
        this.category = Object.assign({}, category);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param category
     */

    private async onSave(category: Category)
    {
        try
        {
            // Update the category.
            await this.$serviceContainer.getCategoryService().updateCategory(category);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.categories.message.category_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}