import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import User from '@/model/entities/organisation/user';
import UserService from '@/model/services/organisation/user-service';
import UserTransformer from '@/model/transformers/organisation/user-transformer';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes,
    scrollBehavior(
        to, from, savedPosition,
    )
    {
        if (savedPosition)
        {
            return savedPosition;
        }
        else
        {
            return {
                x: 0,
                y: 0,
            };
        }
    },
});

router.beforeEach((
    to, from, next,
) =>
{
    if (to.matched.some(record => record.meta.requiresAuth))
    {
        const apiUser = store.getters['app/user'];

        if (!apiUser)
        {
            next({
                name: 'home',
                params: { nextUrl: to.fullPath },
            });

            EventBus.instance.$emit(
                'notification', LocaleHelper.getI18n().t('authentication.message.please_login_to_access_content'), 'error',
            );
        }
        else if (to.matched.some(record =>
        {
            if (record.meta.roles)
            {
                const user = Object.assign(new User(), UserTransformer.fromApi(apiUser));

                return !UserService.userHasRole(user, record.meta.roles);
            }
            else
            {
                return false;
            }
        }))
        {
            next({
                name: 'access-denied',
                params: { nextUrl: to.fullPath },
            });
        }
        else
        {
            next();
        }
    }
    else
    {
        next();
    }
});

export default router;