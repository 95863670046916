import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Category from '../../../model/entities/webscenes/category';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Location from '../../../model/entities/organisation/location';
import Scene from '../../../model/entities/webscenes/scene';
import SceneQuestion from '../../../model/entities/webscenes/scene-question';
import SceneQuestionFormComponent from '@/components/widgets/scene-question-form/index.vue';
import SceneValidator from '../../../model/validator/webscenes/scene-validator';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-scene-question-form': SceneQuestionFormComponent,
    },
})
export default class SceneFormComponent extends Vue
{
    @Prop()
    private scene!: Scene;

    @Prop({ default: 'Save Scene' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formScene: Scene|null = null;
    private categories: Category[] = [];
    private locations: Location[] = [];

    private showQuestionDialog = false;
    private questionDialogQuestion: SceneQuestion|null = null;
    private questionDialogQuestionIndex: number|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formScene = Object.assign(new Scene(), this.scene);

        this.loadCategories();
        this.loadLocations();
    }

    @Watch('scene')
    private onSceneUpdated()
    {
        this.formScene = Object.assign(new Scene(), this.scene);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formScene)
        {
            return false;
        }

        const validator: SceneValidator = new SceneValidator();
        this.validationErrors = validator.validate(this.formScene);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the title for the question dialog.
     */

    get questionDialogTitle(): string
    {
        return this.questionDialogQuestion && this.questionDialogQuestion.id ? this.$et(this.questionDialogQuestion.text) : this.$t('admin.scenes.action.add_question').toString();
    }

    /**
     * Returns the URI for the image.
     */

    get imageUri(): string|null
    {
        if (!this.formScene)
        {
            return null;
        }

        return this.$serviceContainer.getSceneService().getSceneImageUri(this.formScene);
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Loads the categories.
     */

    private async loadCategories()
    {
        const results = await this.$serviceContainer.getCategoryService().searchCategories({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.categories = results.results;
        }
    }

    /**
     * Loads the available locations.
     */

    private async loadLocations()
    {
        const results = await this.$serviceContainer.getLocationService().searchLocations({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.locations = results.results;
        }
    }

    /**
     * Handles image file change.
     *
     * @param files
     */

    private onImageChange(files: FileList)
    {
        if (!this.formScene)
        {
            return;
        }

        this.formScene.imageFile = files[0];
    }

    /**
     * Handles the creation of a question.
     */

    private onCreateQuestion()
    {
        if (!this.formScene)
        {
            return;
        }

        // Create the new question.
        const question = new SceneQuestion();
        question.sceneId = this.scene.id;
        question.scene = this.scene;
        question.text.en = 'New Question';
        question.displayOrder = this.formScene.questions.length + 1;

        this.questionDialogQuestion = Object.assign({}, question);
        this.questionDialogQuestionIndex = null;
        this.showQuestionDialog = true;
    }

    /**
     * Handles editing of a question.
     *
     * @param index
     */

    private onEditQuestion(index: number)
    {
        if (!this.formScene)
        {
            return;
        }

        this.questionDialogQuestion = Object.assign({}, this.formScene.questions[index]);
        this.questionDialogQuestionIndex = index;
        this.showQuestionDialog = true;
    }

    /**
     * Handles the deletion of a question.
     *
     * @param index
     */

    private onDeleteQuestion(index: number)
    {
        if (!this.formScene)
        {
            return;
        }

        this.formScene.questions.splice(index, 1);
    }

    /**
     * Handles saving of a question.
     *
     * @param formQuestion
     * @param files
     */

    private onSaveQuestion(formQuestion: SceneQuestion, files: FileList)
    {
        if (!this.formScene)
        {
            return;
        }

        if (this.questionDialogQuestionIndex === null)
        {
            const question = Object.assign(new SceneQuestion(), formQuestion);
            this.formScene.questions.push(question);
        }
        else
        {
            Object.assign(this.formScene.questions[this.questionDialogQuestionIndex], formQuestion);
        }

        this.questionDialogQuestion = null;
        this.questionDialogQuestionIndex = null;
        this.showQuestionDialog = false;
    }

    /**
     * Handles cancelling of question adding / editing.
     */

    private onCancelQuestion()
    {
        this.questionDialogQuestion = null;
        this.questionDialogQuestionIndex = null;
        this.showQuestionDialog = false;
    }

    /**
     * Returns an iso string for a date.
     *
     * @param date
     */

    private getDateString(date: Date): string|null
    {
        return date ? format(date, 'yyyy-MM-dd HH:mm') : null;
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formScene)
        {
            throw new Error('Invalid scene properties');
        }

        const validator = new SceneValidator();
        const validationErrors = validator.validate(this.formScene);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid scene properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated scene.
            this.$emit('save', this.formScene);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}