import DeletePostTask from '../../tasks/api/blog/delete-post';
import GetActivePostsTask from '../../tasks/api/blog/get-active-posts';
import GetPostTask from '../../tasks/api/blog/get-post';
import PatchPostTask from '../../tasks/api/blog/patch-post';
import Post from '../../entities/blog/post';
import PostPostTask from '../../tasks/api/blog/post-post';
import PostResultsApiInterface from '../../vos/blog/post-results-api-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchPostsTask from '../../tasks/api/blog/search-posts';
import UploadPostFileTask from '../../tasks/api/blog/upload-post-file';

export default class PostService
{
    /**
     * Returns all the active posts.
     */

    public async getActivePosts(): Promise<Post[]>
    {
        const task = new GetActivePostsTask();

        return task.run();
    }

    /**
     * Returns posts matching the conditions.
     *
     * @param conditions
     */

    public async searchPosts(conditions: SearchConditionsInterface): Promise<PostResultsApiInterface|null>
    {
        const task = new SearchPostsTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a post.
     *
     * @param id
     */

    public async loadPost(id: number|string): Promise<Post|null>
    {
        const task = new GetPostTask();
        task.id = id;

        return task.run();
    }

    /**
     * Loads a post by slug.
     *
     * @param slug
     */

    public async loadPostBySlug(slug: string): Promise<Post|null>
    {
        const task = new GetPostTask();
        task.id = slug;

        return task.run();
    }

    /**
     * Creates a post.
     *
     * @param post
     */

    public async createPost(post: Post): Promise<Post>
    {
        const task = new PostPostTask();
        task.post = post;

        return task.run();
    }

    /**
     * Updates a post.
     *
     * @param post
     */

    public async updatePost(post: Post): Promise<Post>
    {
        const task = new PatchPostTask();
        task.post = post;

        return task.run();
    }

    /**
     * Deletes an post.
     *
     * @param id
     */

    public async deletePost(id: number): Promise<void>
    {
        const task = new DeletePostTask();
        task.id = id;

        return task.run();
    }

    /**
     * Uploads a post file.
     *
     * @param postId
     * @param field
     * @param file
     */

    public async uploadPostFile(
        postId: number,
        field: string,
        file: File,
    ): Promise<Post|null>
    {
        const task = new UploadPostFileTask();
        task.postId = postId;
        task.field = field;
        task.file = file;

        return task.run();
    }
}