import AbstractValidator from '../abstract-validator';
import ValidationErrorsInterface from '../validation-errors-interface';
import Video from '../../entities/media/video';

export default class VideoValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a video.
     *
     * @param Video
     */

    public validate(video: Video): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateName(video, this.errors);
        this.validateDescription(video, this.errors);
        this.validateUrl(video, this.errors);

        return this.errors;
    }

    private validateName(video: Video, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(video.title.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_title').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.title = errors;
        }
    }

    private validateDescription(video: Video, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(video.description.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_description').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.description = errors;
        }
    }

    private validateUrl(video: Video, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(video.url.en))
        {
            errors.push(this.i18n.t('admin.videos.validation.please_enter_a_url').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.url = errors;
        }
    }
}