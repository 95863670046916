import AbstractApiGetTask from '../abstract-api-get-task';
import Role from '@/model/entities/organisation/role';
import RoleApiInterface from '@/model/entities/organisation/role-api-interface';
import RoleTransformer from '@/model/transformers/organisation/role-transformer';

export default class GetRolesTask extends AbstractApiGetTask
{
    public async run(): Promise<Role[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiRoles: RoleApiInterface[] = response.data.data;

                const roles: Role[] = [];
                for (const apiRole of apiRoles)
                {
                    try
                    {
                        // Parse the category.
                        roles.push(RoleTransformer.fromApi(apiRole));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Roles');
                        console.error(error);
                    }
                }

                return roles;
            }
            else
            {
                this.handleError(response);
            }

            return [];
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/roles';
    }
}