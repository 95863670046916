import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import Location from '../../../model/entities/organisation/location';
import Team from '../../../model/entities/organisation/team';
import TeamValidator from '../../../model/validator/organisation/team-validator';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class TeamFormComponent extends Vue
{
    @Prop()
    private team!: Team;

    @Prop({ default: 'Save Team' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formTeam: Team|null = null;
    private locations: Location[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formTeam = Object.assign(new Team(), this.team);

        this.loadLocations();
    }

    @Watch('team')
    private onTeamUpdated()
    {
        this.formTeam = Object.assign(new Team(), this.team);
    }

    @Watch('formTeam.location')
    private onLocationUpdated()
    {
        if (!this.formTeam)
        {
            return;
        }

        this.formTeam.locationId = this.formTeam.location ? this.formTeam.location.id : null;
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formTeam)
        {
            return false;
        }

        const validator: TeamValidator = new TeamValidator();
        this.validationErrors = validator.validate(this.formTeam);

        return size(this.validationErrors) === 0;
    }

    /**
     * Loads the available locations.
     */

    private async loadLocations()
    {
        const results = await this.$serviceContainer.getLocationService().searchLocations({ limit: 1000,
            sortBy: 'name' });

        if (results)
        {
            this.locations = results.results;
        }
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formTeam)
        {
            throw new Error('Invalid team properties');
        }

        const validator = new TeamValidator();
        const validationErrors = validator.validate(this.formTeam);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid team properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated team.
            this.$emit('save', this.formTeam);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}