import TranslationsInterface from '@/model/vos/translations/translations-interface';
import User from '@/model/entities/organisation/user';

export default class Notification
{
    id = 0;
    title: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    message: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    uri: string|null = null;
    users: User[] = [];
    isEnabled = false;
    isRead = false;
    created: Date = new Date();
    updated: Date = new Date();
}