/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
    namespaced: true,
    state: (): any => ({
        locale: 'en',
        navbarVisible: true,
        pageLocked: false,
    }),
    mutations: {
        locale(state: any, locale: string): void
        {
            state.locale = locale;
        },
        navbarVisible(state: any, navbarVisible: boolean): void
        {
            state.navbarVisible = navbarVisible;
        },
        pageLocked(state: any, pageLocked: boolean): void
        {
            state.pageLocked = pageLocked;
        },
    },
    actions: {},
    getters: {
        locale(state: any): string
        {
            return state.locale;
        },
        navbarVisible(state: any): boolean
        {
            return state.navbarVisible;
        },
        pageLocked(state: any): boolean
        {
            return state.pageLocked;
        },
    },
};