import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import SafetyAmbassador from '../../../entities/organisation/safety-ambassador';
import SafetyAmbassadorApiInterface from '@/model/entities/organisation/safety-ambassador-api-interface';
import SafetyAmbassadorTransformer from '../../../transformers/organisation/safety-ambassador-transformer';

export default class PostSafetyAmbassadorTask extends AbstractApiPostTask
{
    private _safetyAmbassador!: SafetyAmbassador;

    public async run(): Promise<SafetyAmbassador>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return SafetyAmbassadorTransformer.fromApi(response.data.data, response.data.data.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._safetyAmbassador;
    }

    set safetyAmbassador(safetyAmbassador: SafetyAmbassador)
    {
        this._safetyAmbassador = safetyAmbassador;
    }

    protected getUrl(): string
    {
        return '/safety-ambassadors';
    }

    protected getData(): SafetyAmbassadorApiInterface
    {
        return SafetyAmbassadorTransformer.toApi(this._safetyAmbassador);
    }
}