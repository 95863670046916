import AbstractValidator from '../abstract-validator';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class LoginFormValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a login form.
     *
     * @param email
     * @param password
     */

    public validate(email: string, password: string): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateEmail(email, this.errors);
        this.validatePassword(password, this.errors);

        return this.errors;
    }

    private validateEmail(email: string, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(email))
        {
            errors.push('Please enter your email address');
        }

        if (errors.length > 0)
        {
            validationErrors.email = errors;
        }
    }

    private validatePassword(password: string, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(password))
        {
            errors.push('Please enter your password');
        }

        if (errors.length > 0)
        {
            validationErrors.password = errors;
        }
    }
}