import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Scene from '../../../../../model/entities/webscenes/scene';
import SceneResultsApiInterface from '../../../../../model/vos/webscenes/scene-results-api-interface';
import SceneSearchConditionsInterface from '../../../../../model/vos/webscenes/scene-search-conditions-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminSceneIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.scenes.message.index_title'),
                disabled: true,
                to: { name: 'admin-scene-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.id'),
                align: 'left',
                value: 'id',
            },
            {
                text: this.$t('general.field.name'),
                align: 'left',
                value: 'name',
            },
            {
                text: this.$t('general.field.location'),
                align: 'left',
                value: 'location.name',
            },
            {
                text: this.$t('general.field.created'),
                align: 'left',
                value: 'created',
            },
            {
                text: this.$t('general.field.updated'),
                align: 'left',
                value: 'updated',
            },
            {
                text: this.$t('general.field.enabled'),
                align: 'left',
                value: 'enabled',
            },
        ];
    }

    private results: SceneResultsApiInterface = {
        results: [],
        total_results: 0,
        results_per_page: 50,
        total_pages: 0,
        page: 1,
        sort_by: 'name',
        descending: false,
    };

    private isLoading = true;

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: SearchConditionsInterface)
    {
        const sceneConditions: SceneSearchConditionsInterface = Object.assign({}, conditions);

        this.isLoading = true;

        const results = await this.$serviceContainer.getSceneService().searchScenes(sceneConditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-scene-create' });
    }

    /**
     * Handles opening of scenes.
     *
     * @param scene
     */

    private onOpenScene(scene: Scene)
    {
        NavigationHelper.pushRoute({
            name: 'admin-scene-edit',
            params: {
                sceneId: scene.id.toString(),
            },
        });
    }
}