import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Banner from '../../../entities/media/banner';
import BannerApiInterface from '../../../entities/media/banner-api-interface';
import BannerTransformer from '../../../transformers/media/banner-transformer';

export default class GetActiveBannersTask extends AbstractApiGetTask
{
    public async run(): Promise<Banner[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiBanners: BannerApiInterface[] = response.data.data;

                const banners: Banner[] = [];
                for (const apiBanner of apiBanners)
                {
                    try
                    {
                        // Parse the banner.
                        banners.push(BannerTransformer.fromApi(apiBanner));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Banner');
                        console.error(error);
                    }
                }

                return banners;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/banners/active';
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}