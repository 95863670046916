import { Component, Vue } from 'vue-property-decorator';
import { size } from 'lodash';
import RequestResetPasswordFormValidator from '@/model/validator/authentication/request-reset-password-form-validator';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({})
export default class RequestResetPasswordFormComponent extends Vue
{
    private email = '';

    private validationErrors: ValidationErrorsInterface = {};

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        const validator: RequestResetPasswordFormValidator = new RequestResetPasswordFormValidator();
        this.validationErrors = validator.validate(this.email);

        return size(this.validationErrors) === 0;
    }

    /**
     * Handles submissions.
     */

    onSubmit()
    {
        if (!this.isValid)
        {
            return;
        }

        this.$emit('submitted', this.email);
    }
}