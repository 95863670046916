import { Component, Vue } from 'vue-property-decorator';
import { parseISO } from 'date-fns';
import DividerComponent from '@/components/widgets/divider/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import PointsHistoryApiInterface from '../../../model/vos/organisation/points-history-api-interface';

@Component({
    components:
    {
        'app-divider': DividerComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class PointsPageComponent extends Vue
{
    private isLoading = true;
    private isNotEmployee = false;
    private history: PointsHistoryApiInterface|null = null;

    async created()
    {
        this.isLoading = true;
        await this.loadPointsHistory();
        this.isLoading = false;
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('points.message.points'),
                disabled: false,
                to: { name: 'my-points' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            { text: this.$t('leaderboard.field.activity'),
                align: 'left',
                value: 'activity',
                sortable: false },
            { text: this.$t('leaderboard.field.points_awarded'),
                align: 'center',
                value: 'points_awarded',
                sortable: false },
            { text: this.$t('leaderboard.field.redeemable_points'),
                align: 'center',
                value: 'points_redeemable',
                sortable: false },
            { text: this.$t('general.field.date'),
                align: 'center',
                value: 'created',
                sortable: false },
        ];
    }

    /**
     * Loads the points history.
     */

    private async loadPointsHistory()
    {
        try
        {
            const history = await this.$serviceContainer.getEmployeeService().getPointsHistory();
            this.history = Object.assign({}, history);
        }
        catch (error)
        {
            switch (error)
            {
            case 'employee_not_found':
                this.isNotEmployee = true;
                break;
            }
        }
    }

    private getDate(date: string)
    {
        return parseISO(date);
    }
}