import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Newsletter from '../../../entities/media/newsletter';
import NewsletterApiInterface from '../../../entities/media/newsletter-api-interface';
import NewsletterTransformer from '../../../transformers/media/newsletter-transformer';

export default class GetNewsletterTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<Newsletter|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiNewsletter: NewsletterApiInterface = response.data.data;

                return NewsletterTransformer.fromApi(apiNewsletter);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/newsletters/${ this._id }`;
    }
}