import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Video from '../../../../../model/entities/media/video';
import VideoFormComponent from '../../../../widgets/video-form/index.vue';

@Component({
    components:
    {
        'app-video-form': VideoFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminVideoEditPageComponent extends Vue
{
    private video: Video|null = null;
    private isLoading = true;

    created()
    {
        this.loadVideo();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.videos.message.index_title'),
                disabled: false,
                to: { name: 'admin-video-index' },
                exact: true,
            },
            {
                text: this.$t('admin.videos.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the title of the video for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.video ? this.$et(this.video.title) : this.$t('admin.videos.edit_title').toString();
    }

    /**
     * Loads the video.
     */

    private async loadVideo()
    {
        this.isLoading = true;
        const video = await this.$serviceContainer.getVideoService().loadVideo(parseInt(this.$route.params.id));
        this.video = Object.assign({}, video);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param video
     */

    private async onSave(video: Video)
    {
        try
        {
            // Update the video.
            const updated = await this.$serviceContainer.getVideoService().updateVideo(video);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.videos.message.video_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}