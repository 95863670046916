import Banner from '../../entities/media/banner';
import BannerResultsApiInterface from '../../vos/media/banner-results-api-interface';
import DeleteBannerTask from '../../tasks/api/media/delete-banner';
import GetActiveBannersTask from '../../tasks/api/media/get-active-banners';
import GetBannerTask from '../../tasks/api/media/get-banner';
import PatchBannerTask from '../../tasks/api/media/patch-banner';
import PostBannerTask from '../../tasks/api/media/post-banner';
import SearchBannersTask from '../../tasks/api/media/search-banners';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import UploadBannerFileTask from '../../tasks/api/media/upload-banner-file';

export default class BannerService
{
    /**
     * Returns all the active banners.
     */

    public async getActiveBanners(): Promise<Banner[]>
    {
        const task = new GetActiveBannersTask();

        return task.run();
    }

    /**
     * Returns banners matching the conditions.
     *
     * @param conditions
     */

    public async searchBanners(conditions: SearchConditionsInterface): Promise<BannerResultsApiInterface|null>
    {
        const task = new SearchBannersTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a banner.
     *
     * @param id
     */

    public async loadBanner(id: number|string): Promise<Banner|null>
    {
        const task = new GetBannerTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a banner.
     *
     * @param banner
     */

    public async createBanner(banner: Banner): Promise<Banner>
    {
        const task = new PostBannerTask();
        task.banner = banner;

        return task.run();
    }

    /**
     * Updates a banner.
     *
     * @param banner
     */

    public async updateBanner(banner: Banner): Promise<Banner>
    {
        const task = new PatchBannerTask();
        task.banner = banner;

        return task.run();
    }

    /**
     * Deletes an banner.
     *
     * @param id
     */

    public async deleteBanner(id: number): Promise<void>
    {
        const task = new DeleteBannerTask();
        task.id = id;

        return task.run();
    }

    /**
     * Uploads a banner file.
     *
     * @param bannerId
     * @param field
     * @param file
     */

    public async uploadBannerFile(
        bannerId: number,
        field: string,
        file: File,
    ): Promise<Banner|null>
    {
        const task = new UploadBannerFileTask();
        task.bannerId = bannerId;
        task.field = field;
        task.file = file;

        return task.run();
    }
}