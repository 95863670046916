import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import SafetyAmbassador from '../../../entities/organisation/safety-ambassador';
import SafetyAmbassadorApiInterface from '../../../entities/organisation/safety-ambassador-api-interface';
import SafetyAmbassadorTransformer from '../../../transformers/organisation/safety-ambassador-transformer';

export default class GetActiveSafetyAmbassadorsTask extends AbstractApiGetTask
{
    public async run(): Promise<SafetyAmbassador[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiSafetyAmbassadors: SafetyAmbassadorApiInterface[] = response.data.data;

                const safetyAmbassadors: SafetyAmbassador[] = [];
                for (const apiSafetyAmbassador of apiSafetyAmbassadors)
                {
                    try
                    {
                        // Parse the safety ambassador.
                        safetyAmbassadors.push(SafetyAmbassadorTransformer.fromApi(apiSafetyAmbassador, apiSafetyAmbassador.associations));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse SafetyAmbassador');
                        console.error(error);
                    }
                }

                return safetyAmbassadors;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/safety-ambassadors/active';
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}