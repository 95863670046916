import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import SceneQuestion from '../../../model/entities/webscenes/scene-question';
import SceneQuestionAnswer from '../../../model/entities/webscenes/scene-question-answer';
import SceneQuestionAnswerFormComponent from '@/components/widgets/scene-question-answer-form/index.vue';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-scene-question-answer-form': SceneQuestionAnswerFormComponent,
    },
})
export default class SceneQuestionFormComponent extends Vue
{
    @Prop()
    private question!: SceneQuestion;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formQuestion: SceneQuestion|null = null;

    private showAnswerDialog = false;
    private answerDialogAnswer: SceneQuestionAnswer|null = null;
    private answerDialogAnswerIndex: number|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formQuestion = Object.assign(new SceneQuestion(), this.question);
    }

    @Watch('question')
    private onQuestionUpdated()
    {
        this.formQuestion = Object.assign(new SceneQuestion(), this.question);
    }

    /**
     * Returns the title for the answer dialog.
     */

    get answerDialogTitle(): string
    {
        return this.answerDialogAnswer && this.answerDialogAnswer.id ? this.$et(this.answerDialogAnswer.text) : this.$t('admin.scenes.action.add_answer').toString();
    }

    /**
     * Returns the URI for the image.
     */

    get imageUri(): string|null
    {
        if (!this.formQuestion)
        {
            return null;
        }

        return this.$serviceContainer.getSceneService().getSceneQuestionImageUri(this.formQuestion);
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Handles image file change.
     *
     * @param files
     */

    private onImageChange(files: FileList)
    {
        if (!this.formQuestion)
        {
            return;
        }

        this.formQuestion.imageFile = files[0];
    }

    /**
     * Handles the creation of an answer.
     */

    private onCreateAnswer()
    {
        if (!this.formQuestion)
        {
            return;
        }

        // Create the new answer.
        const answer = new SceneQuestionAnswer();
        answer.questionId = this.formQuestion.id;
        answer.question = this.formQuestion;
        answer.text.en = 'New Answer';
        answer.displayOrder = this.formQuestion.answers.length + 1;

        this.answerDialogAnswer = Object.assign({}, answer);
        this.answerDialogAnswerIndex = null;
        this.showAnswerDialog = true;
    }

    /**
     * Handles editing of an answer.
     *
     * @param answer
     */

    private onEditAnswer(index: number)
    {
        if (!this.formQuestion)
        {
            return;
        }

        this.answerDialogAnswer = Object.assign({}, this.formQuestion.answers[index]);
        this.answerDialogAnswerIndex = index;
        this.showAnswerDialog = true;
    }

    /**
     * Handles the deletion of an answer.
     *
     * @param index
     */

    private onDeleteAnswer(index: number)
    {
        if (!this.formQuestion)
        {
            return;
        }

        this.formQuestion.answers.splice(index, 1);
    }

    /**
     * Handles saving of an answer.
     *
     * @param formAnswer
     * @param files
     */

    private onSaveAnswer(formAnswer: SceneQuestionAnswer, files: FileList)
    {
        if (!this.formQuestion)
        {
            return;
        }

        if (this.answerDialogAnswerIndex === null)
        {
            const answer = Object.assign(new SceneQuestionAnswer(), formAnswer);
            this.formQuestion.answers.push(answer);
        }
        else
        {
            Object.assign(this.formQuestion.answers[this.answerDialogAnswerIndex], formAnswer);
        }

        this.answerDialogAnswer = null;
        this.answerDialogAnswerIndex = null;
        this.showAnswerDialog = false;
    }

    /**
     * Handles cancelling of answer adding / editing.
     */

    private onCancelAnswer()
    {
        this.answerDialogAnswer = null;
        this.answerDialogAnswerIndex = null;
        this.showAnswerDialog = false;
    }

    /**
     * Handles save requests.
     */

    private onSave()
    {
        this.$emit('save', this.formQuestion);
    }

    /**
     * Handles cancel requests.
     */

    private onCancel()
    {
        this.$emit('cancel');
    }
}