import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';

export default class GetTagsTask extends AbstractApiGetTask
{
    private _searchTerm!: string;

    public async run(): Promise<string[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return response.data.data;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
    }

    protected getUrl(): string
    {
        return '/tags';
    }

    protected getParams(): any
    {
        return {
            search_term: this._searchTerm,
        };
    }
}