import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Video from '../../entities/media/video';
import VideoApiInterface from '../../entities/media/video-api-interface';

export default class VideoTransformer
{
    /**
     * Transforms an API video representation into a Video entity.
     *
     * @param apiVideo
     */

    public static fromApi(apiVideo: VideoApiInterface, associations?: AssociationsApiInterface): Video
    {
        const video = new Video();
        video.id = apiVideo.id;
        video.title = apiVideo.title;
        video.description = apiVideo.description;
        video.url = apiVideo.url;
        video.displayOrder = apiVideo.display_order;
        video.isEnabled = apiVideo.enabled;
        video.created = parseISO(apiVideo.created);
        video.updated = parseISO(apiVideo.updated);

        return video;
    }

    /**
     * Transforms a Video entity into an API representation of a video.
     *
     * @param video
     */

    public static toApi(video: Video): VideoApiInterface
    {
        return {
            id: video.id,
            title: video.title,
            description: video.description,
            url: video.url,
            display_order: video.displayOrder,
            enabled: video.isEnabled,
            created: formatISO(video.created),
            updated: formatISO(video.updated),
        };
    }
}