import AbstractApiPostTask from '../abstract-api-post-task';

export default class UploadFileTask extends AbstractApiPostTask
{
    private _path!: string;
    private _name = 'file';
    private _file!: File;
    private _data!: FormData;
    private _uploadProgressCallback: ((progressEvent: ProgressEvent) => void)|null = null;

    public async run(): Promise<void>
    {
        try
        {
            const response = await this.request(typeof this._uploadProgressCallback === 'function');

            if (!this.isSuccessful(response))
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    set path(path: string)
    {
        this._path = path;
    }

    set name(name: string)
    {
        this._name = name;
    }

    set file(file: File)
    {
        this._file = file;
    }

    set data(data: FormData)
    {
        this._data = data;
    }

    set uploadProgressCallback(uploadProgressCallback: (progressEvent: ProgressEvent) => void)
    {
        this._uploadProgressCallback = uploadProgressCallback;
    }

    protected getUrl(): string
    {
        return this._path;
    }

    protected getData(): FormData
    {
        if (!this._data)
        {
            this._data = new FormData();
        }

        this._data.append(this._name, this._file);

        return this._data;
    }

    protected onUploadProgress(progressEvent: ProgressEvent)
    {
        if (!this._uploadProgressCallback)
        {
            return;
        }

        this._uploadProgressCallback(progressEvent);
    }
}