import AssociationsApiInterface from '../../entities/associations-api-interface';
import SceneQuestionAnswer from '../../entities/webscenes/scene-question-answer';
import SceneQuestionAnswerApiInterface from '../../entities/webscenes/scene-question-answer-api-interface';

export default class SceneQuestionAnswerTransformer
{
    /**
     * Transforms an API scene answer representation into a SceneQuestionAnswer entity.
     *
     * @param apiScene
     */

    public static fromApi(apiAnswer: SceneQuestionAnswerApiInterface, associations?: AssociationsApiInterface): SceneQuestionAnswer
    {
        const answer = new SceneQuestionAnswer();
        answer.id = apiAnswer.id;
        answer.questionId = apiAnswer.question_id;
        answer.text = apiAnswer.text;
        answer.selectionText = apiAnswer.selection_text;
        answer.image = apiAnswer.image;
        answer.displayOrder = apiAnswer.display_order;
        answer.isCorrect = apiAnswer.correct;
        answer.isEnabled = apiAnswer.enabled;

        return answer;
    }

    /**
     * Transforms a SceneQuestionAnswer entity into an API representation of a scene answer.
     *
     * @param answer
     */

    public static toApi(answer: SceneQuestionAnswer): SceneQuestionAnswerApiInterface
    {
        return {
            id: answer.id,
            question_id: answer.questionId,
            text: answer.text,
            selection_text: answer.selectionText,
            image: answer.image,
            display_order: answer.displayOrder,
            correct: answer.isCorrect,
            enabled: answer.isEnabled,
        };
    }
}