import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import SafetyAmbassador from '../../../../../model/entities/organisation/safety-ambassador';
import SafetyAmbassadorFileInterface from '../../../../../model/vos/organisation/safety-ambassador-file-interface';
import SafetyAmbassadorFormComponent from '@/components/widgets/safety-ambassador-form/index.vue';

@Component({
    components:
    {
        'app-safety-ambassador-form': SafetyAmbassadorFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminSafetyAmbassadorEditPageComponent extends Vue
{
    private safetyAmbassador: SafetyAmbassador|null = null;
    private isLoading = true;

    created()
    {
        this.loadSafetyAmbassador();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.safety_ambassadors.message.index_title'),
                disabled: false,
                to: { name: 'admin-safety-ambassador-index' },
                exact: true,
            },
            {
                text: this.$t('admin.safety_ambassadors.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the title of the safetyAmbassador for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.safetyAmbassador ? `${ this.safetyAmbassador.firstName } ${ this.safetyAmbassador.lastName }` : this.$t('admin.safety_ambassadors.edit_title').toString();
    }

    /**
     * Loads the safety ambassador.
     */

    private async loadSafetyAmbassador()
    {
        this.isLoading = true;
        const safetyAmbassador = await this.$serviceContainer.getSafetyAmbassadorService().loadSafetyAmbassador(parseInt(this.$route.params.id));
        this.safetyAmbassador = Object.assign({}, safetyAmbassador);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param safetyAmbassador
     * @param files
     */

    private async onSave(safetyAmbassador: SafetyAmbassador, files: SafetyAmbassadorFileInterface[])
    {
        try
        {
            // Update the safetyAmbassador.
            let updated = await this.$serviceContainer.getSafetyAmbassadorService().updateSafetyAmbassador(safetyAmbassador);

            // Upload files.
            if (files.length > 0)
            {
                updated = await this.uploadFiles(updated, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.safety_ambassadors.message.safety_ambassador_updated'));

            this.safetyAmbassador = Object.assign(new SafetyAmbassador(), updated);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads safetyAmbassador files.
     *
     * @param safetyAmbassador
     * @param files
     */

    private async uploadFiles(safetyAmbassador: SafetyAmbassador, files: SafetyAmbassadorFileInterface[]): Promise<SafetyAmbassador>
    {
        let updated: SafetyAmbassador = safetyAmbassador;

        for (const file of files)
        {
            const returned = await this.$serviceContainer.getSafetyAmbassadorService().uploadSafetyAmbassadorFile(
                safetyAmbassador.id,
                file.field,
                file.file,
            );

            if (returned)
            {
                updated = returned;
            }
        }

        return updated;
    }
}