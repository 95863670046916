import { Component, Vue } from 'vue-property-decorator';
import Scene from '../../../model/entities/webscenes/scene';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-translation': TranslationComponent,
    },
})
export default class IncompleteScenesComponent extends Vue
{
    private scenes: Scene[] = [];

    created()
    {
        this.loadScenes();
    }

    /**
     * Loads the latest scenes.
     */

    private async loadScenes()
    {
        try
        {
            this.scenes = await this.$serviceContainer.getSceneService().getIncompleteScenes();
        }
        catch (error)
        {
            //
        }
    }
}