/* eslint-disable @typescript-eslint/no-explicit-any */
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import store from '@/store';

export default abstract class AbstractApiTask
{
    public static RESULT_SUCCESS = 'success';
    public static RESULT_ERROR = 'error';

    private static BASE_URL = process.env.VUE_APP_API_BASE_URL;

    /**
     * Runs the task.
     */

    public abstract run(): Promise<unknown>;

    /**
     * Makes the request and returns an AxiosResponse.
     *
     * @param monitorUploadProgress
     */

    protected async request(monitorUploadProgress = false): Promise<AxiosResponse>
    {
        // Generate an axios instance.
        const request = axios.create({
            baseURL: AbstractApiTask.BASE_URL,
            headers: this.getHeaders(),
        });

        const config: AxiosRequestConfig = {
            method: this.getMethod(),
            url: this.getUrl(),
            params: this.getParams(),
            data: this.getData(),
        };

        if (monitorUploadProgress)
        {
            config.onUploadProgress = (progressEvent: ProgressEvent) => this.onUploadProgress(progressEvent);
        }

        return request.request(config);
    }

    /**
     * Returns the headers for the request.
     */

    protected getHeaders(): any
    {
        const headers: any = {};

        // Add the API token if supplied.
        if (this.getAuthenticated())
        {
            headers.Authorization = `APIToken ${ this.getApiToken() }`;
        }

        return headers;
    }

    /**
     * Returns whether the request should be authenticated.
     *
     * @returns
     */

    protected getAuthenticated(): boolean
    {
        return true;
    }

    /**
     * Returns the API token
     *
     * @returns
     */

    protected getApiToken(): string
    {
        return store.getters['app/user']?.token ?? '';
    }

    /**
     * Returns the request method.
     */

    protected abstract getMethod(): Method;

    /**
     * Returns the request URL.
     */

    protected abstract getUrl(): string;

    /**
     * Returns the URL params.
     */

    protected getParams(): any|URLSearchParams
    {
        return {};
    }

    /**
     * Returns the request data.
     */

    protected getData(): any|ArrayBuffer|Blob|File|FormData|URLSearchParams
    {
        return {};
    }

    /**
     * Returns whether the response is successful.
     *
     * @param response
     */

    protected isSuccessful(response: AxiosResponse): boolean
    {
        return response.status === 200 && response.data.result === AbstractApiTask.RESULT_SUCCESS;
    }

    /**
     * Handles response errors.
     *
     * @param response
     */

    protected handleError(response: AxiosResponse)
    {
        if (!response || !response.data || !response.data.error || !response.data.error.code)
        {
            throw new Error('unknown_error');
        }

        switch (response.data.error.code)
        {
        // Emit an event in the case of authentication failures or access denied errors.
        case 'access_denied':
            EventBus.instance.$emit('access-denied');
            break;

        case 'authentication_failed':
        case 'authentication_required':
            EventBus.instance.$emit('not-authenticated');
            break;

        default:
            throw new Error(response.data.error.code);
        }
    }

    /**
     * Callback for upload progress.
     *
     * @param progressEvent
     */

    protected onUploadProgress(progressEvent: ProgressEvent)
    {
        //
    }
}