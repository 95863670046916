import Activity from '../../entities/rewards/activity';
import ActivityApiInterface from '../../entities/rewards/activity-api-interface';
import AssociationsApiInterface from '../../entities/associations-api-interface';

export default class ActivityTransformer
{
    /**
     * Transforms an API activity representation into a Activity entity.
     *
     * @param apiActivity
     */

    public static fromApi(apiActivity: ActivityApiInterface, associations?: AssociationsApiInterface): Activity
    {
        const activity = new Activity();
        activity.id = apiActivity.id;
        activity.name = apiActivity.name;
        activity.description = apiActivity.description;
        activity.points = apiActivity.points;
        activity.isEnabled = apiActivity.enabled;

        return activity;
    }

    /**
     * Transforms a Activity entity into an API representation of a activity.
     *
     * @param activity
     */

    public static toApi(activity: Activity): ActivityApiInterface
    {
        return {
            id: activity.id,
            name: activity.name,
            description: activity.description,
            points: activity.points,
            enabled: activity.isEnabled,
        };
    }
}