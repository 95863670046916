import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Reward
{
    id = 0;
    name: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    image: string|null = null;
    imageFile: File|null = null;
    points = 0;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
}