import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import Category from '../../entities/webscenes/category';
import CategoryApiInterface from '../../entities/webscenes/category-api-interface';

export default class CategoryTransformer
{
    /**
     * Transforms an API category representation into a Category entity.
     *
     * @param apiCategory
     */

    public static fromApi(apiCategory: CategoryApiInterface, associations?: AssociationsApiInterface): Category
    {
        const category = new Category();
        category.id = apiCategory.id;
        category.ref = apiCategory.ref;
        category.name = apiCategory.name;
        category.description = apiCategory.description;
        category.image = apiCategory.image;
        category.hasNewScenes = apiCategory.has_new_scenes;
        category.isEnabled = apiCategory.enabled;
        category.created = parseISO(apiCategory.created);
        category.updated = parseISO(apiCategory.updated);
        category.totalScenes = apiCategory.total_scenes ?? 0;

        return category;
    }

    /**
     * Transforms a Category entity into an API representation of a category.
     *
     * @param category
     */

    public static toApi(category: Category): CategoryApiInterface
    {
        return {
            id: category.id,
            ref: category.ref,
            name: category.name,
            description: category.description,
            image: category.image,
            has_new_scenes: category.hasNewScenes,
            enabled: category.isEnabled,
            created: formatISO(category.created),
            updated: formatISO(category.updated),
            total_scenes: category.totalScenes,
        };
    }
}