import AbstractValidator from '../abstract-validator';
import Post from '../../entities/blog/post';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class PostValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a post.
     *
     * @param Post
     */

    public validate(post: Post): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateTitle(post, this.errors);
        this.validateBody(post, this.errors);

        return this.errors;
    }

    private validateTitle(post: Post, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(post.title.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_title').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.title = errors;
        }
    }

    private validateBody(post: Post, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(post.body.en))
        {
            errors.push(this.i18n.t('admin.posts.validation.please_enter_a_body').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.body = errors;
        }
    }
}