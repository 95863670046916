import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Category from '../../../entities/webscenes/category';
import CategoryApiInterface from '../../../entities/webscenes/category-api-interface';
import CategoryResultsApiInterface from '../../../vos/webscenes/category-results-api-interface';
import CategoryTransformer from '../../../transformers/webscenes/category-transformer';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';

export default class SearchCategoriesTask extends AbstractApiGetTask
{
    private _conditions!: SearchConditionsInterface;

    public async run(): Promise<CategoryResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiCategories: CategoryApiInterface[] = response.data.data.results;

                const categories: Category[] = [];
                for (const apiCategory of apiCategories)
                {
                    try
                    {
                        // Parse the category.
                        categories.push(CategoryTransformer.fromApi(apiCategory));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Category');
                        console.error(error);
                    }
                }

                return {
                    results: categories,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: SearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/categories';
    }

    protected getParams(): any
    {
        const params: any = {};

        if (this._conditions.searchTerm)
        {
            params.search_term = this._conditions.searchTerm;
        }

        if (this._conditions.limit)
        {
            params.limit = this._conditions.limit;
        }

        if (this._conditions.page)
        {
            params.page = this._conditions.page;
        }

        if (this._conditions.sortBy)
        {
            params.sort_by = this._conditions.sortBy;
        }

        if (this._conditions.descending)
        {
            params.desc = this._conditions.descending ? '1' : '0';
        }

        return params;
    }
}