import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Employee from '../../../../../model/entities/organisation/employee';
import EmployeeFiltersComponent from '@/components/widgets/employee-filters/index.vue';
import EmployeeResultsApiInterface from '../../../../../model/vos/organisation/employee-results-api-interface';
import EmployeeSearchConditionsInterface from '../../../../../model/vos/organisation/employee-search-conditions-interface';
import EmployeeSearchConditionsService from '../../../../../model/services/organisation/employee-search-conditions-service';
import Location from '../../../../../model/entities/organisation/location';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Team from '../../../../../model/entities/organisation/team';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-employee-filters': EmployeeFiltersComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminEmployeeIndexPageComponent extends Vue
{
    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.employees.message.index_title'),
                disabled: true,
                to: { name: 'admin-employee-index' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            { text: this.$t('employees.field.employee_number'),
                align: 'left',
                value: 'employee_number' },
            { text: this.$t('general.field.name'),
                align: 'left',
                value: 'name' },
            { text: this.$t('general.field.location'),
                align: 'left',
                value: 'location' },
            { text: this.$t('employees.field.job_level'),
                align: 'left',
                value: 'job_level' },
        ];
    }

    private results: EmployeeResultsApiInterface =
        {
            results: [],
            total_results: 0,
            results_per_page: 50,
            total_pages: 0,
            page: 1,
            sort_by: 'name',
            descending: false,
        };

    private conditions: EmployeeSearchConditionsInterface = {};
    private filters: EmployeeSearchConditionsInterface = {};
    private searchConditionsService!: EmployeeSearchConditionsService;

    private isInitialising = true;
    private isLoading = true;

    private locations: Location[] = [];
    private teams: Team[] = [];

    async created()
    {
        this.isInitialising = true;

        this.searchConditionsService = new EmployeeSearchConditionsService();
        this.filters = this.searchConditionsService.getConditionsFromQueryParams(
            this.$route, 50, 'first_name',
        );

        await this.loadLocations();
        await this.loadTeams();

        this.isInitialising = false;
    }

    /**
     * Loads the results.
     *
     * @param conditions
     */

    private async loadResults(conditions: EmployeeSearchConditionsInterface)
    {
        this.isLoading = true;

        // Store the conditions.
        this.conditions = conditions;

        // Run the search.
        const results = await this.$serviceContainer.getEmployeeService().searchEmployees(this.conditions);
        if (results)
        {
            this.results = Object.assign({}, results);
        }

        this.isLoading = false;
    }

    /**
     * Loads the locations.
     */

    private async loadLocations()
    {
        const results = await this.$serviceContainer.getLocationService().searchLocations({
            limit: 100,
            sortBy: 'name',
        });

        if (results)
        {
            this.locations = results.results;
        }
    }

    /**
     * Loads the teams.
     */

    private async loadTeams()
    {
        const results = await this.$serviceContainer.getTeamService().searchTeams({
            limit: 1000,
            sortBy: 'name',
        });

        if (results)
        {
            this.teams = results.results;
        }
    }

    /**
     * Handles create button clicks.
     */

    private onCreate()
    {
        NavigationHelper.pushRoute({ name: 'admin-employee-create' });
    }

    /**
     * Handles opening of employees.
     *
     * @param employee
     */

    private onOpenEmployee(employee: Employee)
    {
        NavigationHelper.pushRoute({
            name: 'admin-employee-edit',
            params: { id: employee.userId.toString() },
        });
    }

    /**
     * Handles filter updates.
     *
     * @param filters
     */

    private onFiltersUpdate(filters: EmployeeSearchConditionsInterface)
    {
        Object.assign(this.filters, filters);
        Object.assign(this.conditions, this.filters);

        const query = this.searchConditionsService.getQueryParamsFromConditions(
            this.conditions, 50, 'first_name',
        );

        NavigationHelper.replaceRoute({ query });
    }
}