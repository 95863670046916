import AbstractValidator from '../abstract-validator';
import Scene from '../../entities/webscenes/scene';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class SceneValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a scene.
     *
     * @param Scene
     */

    public validate(scene: Scene): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateName(scene, this.errors);
        this.validateDescription(scene, this.errors);

        // This.validateIntroduction(scene, this.errors);

        return this.errors;
    }

    private validateName(scene: Scene, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(scene.name.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.name = errors;
        }
    }

    private validateDescription(scene: Scene, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(scene.description.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_description').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.description = errors;
        }
    }

    private validateIntroduction(scene: Scene, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(scene.introduction.en))
        {
            errors.push(this.i18n.t('admin.scenes.validation.please_enter_an_introduction').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.introduction = errors;
        }
    }
}