import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Team from '../../../entities/organisation/team';
import TeamApiInterface from '../../../entities/organisation/team-api-interface';
import TeamResultsApiInterface from '../../../vos/organisation/team-results-api-interface';
import TeamSearchConditionsInterface from '../../../vos/organisation/team-search-conditions-interface';
import TeamSearchConditionsService from '../../../services/organisation/team-search-conditions-service';
import TeamTransformer from '../../../transformers/organisation/team-transformer';

export default class SearchTeamsTask extends AbstractApiGetTask
{
    private _conditions!: TeamSearchConditionsInterface;

    public async run(): Promise<TeamResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiTeams: TeamApiInterface[] = response.data.data.results;

                const teams: Team[] = [];
                for (const apiTeam of apiTeams)
                {
                    try
                    {
                        // Parse the team.
                        teams.push(TeamTransformer.fromApi(apiTeam));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Team');
                        console.error(error);
                    }
                }

                return {
                    results: teams,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: TeamSearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/teams';
    }

    protected getParams(): any
    {
        const searchConditionsService = new TeamSearchConditionsService();
        const params = searchConditionsService.getQueryParamsFromConditions(
            this._conditions, this._conditions.limit ?? 20, this._conditions.sortBy ?? 'name',
        );

        params.limit = this._conditions.limit;

        return params;
    }
}