import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Category from '../../../model/entities/webscenes/category';
import DividerComponent from '@/components/widgets/divider/index.vue';
import Scene from '../../../model/entities/webscenes/scene';
import SceneButtonComponent from '@/components/widgets/scene-button/index.vue';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-divider': DividerComponent,
        'app-scene-button': SceneButtonComponent,
        'app-translation': TranslationComponent,
    },
})
export default class CategoryScenesComponent extends Vue
{
    @Prop()
    private category!: Category;

    private scenes: Scene[] = [];
    private isLoading = true;

    created()
    {
        this.loadScenes();
    }

    /**
     * Returns rows containing 3 scenes each.
     */

    get rows(): Scene[][]
    {
        const rows: Scene[][] = [];

        // Calculate how many columns should appear.
        let columns: number;
        switch (this.$vuetify.breakpoint.name)
        {
        case 'xl':
        case 'lg':
            columns = 3;
            break;

        case 'md':
            columns = 2;
            break;

        case 'sm':
        case 'xs':
        default:
            columns = 1;
            break;
        }

        let rowItems: Scene[] = [];
        for (let index = 0; index < this.scenes.length; index++)
        {
            const scene = this.scenes[index];

            rowItems.push(scene);

            // New row?
            if ((index + 1) % columns === 0)
            {
                rows.push(rowItems);
                rowItems = [];
            }
        }

        rows.push(rowItems);

        return rows;
    }

    private async loadScenes()
    {
        this.isLoading = true;
        this.scenes = await this.$serviceContainer.getSceneService().getActiveScenes(this.category.id);
        this.isLoading = false;
    }
}