import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import VueI18n from 'vue-i18n';

@Component({
})
export default class TranslationsComponent extends Vue
{
    @Prop()
    private value!: VueI18n.LocaleMessages;

    @Prop()
    private parentKey!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private parentActive!: boolean;

    private active = -1;
    private localValue: VueI18n.LocaleMessages = {};

    created()
    {
        if (this.value)
        {
            this.localValue = Object.assign({}, this.value);
        }

        if ('key_path' in this.$route.query)
        {
            const activeKeyPath = (this.$route.query.key_path as string);

            for (let index = 0; index < this.translationKeys.length; index++)
            {
                const key = this.translationKeys[index];
                const keyPath = this.getKeyPath(key);

                if (activeKeyPath === keyPath)
                {
                    this.active = index;
                    break;
                }
            }
        }
    }

    mounted()
    {
        this.$nextTick(() => this.scrollToKeyPath());
    }

    @Watch('value')
    onValueUpdated()
    {
        if (this.value)
        {
            this.localValue = Object.assign({}, this.value);
        }
    }

    private scrollToKeyPath()
    {
        if ('key_path' in this.$route.query && this.active !== null)
        {
            const elementId = this.getKeyPath(this.translationKeys[this.active]);

            window.setTimeout(() =>
            {
                const element = document.getElementById(elementId);
                if (element)
                {
                    element.scrollIntoView();
                }
            }, 2000);
        }
    }

    get translationKeys(): string[]
    {
        const keys = Object.keys(this.value);

        keys.sort();

        return keys;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Returns the full key path for a key
     *
     * @param key
     */

    private getKeyPath(key: string): string
    {
        return this.parentKey ? `${ this.parentKey }.${ key }` : key;
    }

    /**
     * Returns whether the given key is the active one.
     *
     * @param key
     */

    private isActive(key: string): boolean
    {
        return !!(this.parentActive && key === this.translationKeys[this.active]);
    }
}