import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Employee from '../../../entities/organisation/employee';
import EmployeeApiInterface from '../../../entities/organisation/employee-api-interface';
import EmployeeTransformer from '../../../transformers/organisation/employee-transformer';

export default class PatchEmployeeTask extends AbstractApiPatchTask
{
    private _employee!: Employee;

    public async run(): Promise<Employee>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiEmployee: EmployeeApiInterface = response.data.data;

                return EmployeeTransformer.fromApi(apiEmployee, apiEmployee.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._employee;
    }

    set employee(employee: Employee)
    {
        this._employee = employee;
    }

    protected getUrl(): string
    {
        return `/employees/${ this._employee.userId }`;
    }

    protected getData(): EmployeeApiInterface
    {
        return EmployeeTransformer.toApi(this._employee);
    }
}