import DeleteNotificationTask from '../../tasks/api/notifications/delete-notification';
import GetLatestNotificationsTask from '../../tasks/api/notifications/get-latest-notifications';
import GetNotificationTask from '../../tasks/api/notifications/get-notification';
import GetNotificationsTask from '../../tasks/api/notifications/get-notifications';
import GetUnreadNotificationsTask from '../../tasks/api/notifications/get-unread-notifications';
import Notification from '../../entities/notifications/notification';
import NotificationResultsInterface from '../../vos/notifications/notification-results-interface';
import NotificationSearchConditions from '../../vos/notifications/notification-search-conditions';
import PatchNotificationTask from '../../tasks/api/notifications/patch-notification';
import PostNotificationTask from '../../tasks/api/notifications/post-notification';
import ReadNotificationTask from '../../tasks/api/notifications/read-notification';
import SearchNotificationsTask from '../../tasks/api/notifications/search-notifications';

export default class NotificationService
{
    /**
     * Returns all the notifications for this user.
     *
     */

    public async getNotifications(): Promise<Notification[]>
    {
        const task = new GetNotificationsTask();

        return task.run();
    }

    /**
     * Returns the result of a search on notifcations
     *
     * @param conditions
     */

    public async searchNotifications(conditions: NotificationSearchConditions): Promise<NotificationResultsInterface|null>
    {
        const task = new SearchNotificationsTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Return all the notifications for this user that have not been read
     *
     */

    public async getUnreadNotifications(): Promise<Notification[]>
    {
        const task = new GetUnreadNotificationsTask();

        return task.run();
    }

    /**
     * Loads the latest notifications.
     */

    public async loadLatestNotifications(): Promise<Notification[]>
    {
        const task = new GetLatestNotificationsTask();

        return task.run();
    }

    /**
     * Loads a Notification.
     *
     * @param id
     */

    public async loadNotification(id: number): Promise<Notification|null>
    {
        const task = new GetNotificationTask();
        task.id = id;

        return task.run();
    }

    /**
     * Mark a notification as read.
     *
     * @param id
     */

    public async readNotification(id: number): Promise<Notification|null>
    {
        const task = new ReadNotificationTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a Notification.
     *
     * @param notification
     */

    public async createNotification(notification: Notification): Promise<Notification>
    {
        const task = new PostNotificationTask();
        task.notification = notification;

        return task.run();
    }

    /**
     * Updates a notification.
     *
     * @param notification
     */

    public async updateNotification(notification: Notification): Promise<Notification>
    {
        const task = new PatchNotificationTask();
        task.notification = notification;

        return task.run();
    }

    /**
     * Deletes an notification.
     *
     * @param id
     */

    public async deleteNotification(id: number): Promise<void>
    {
        const task = new DeleteNotificationTask();
        task.id = id;

        return task.run();
    }
}