import AbstractValidator from '../abstract-validator';
import Notification from '../../entities/notifications/notification';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class NotificationValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a notification.
     *
     * @param Notification
     */

    public validate(notification: Notification): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateTitle(notification, this.errors);

        return this.errors;
    }

    private validateTitle(notification: Notification, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(notification.title.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_title').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.title = errors;
        }
    }
}