import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format, parse } from 'date-fns';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Post from '../../../model/entities/blog/post';
import PostFileInterface from '../../../model/vos/blog/post-file-interface';
import PostValidator from '../../../model/validator/blog/post-validator';
import TagsInputComponent from '@/components/widgets/tags-input/index.vue';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-tags-input': TagsInputComponent,
    },
})
export default class PostFormComponent extends Vue
{
    @Prop()
    private post!: Post;

    @Prop({ default: 'Save Post' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formPost: Post|null = null;
    private filesToUpload: PostFileInterface[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formPost = Object.assign(new Post(), this.post);
    }

    @Watch('post')
    private onPostUpdated()
    {
        this.formPost = Object.assign(new Post(), this.post);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formPost)
        {
            return false;
        }

        const validator: PostValidator = new PostValidator();
        this.validationErrors = validator.validate(this.formPost);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Returns the URI for the image
     */

    get imageUri(): string
    {
        if (!this.formPost || !this.formPost.image)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + this.formPost.image.uri;
    }

    /**
     * Returns the orginal filename for the image
     */

    get imageFilename(): string
    {
        if (!this.formPost || !this.formPost.image)
        {
            return 'no_file';
        }

        return this.formPost.image.filename;
    }

    /**
     * Returns the published date
     */

    get publishedDate(): string
    {
        if (!this.formPost)
        {
            return '';
        }

        if (!this.formPost.publishedDate)
        {
            return format(new Date(), 'yyyy-MM-dd');
        }

        return format(this.formPost.publishedDate, 'yyyy-MM-dd');
    }

    /**
     * Sets the published date
     */

    set publishedDate(dateString: string)
    {
        if (!this.formPost)
        {
            return;
        }

        this.formPost.publishedDate = parse(
            dateString, 'yyyy-MM-dd', new Date(),
        );
    }

    /**
     * Returns the tags
     */

    get tags(): string[]
    {
        if (!this.formPost)
        {
            return [];
        }

        return this.formPost.tags;
    }

    /**
     * Sets the tags
     */

    set tags(tags: string[])
    {
        if (!this.formPost)
        {
            return;
        }

        this.formPost.tags = tags.slice(0);
    }

    /**
     * Handles image updates
     *
     * @param file
     */

    private onImageChange(file: File)
    {
        this.filesToUpload.push({
            field: 'image',
            file,
        });
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formPost)
        {
            throw new Error('Invalid post properties');
        }

        const validator = new PostValidator();
        const validationErrors = validator.validate(this.formPost);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid post properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated post.
            this.$emit(
                'save', this.formPost, this.filesToUpload.splice(0),
            );
            this.filesToUpload = [];
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}