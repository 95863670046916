import AbstractValidator from '../abstract-validator';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class RequestResetPasswordFormValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a login form.
     *
     * @param email
     */

    public validate(email: string): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateEmail(email, this.errors);

        return this.errors;
    }

    private validateEmail(email: string, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(email, 3))
        {
            errors.push('Please enter your email address');
        }

        if (errors.length > 0)
        {
            validationErrors.email = errors;
        }
    }
}