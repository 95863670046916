import AbstractApiPostTask from '../abstract-api-post-task';

export default class ResetPasswordTask extends AbstractApiPostTask
{
    private _key!: string;
    private _password!: string;

    public async run(): Promise<boolean>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return true;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return false;
    }

    set key(key: string)
    {
        this._key = key;
    }

    set password(password: string)
    {
        this._password = password;
    }

    protected getUrl(): string
    {
        return '/reset-password';
    }

    protected getData(): any
    {
        return {
            key: this._key,
            password: this._password,
        };
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}