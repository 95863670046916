import { formatISO, parseISO } from 'date-fns';
import AssociationsApiInterface from '../../entities/associations-api-interface';
import FirstAider from '../../entities/organisation/first-aider';
import FirstAiderApiInterface from '../../entities/organisation/first-aider-api-interface';
import LocationTransformer from './location-transformer';

export default class FirstAiderTransformer
{
    /**
     * Transforms an API firstAider representation into a FirstAider entity.
     *
     * @param apiFirstAider
     */

    public static fromApi(apiFirstAider: FirstAiderApiInterface, associations?: AssociationsApiInterface): FirstAider
    {
        const firstAider = new FirstAider();
        firstAider.id = apiFirstAider.id;
        firstAider.firstName = apiFirstAider.first_name;
        firstAider.lastName = apiFirstAider.last_name;
        firstAider.locationId = apiFirstAider.location_id;
        firstAider.role = apiFirstAider.role;
        firstAider.email = apiFirstAider.email;
        firstAider.phone = apiFirstAider.phone;
        firstAider.type = apiFirstAider.type;
        firstAider.isEnabled = apiFirstAider.enabled;
        firstAider.created = parseISO(apiFirstAider.created);
        firstAider.updated = parseISO(apiFirstAider.updated);

        // Associations.
        if (associations)
        {
            if (firstAider.locationId && associations.locations?.[firstAider.locationId])
            {
                const location = LocationTransformer.fromApi(associations.locations[firstAider.locationId], associations);
                firstAider.location = location;
            }
        }

        return firstAider;
    }

    /**
     * Transforms a FirstAider entity into an API representation of a firstAider.
     *
     * @param firstAider
     */

    public static toApi(firstAider: FirstAider): FirstAiderApiInterface
    {
        return {
            id: firstAider.id,
            first_name: firstAider.firstName,
            last_name: firstAider.lastName,
            location_id: firstAider.locationId,
            role: firstAider.role,
            email: firstAider.email,
            phone: firstAider.phone,
            type: firstAider.type,
            enabled: firstAider.isEnabled,
            created: formatISO(firstAider.created),
            updated: formatISO(firstAider.updated),
        };
    }
}