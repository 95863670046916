import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Notification from '../../../model/entities/notifications/notification';
import NotificationValidator from '../../../model/validator/notifications/notification-validator';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import User from '@/model/entities/organisation/user';
import UserSelectInputComponent from '@/components/widgets/user-select-input/index.vue';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'vp-users-select': UserSelectInputComponent,
    },
})
export default class NotificationFormComponent extends Vue
{
    @Prop()
    private notification!: Notification;

    @Prop({ default: 'Save Notification' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formNotification: Notification|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formNotification = Object.assign(new Notification(), this.notification);
    }

    @Watch('notification')
    private onNotificationUpdated()
    {
        this.formNotification = Object.assign(new Notification(), this.notification);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formNotification)
        {
            return false;
        }

        const validator: NotificationValidator = new NotificationValidator();
        this.validationErrors = validator.validate(this.formNotification);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formNotification)
        {
            throw new Error('Invalid notification properties');
        }

        const validator = new NotificationValidator();
        const validationErrors = validator.validate(this.formNotification);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid notification properties');
        }
    }

    /**
     * Handler for user update
     */

    private onUserChange(users: User[])
    {
        if (!this.formNotification)
        {
            return;
        }

        this.formNotification.users = users;
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated notification.
            this.$emit('save', this.formNotification);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}