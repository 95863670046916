import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LoginFormComponent from '@/components/widgets/login-form/index.vue';
import NavigationHelper from '@/model/services/navigation/navigation-helper';

@Component({
    components:
    {
        'vp-login-form': LoginFormComponent,
    },
})
export default class LoginView extends Vue
{
    private isLoggingIn = false;

    /**
     * Handles login.
     *
     * @param email
     * @param password
     */

    private async onLogin(email: string, password: string)
    {
        try
        {
            // Mark as logging in.
            this.isLoggingIn = true;

            // Login.
            const user = await this.$serviceContainer.getAuthenticationService().login(email, password);

            // If a user was returned, redirect to the home page.
            if (user)
            {
                NavigationHelper.pushRoute({ name: 'home' });
            }
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('authentication.error.problem_logging_in'), 'error',
            );
        }
        finally
        {
            // No longer logging in.
            this.isLoggingIn = false;
        }
    }
}