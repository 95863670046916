import './plugins/vertical-plus';

import '@vertical-plus/vue-js-components';
import '@vertical-plus/vue-js-micro-service-components';
import '@vertical-plus/vue-js-micro-service-components/lib/plugins/micro-service';

import App from '@/app/index.vue';
import ButtonComponent from '@/components/widgets/button/index.vue';
import TranslationGroupComponent from '@/components/widgets/translation-group/index.vue';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import i18n from './i18n';
import router from './router';
import store from './store';
import vueHeadful from 'vue-headful';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueScrollTo);

// Register components used within this project multiple times globally
Vue.component('app-button', ButtonComponent);
Vue.component('app-translation-group', TranslationGroupComponent);
Vue.component('vue-headful', vueHeadful);

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');