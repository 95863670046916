import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Category from '../../../entities/webscenes/category';
import CategoryApiInterface from '@/model/entities/webscenes/category-api-interface';
import CategoryTransformer from '../../../transformers/webscenes/category-transformer';

export default class PostCategoryTask extends AbstractApiPostTask
{
    private _category!: Category;

    public async run(): Promise<Category>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return CategoryTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._category;
    }

    set category(category: Category)
    {
        this._category = category;
    }

    protected getUrl(): string
    {
        return '/categories';
    }

    protected getData(): CategoryApiInterface
    {
        return CategoryTransformer.toApi(this._category);
    }
}