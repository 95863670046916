import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Newsletter from '../../../../../model/entities/media/newsletter';
import NewsletterFileInterface from '../../../../../model/vos/media/newsletter-file-interface';
import NewsletterFormComponent from '@/components/widgets/newsletter-form/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-newsletter-form': NewsletterFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminNewsletterCreatePageComponent extends Vue
{
    private newsletter: Newsletter|null = null;

    created()
    {
        this.newsletter = Object.assign({}, new Newsletter());
        this.newsletter.releaseDate = new Date();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.newsletters.message.index_title'),
                disabled: false,
                to: { name: 'admin-newsletter-index' },
                exact: true,
            },
            {
                text: this.$t('admin.newsletters.message.create_title'),
                disabled: true,
                to: { name: 'admin-newsletter-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param newsletter
     * @param files
     */

    private async onSave(newsletter: Newsletter, files: NewsletterFileInterface[])
    {
        try
        {
            // Create the newsletter.
            const created = await this.$serviceContainer.getNewsletterService().createNewsletter(newsletter);

            // Upload files.
            if (files.length > 0)
            {
                await this.uploadFiles(created, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.newsletters.message.newsletter_created'));

            // Redirect to the newsletter.
            NavigationHelper.pushRoute({
                name: 'admin-newsletter-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads newsletter files.
     *
     * @param newsletter
     * @param files
     */

    private async uploadFiles(newsletter: Newsletter, files: NewsletterFileInterface[]): Promise<void>
    {
        for (const file of files)
        {
            await this.$serviceContainer.getNewsletterService().uploadNewsletterFile(
                newsletter.id,
                file.field,
                file.locale,
                file.file,
            );
        }
    }
}