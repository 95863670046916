import { Component, Prop, Vue } from 'vue-property-decorator';
import Activity from '../../../model/entities/rewards/activity';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Employee from '../../../model/entities/organisation/employee';
import EmployeePointsApiInterface from '../../../model/vos/organisation/employee-points-api-interface';
import Reward from '../../../model/entities/rewards/reward';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class EmployeePointsFormComponent extends Vue
{
    @Prop()
    private employee!: Employee;

    private activities: Activity[] = [];
    private employeePoints: EmployeePointsApiInterface = { total_points: 0,
        total_redeemable_points: 0 };

    private rewards: Reward[] = [];

    private activity: Activity|null = null;
    private activityNotes = '';
    private activityPointsAwarded = 0;
    private activityPointsRedeemable = 0;

    private reward: Reward|null = null;
    private rewardPoints = 0;

    private isLoading = false;

    $refs!:
    {
        confirmDialog: any
    };

    async created()
    {
        this.isLoading = true;

        await this.loadActivities();
        await this.loadPoints();
        await this.loadRewards();

        this.isLoading = false;
    }

    get points()
    {
        return [
            { field: this.$t('leaderboard.field.points_awarded'),
                value: this.employeePoints.total_points },
            { field: this.$t('leaderboard.field.redeemable_points'),
                value: this.employeePoints.total_redeemable_points },
        ];
    }

    activityName(activity: Activity)
    {
        return this.$et(activity.name);
    }

    rewardName(reward: Reward)
    {
        return this.$et(reward.name);
    }

    /**
     * Loads the available activities.
     */

    private async loadActivities()
    {
        const results = await this.$serviceContainer.getActivityService().searchActivities({
            limit: 100,
            sortBy: 'name',
        });

        if (results)
        {
            this.activities = results.results;
        }
    }

    /**
     * Loads the employee's points.
     */

    private async loadPoints()
    {
        const points = await this.$serviceContainer.getEmployeeService().getUserPoints(this.employee.userId);
        if (points)
        {
            this.employeePoints = Object.assign({}, points);
        }
    }

    /**
     * Loads the available rewards.
     */

    private async loadRewards()
    {
        const results = await this.$serviceContainer.getRewardService().searchRewards({
            limit: 100,
            sortBy: 'name',
        });

        if (results)
        {
            this.rewards = results.results;
        }
    }

    /**
     * Handles activity selection.
     *
     * @param activity
     */

    private onActivityChange(activity: Activity)
    {
        if (!activity)
        {
            this.activityPointsAwarded = 0;
            this.activityPointsRedeemable = 0;
            return;
        }

        this.activityPointsAwarded = activity.points;
        this.activityPointsRedeemable = activity.points;
    }

    /**
     * Handles reward selection.
     *
     * @param reward
     */

    private onRewardChange(reward: Reward)
    {
        if (!reward)
        {
            this.rewardPoints = 0;
            return;
        }

        this.rewardPoints = reward.points;
    }

    /**
     * Handles activity addition requests.
     */

    private async onAddActivity()
    {
        const confirmed = await this.$refs.confirmDialog.open(this.$t('admin.employees.message.add_activity_question').toString(), this.$t('admin.employees.message.add_activity_confirmation').toString());

        if (confirmed)
        {
            return this.addActivity();
        }
    }

    private addActivity()
    {
        this.$emit(
            'add-activity', this.activity, this.activityNotes, this.activityPointsAwarded, this.activityPointsRedeemable,
        );
    }

    /**
     * Handles reward addition requests.
     */

    private async onAddReward()
    {
        const confirmed = await this.$refs.confirmDialog.open(this.$t('admin.employees.message.add_reward_question').toString(), this.$t('admin.employees.message.add_reward_confirmation').toString());

        if (confirmed)
        {
            return this.addReward();
        }
    }

    private addReward()
    {
        this.$emit(
            'add-reward', this.reward, this.rewardPoints,
        );
    }
}