import { Component, Prop, Vue } from 'vue-property-decorator';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-translation': TranslationComponent,
    },
})
export default class PageTitleComponent extends Vue
{
    @Prop()
    private title!: string;

    @Prop()
    private description!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private translation!: boolean;
}