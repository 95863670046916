import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SupportedLocales } from '@/model/services/translations/translation-service';
import { format, parse } from 'date-fns';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import Newsletter from '../../../model/entities/media/newsletter';
import NewsletterFileInterface from '../../../model/vos/media/newsletter-file-interface';
import NewsletterValidator from '../../../model/validator/media/newsletter-validator';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class NewsletterFormComponent extends Vue
{
    @Prop()
    private newsletter!: Newsletter;

    @Prop({ default: 'Save Newsletter' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formNewsletter: Newsletter|null = null;
    private filesToUpload: NewsletterFileInterface[] = [];

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formNewsletter = Object.assign(new Newsletter(), this.newsletter);
    }

    @Watch('newsletter')
    private onNewsletterUpdated()
    {
        this.formNewsletter = Object.assign(new Newsletter(), this.newsletter);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formNewsletter)
        {
            return false;
        }

        const validator: NewsletterValidator = new NewsletterValidator();
        this.validationErrors = validator.validate(this.formNewsletter);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Returns the release date
     */

    get releaseDate(): string
    {
        if (!this.formNewsletter)
        {
            return '';
        }

        if (!this.formNewsletter.releaseDate)
        {
            return format(new Date(), 'yyyy-MM-dd');
        }

        return format(this.formNewsletter.releaseDate, 'yyyy-MM-dd');
    }

    /**
     * Sets the release date
     */

    set releaseDate(dateString: string)
    {
        if (!this.formNewsletter)
        {
            return;
        }

        this.formNewsletter.releaseDate = parse(
            dateString, 'yyyy-MM-dd', new Date(),
        );
    }

    /**
     * Returns the URI for the file
     *
     * @param locale
     */

    getFileUri(locale: SupportedLocales): string
    {
        if (!this.formNewsletter?.file?.[locale]?.uri)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + this.formNewsletter?.file?.[locale]?.uri;
    }

    /**
     * Handles file updates
     *
     * @param file
     * @param locale
     */

    private onFileChange(file: File, locale: string)
    {
        this.filesToUpload.push({
            field: 'file',
            locale,
            file,
        });
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formNewsletter)
        {
            throw new Error('Invalid newsletter properties');
        }

        const validator = new NewsletterValidator();
        const validationErrors = validator.validate(this.formNewsletter);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid newsletter properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated newsletter.
            this.$emit(
                'save', this.formNewsletter, this.filesToUpload.splice(0),
            );
            this.filesToUpload = [];
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}