import { Component, Vue } from 'vue-property-decorator';
import DividerComponent from '@/components/widgets/divider/index.vue';
import Employee from '../../../model/entities/organisation/employee';
import LeaderboardApiInterface from '../../../model/vos/organisation/leaderboard-api-interface';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-divider': DividerComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class LeaderboardPageComponent extends Vue
{
    private isLoading = true;
    private leaderboard: LeaderboardApiInterface[] = [];

    async created()
    {
        this.isLoading = true;
        await this.loadLeaderboard();
        this.isLoading = false;
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('leaderboard.message.leaderboard'),
                disabled: false,
                to: { name: 'leaderboard' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            { text: this.$t('general.field.name'),
                align: 'left',
                value: 'name',
                sortable: false },
            { text: this.$t('general.field.points'),
                align: 'center',
                value: 'points',
                sortable: false },
        ];
    }

    /**
     * Returns the current employee.
     */

    get employee(): Employee
    {
        return this.$castEmployee(this.$store.state.app.employee);
    }

    /**
     * Returns the total points.
     */

    get totalPoints(): number
    {
        let total = 0;

        for (const row of this.leaderboard)
        {
            total += row.total_points;
        }

        return total;
    }

    /**
     * Loads the leaderboard.
     */

    private async loadLeaderboard()
    {
        if (!this.employee || !this.employee.teamId)
        {
            return;
        }

        this.leaderboard = await this.$serviceContainer.getTeamService().getLeaderboard(this.employee.teamId);
    }
}