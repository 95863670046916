import DeleteRewardTask from '../../tasks/api/rewards/delete-reward';
import GetActiveRewardsTask from '../../tasks/api/rewards/get-active-rewards';
import GetRewardTask from '../../tasks/api/rewards/get-reward';
import PatchRewardTask from '../../tasks/api/rewards/patch-reward';
import PostRewardTask from '../../tasks/api/rewards/post-reward';
import Reward from '../../entities/rewards/reward';
import RewardResultsApiInterface from '../../vos/rewards/reward-results-api-interface';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchRewardsTask from '../../tasks/api/rewards/search-rewards';
import UploadFileTask from '@/model/tasks/api/file/upload-file';
import store from '@/store';

export default class RewardService
{
    /**
     * Returns all the active rewards.
     *
     */

    public async getActiveRewards(): Promise<Reward[]>
    {
        const task = new GetActiveRewardsTask();

        return task.run();
    }

    /**
     * Returns rewards matching the conditions.
     *
     * @param conditions
     */

    public async searchRewards(conditions: SearchConditionsInterface): Promise<RewardResultsApiInterface|null>
    {
        const task = new SearchRewardsTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a reward.
     *
     * @param id
     */

    public async loadReward(id: number|string): Promise<Reward|null>
    {
        const task = new GetRewardTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a reward.
     *
     * @param reward
     */

    public async createReward(reward: Reward): Promise<Reward>
    {
        const task = new PostRewardTask();
        task.reward = reward;

        return task.run();
    }

    /**
     * Updates a reward.
     *
     * @param reward
     */

    public async updateReward(reward: Reward): Promise<Reward>
    {
        const task = new PatchRewardTask();
        task.reward = reward;

        return task.run();
    }

    /**
     * Deletes an reward.
     *
     * @param id
     */

    public async deleteReward(id: number): Promise<void>
    {
        const task = new DeleteRewardTask();
        task.id = id;

        return task.run();
    }

    /**
     * Returns the image uri for a reward.
     *
     * @param reward
     */

    public getRewardImageUri(reward: Reward): string|null
    {
        if (!reward.id || !reward.image)
        {
            return null;
        }

        return `${ process.env.VUE_APP_API_BASE_URL }/rewards/${ reward.id }/image.jpg?token=${ this.getApiToken() }`;
    }

    /**
     * Uploads any updated files.
     *
     * @param reward
     * @param formReward
     */

    public async uploadRewardImage(reward: Reward, formReward: Reward)
    {
        if (!formReward.imageFile)
        {
            return;
        }

        const task = new UploadFileTask();
        task.path = `/rewards/${ reward.id }/image.jpg`;
        task.name = 'file';
        task.file = formReward.imageFile;

        return task.run();
    }

    /**
     * Returns the API token
     *
     * @returns
     */

    protected getApiToken(): string
    {
        return store.getters['app/user']?.token ?? '';
    }
}