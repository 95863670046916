import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchConditionsService from '@/model/services/search/search-conditions-service';
import Video from '../../../entities/media/video';
import VideoApiInterface from '../../../entities/media/video-api-interface';
import VideoResultsApiInterface from '../../../vos/media/video-results-api-interface';
import VideoTransformer from '../../../transformers/media/video-transformer';

export default class SearchVideosTask extends AbstractApiGetTask
{
    private _conditions!: SearchConditionsInterface;

    public async run(): Promise<VideoResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiVideos: VideoApiInterface[] = response.data.data.results;

                const videos: Video[] = [];
                for (const apiVideo of apiVideos)
                {
                    try
                    {
                        // Parse the video.
                        videos.push(VideoTransformer.fromApi(apiVideo));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Video');
                        console.error(error);
                    }
                }

                return {
                    results: videos,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: SearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/videos';
    }

    protected getParams(): any
    {
        const searchConditionsService = new SearchConditionsService();
        const params = searchConditionsService.getQueryParamsFromConditions(
            this._conditions, 20, 'displayOrder',
        );

        params.limit = this._conditions.limit;

        return params;
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}