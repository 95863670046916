import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Post from '../../../entities/blog/post';
import PostApiInterface from '@/model/entities/blog/post-api-interface';
import PostTransformer from '../../../transformers/blog/post-transformer';

export default class PostPostTask extends AbstractApiPostTask
{
    private _post!: Post;

    public async run(): Promise<Post>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return PostTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._post;
    }

    set post(post: Post)
    {
        this._post = post;
    }

    protected getUrl(): string
    {
        return '/posts';
    }

    protected getData(): PostApiInterface
    {
        return PostTransformer.toApi(this._post);
    }
}