import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';
import Video from '../../../model/entities/media/video';
import VideoValidator from '../../../model/validator/media/video-validator';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class VideoFormComponent extends Vue
{
    @Prop()
    private video!: Video;

    @Prop({ default: 'Save Video' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formVideo: Video|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formVideo = Object.assign(new Video(), this.video);
    }

    @Watch('video')
    private onVideoUpdated()
    {
        this.formVideo = Object.assign(new Video(), this.video);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formVideo)
        {
            return false;
        }

        const validator: VideoValidator = new VideoValidator();
        this.validationErrors = validator.validate(this.formVideo);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formVideo)
        {
            throw new Error('Invalid video properties');
        }

        const validator = new VideoValidator();
        const validationErrors = validator.validate(this.formVideo);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid video properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated video.
            this.$emit('save', this.formVideo);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}