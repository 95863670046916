import { Component, Prop, Vue } from 'vue-property-decorator';
import { difference, findIndex } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Category from '../../../model/entities/webscenes/category';
import DividerComponent from '@/components/widgets/divider/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import Scene from '../../../model/entities/webscenes/scene';
import SceneButtonComponent from '@/components/widgets/scene-button/index.vue';
import SceneImagesComponent from '@/components/widgets/scene-images/index.vue';
import SceneQuestion from '../../../model/entities/webscenes/scene-question';
import SceneQuestionAnswer from '../../../model/entities/webscenes/scene-question-answer';
import SceneQuestionComponent from '@/components/widgets/scene-question/index.vue';
import SceneResultsComponent from '@/components/widgets/scene-results/index.vue';
import SceneSceneComponent from '@/components/widgets/scene-scene/index.vue';
import TranslationComponent from '@/components/widgets/translation/index.vue';
import UserSceneAnswer from '../../../model/entities/webscenes/user-scene-answer';
import UserSceneResultsApiInterface from '../../../model/vos/webscenes/user-scene-results-api-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-divider': DividerComponent,
        'app-scene-button': SceneButtonComponent,
        'app-scene-images': SceneImagesComponent,
        'app-scene-question': SceneQuestionComponent,
        'app-scene-results': SceneResultsComponent,
        'app-scene-scene': SceneSceneComponent,
        'app-translation': TranslationComponent,
    },
})
export default class SceneComponent extends Vue
{
    @Prop()
    private category!: Category;

    @Prop()
    private scene!: Scene;

    private userAnswers: UserSceneAnswer[] = [];
    private incorrectQuestions: number[] = [];
    private reansweredQuestions: number[] = [];
    private otherScenes: Scene[] = [];
    private isLoading = true;

    private showQuestionDialog = false;
    private questionDialogQuestion: SceneQuestion|null = null;

    private showResultDialog = false;
    private results: UserSceneResultsApiInterface|null = null;

    async created()
    {
        this.isLoading = true;
        await this.loadUserAnswers();
        await this.loadOtherScenes();
        this.isLoading = false;
    }

    /**
     * Returns the progress in this scene.
     */

    get progress(): number
    {
        const answered: number = this.userAnswers.length - (this.incorrectQuestions.length - this.reansweredQuestions.length);

        return Math.round(answered / this.scene.questions.length * 100);
    }

    /**
     * Returns the colour for the current progress.
     */

    get progressColour(): string
    {
        if (this.progress < 50)
        {
            return 'error';
        }
        else if (this.progress < 100)
        {
            return 'warning';
        }
        else
        {
            return 'success';
        }
    }

    /**
     * Returns the other scenes to show in this category.
     */

    get otherScenesInCategory(): Scene[]
    {
        // Remove the current scene from the array.
        const scenes: Scene[] = [];
        for (const scene of this.otherScenes)
        {
            if (scene.id === this.scene.id)
            {
                continue;
            }

            scenes.push(scene);
        }

        // Return an empty array if no scenes.
        if (scenes.length === 0)
        {
            return [];
        }

        // Choose 3 random scenes.
        const shuffled = scenes.sort(() => 0.5 - Math.random());
        const selected = shuffled.slice(0, 3);

        return selected;
    }

    /**
     * Returns whether the scene has been fully answered, taking into account incorrectly answered questions.
     */

    get isSceneComplete(): boolean
    {
        if (this.incorrectQuestions.length > 0)
        {
            return difference(this.incorrectQuestions, this.reansweredQuestions).length === 0;
        }
        else
        {
            return this.userAnswers.length === this.scene.questions.length;
        }
    }

    /**
     * Returns whether the given question has been answered.
     *
     * @param question
     */

    private questionIsAnswered(question: SceneQuestion): boolean
    {
        for (const userAnswer of this.userAnswers)
        {
            if (userAnswer.questionId === question.id)
            {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns whether the given question has been answered incorrectly.
     *
     * @param question
     */

    private questionIsIncorrect(question: SceneQuestion): boolean
    {
        if (!this.incorrectQuestions)
        {
            return false;
        }

        return this.incorrectQuestions.indexOf(question.id) > -1;
    }

    /**
     * Loads the answers for the scene.
     */

    private async loadUserAnswers()
    {
        this.userAnswers = await this.$serviceContainer.getSceneService().getUserAnswers(this.scene.id);

        if (this.userAnswers.length === this.scene.questions.length)
        {
            this.onComplete();
        }
    }

    /**
     * Loads other scenes in the category.
     */

    private async loadOtherScenes()
    {
        if (!this.category)
        {
            return;
        }

        this.otherScenes = await this.$serviceContainer.getSceneService().getActiveScenes(this.category.id);
    }

    /**
     * Handles requests to show a question.
     *
     * @param question
     */

    private onShowQuestion(question: SceneQuestion)
    {
        // If this question has already been answered, deny access.
        if (this.questionIsAnswered(question) && !this.questionIsIncorrect(question))
        {
            EventBus.instance.$emit('notification', this.$t('scenes.message.question_already_answered'));
            return;
        }

        this.questionDialogQuestion = Object.assign({}, question);
        this.showQuestionDialog = true;
    }

    /**
     * Handles question answering.
     *
     * @param question
     * @param answer
     * @param close
     */

    private async onQuestionAnswered(
        question: SceneQuestion, answer: SceneQuestionAnswer, close: boolean,
    )
    {
        // Remove any existing answer for this question.
        const existingIndex = findIndex(this.userAnswers, (userAnswer: UserSceneAnswer) => userAnswer.questionId === question.id);

        if (existingIndex > -1)
        {
            this.userAnswers.splice(existingIndex, 1);
        }

        // Add the answer to the array so the interface can update.
        const userAnswer = new UserSceneAnswer();
        userAnswer.sceneId = this.scene.id;
        userAnswer.questionId = question.id;
        userAnswer.answerId = answer.id;

        this.userAnswers.push(userAnswer);

        // If this answer was previously incorrect, mark the fact this question has been re-answered.
        if (this.incorrectQuestions.includes(userAnswer.questionId) && !this.reansweredQuestions.includes(userAnswer.questionId))
        {
            this.reansweredQuestions.push(userAnswer.questionId);
        }

        // Close the dialog.
        if (close)
        {
            this.onQuestionClose();
        }
    }

    /**
     * Handles a question being closed.
     */

    private onQuestionClose()
    {
        // Close the dialog.
        this.showQuestionDialog = false;
        this.questionDialogQuestion = null;

        // If all the questions have been answered, show the completion dialog.
        if (this.isSceneComplete)
        {
            this.onComplete();
        }
    }

    /**
     * Handles scene completion events.
     */

    private async onComplete()
    {
        // Load the scene results.
        const results = await this.$serviceContainer.getSceneService().getUserSceneResults(this.scene.id);
        this.results = Object.assign({}, results);
        this.incorrectQuestions = this.results.incorrect_questions;
        this.reansweredQuestions = [];

        // Show the results dialog.
        this.showResultDialog = true;
    }

    /**
     * Handles continue scene requests.
     */

    private onContinueScene()
    {
        // Close the dialog.
        this.showResultDialog = false;
    }

    /**
     * Handles restart scene requests.
     */

    private async onRestartScene()
    {
        // Restart the scene.
        await this.$serviceContainer.getSceneService().restartScene(this.scene.id);

        // Reset variables.
        this.userAnswers = [];
        this.incorrectQuestions = [];
        this.reansweredQuestions = [];
        this.results = null;

        // Close the dialog.
        this.showResultDialog = false;
    }

    /**
     * Handles exit scene requests.
     */

    private onExitScene()
    {
        NavigationHelper.replaceRoute({
            name: 'category-view',
            params: { categoryRef: this.category.ref },
        });
    }
}