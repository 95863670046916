import TranslationsInterface from '@/model/vos/translations/translations-interface';

export default class Video
{
    id = 0;
    title: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    description: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    url: TranslationsInterface = {
        'en': '',
        'nl-BE': '',
    };

    displayOrder = 1;
    isEnabled = false;
    created: Date = new Date();
    updated: Date = new Date();
}