import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';

export default class GetInterfaceTranslationsTask extends AbstractApiGetTask
{
    public async run(): Promise<any>
    {
        try
        {
            const response = await this.request();

            return JSON.parse(response.data);
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    protected getUrl(): string
    {
        return '/translations/interface.json';
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}