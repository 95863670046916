import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { size } from 'lodash';
import ButtonComponent from '@/components/widgets/button/index.vue';
import Category from '../../../model/entities/webscenes/category';
import CategoryValidator from '../../../model/validator/webscenes/category-validator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
    components:
    {
        'app-button': ButtonComponent,
    },
})
export default class CategoryFormComponent extends Vue
{
    @Prop()
    private category!: Category;

    @Prop({ default: 'Save Category' })
    private saveLabel!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formCategory: Category|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formCategory = Object.assign(new Category(), this.category);
    }

    @Watch('category')
    private onCategoryUpdated()
    {
        this.formCategory = Object.assign(new Category(), this.category);
    }

    /**
     * Returns whether the entire form is valid.
     */

    get isValid(): boolean
    {
        if (!this.formCategory)
        {
            return false;
        }

        const validator: CategoryValidator = new CategoryValidator();
        this.validationErrors = validator.validate(this.formCategory);

        return size(this.validationErrors) === 0;
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    /**
     * Returns an iso string for a date.
     *
     * @param date
     */

    private getDateString(date: Date): string|null
    {
        return date ? format(date, 'yyyy-MM-dd HH:mm') : null;
    }

    /**
     * Validates the form.
     */

    private async validate()
    {
        if (!this.formCategory)
        {
            throw new Error('Invalid category properties');
        }

        const validator = new CategoryValidator();
        const validationErrors = validator.validate(this.formCategory);

        if (size(validationErrors) > 0)
        {
            this.validationErrors = Object.assign({}, validationErrors);

            throw new Error('Invalid category properties');
        }
    }

    /**
     * Handles saving.
     */

    private async onSave()
    {
        try
        {
            // Run a final validation check.
            await this.validate();

            // Emit the save event and include the validated category.
            this.$emit('save', this.formCategory);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_invalid_details'), 'error',
            );
        }
    }
}