import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import FirstAider from '../../../model/entities/organisation/first-aider';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class FirstAidersComponent extends Vue
{
    private type = '';
    private firstAiders: FirstAider[] = [];
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.first_aiders'),
                disabled: true,
                to: { name: 'first-aiders',
                    params: { type: 'mental-health' } },
                exact: true,
            },
            {
                text: this.$t('first_aiders.message.mental_health'),
                disabled: true,
                to: { name: 'first-aiders',
                    params: { type: 'mental-health' } },
                exact: true,
            },
        ];
    }

    created()
    {
        this.type = this.$route.params.type;

        this.loadFirstAiders();
    }

    private get documentTitle(): string
    {
        switch (this.type)
        {
        case 'mental-health':
        default:
            return this.$t('nav.first_aiders_mental_health').toString();
        }
    }

    private get title(): string
    {
        switch (this.type)
        {
        case 'mental-health':
        default:
            return this.$t('first_aiders.message.mental_health').toString();
        }
    }

    private get description(): string
    {
        switch (this.type)
        {
        case 'mental-health':
        default:
            return this.$t('first_aiders.message.mental_health_description').toString();
        }
    }

    private get locations(): string[]
    {
        const locations: string[] = [];

        for (const firstAider of this.firstAiders)
        {
            if (!firstAider.location)
            {
                continue;
            }

            if (locations.includes(firstAider.location.name))
            {
                continue;
            }

            locations.push(firstAider.location.name);
        }

        locations.sort();

        return locations;
    }

    private get firstAidersByLocation()
    {
        const firstAiders: {[location: string]: FirstAider[]} = {};

        for (const firstAider of this.firstAiders)
        {
            if (!firstAider.location)
            {
                continue;
            }

            if (!(firstAider.location.name in firstAiders))
            {
                firstAiders[firstAider.location.name] = [];
            }

            firstAiders[firstAider.location.name].push(firstAider);
        }

        return firstAiders;
    }

    private async loadFirstAiders()
    {
        this.isLoading = true;

        this.firstAiders = await this.$serviceContainer.getFirstAiderService().getActiveFirstAiders(this.type);

        this.isLoading = false;
    }
}