import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Scene from '../../../entities/webscenes/scene';
import SceneApiInterface from '../../../entities/webscenes/scene-api-interface';
import SceneResultsApiInterface from '../../../vos/webscenes/scene-results-api-interface';
import SceneSearchConditionsInterface from '../../../vos/webscenes/scene-search-conditions-interface';
import SceneTransformer from '../../../transformers/webscenes/scene-transformer';

export default class SearchScenesTask extends AbstractApiGetTask
{
    private _conditions!: SceneSearchConditionsInterface;

    public async run(): Promise<SceneResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiScenes: SceneApiInterface[] = response.data.data.results;

                const scenes: Scene[] = [];
                for (const apiScene of apiScenes)
                {
                    try
                    {
                        // Parse the scene.
                        scenes.push(SceneTransformer.fromApi(apiScene, response.data.data.associations));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Scene');
                        console.error(error);
                    }
                }

                return {
                    results: scenes,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: SceneSearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/scenes';
    }

    protected getParams(): any
    {
        const params: any = {};

        if (this._conditions.searchTerm)
        {
            params.search_term = this._conditions.searchTerm;
        }

        if (this._conditions.categoryId)
        {
            params.category_id = this._conditions.categoryId;
        }

        if (this._conditions.limit)
        {
            params.limit = this._conditions.limit;
        }

        if (this._conditions.page)
        {
            params.page = this._conditions.page;
        }

        if (this._conditions.sortBy)
        {
            params.sort_by = this._conditions.sortBy;
        }

        if (this._conditions.descending)
        {
            params.desc = this._conditions.descending ? '1' : '0';
        }

        return params;
    }
}