import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Post from '../../../entities/blog/post';
import PostApiInterface from '../../../entities/blog/post-api-interface';
import PostTransformer from '../../../transformers/blog/post-transformer';

export default class UploadPostFileTask extends AbstractApiPostTask
{
    private _postId!: number;
    private _field!: string;
    private _file!: File;

    public async run(): Promise<Post|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiPost: PostApiInterface = response.data.data;

                if (apiPost)
                {
                    return PostTransformer.fromApi(apiPost);
                }
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set postId(postId: number)
    {
        this._postId = postId;
    }

    set field(field: string)
    {
        this._field = field;
    }

    set file(file: File)
    {
        this._file = file;
    }

    protected getUrl(): string
    {
        return `/posts/${ this._postId }/file-upload`;
    }

    protected getData(): FormData
    {
        const data = new FormData();
        data.append('post_id', this._postId.toString());
        data.append('field', this._field.toString());
        data.append('file', this._file);

        return data;
    }
}