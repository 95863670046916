import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Scene from '../../../entities/webscenes/scene';
import SceneApiInterface from '../../../entities/webscenes/scene-api-interface';
import SceneTransformer from '../../../transformers/webscenes/scene-transformer';

export default class GetSceneTask extends AbstractApiGetTask
{
    private _id!: number|string;

    public async run(): Promise<Scene|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiScene: SceneApiInterface = response.data.data;

                return SceneTransformer.fromApi(apiScene, apiScene.associations);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number|string)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/scenes/${ this._id }`;
    }
}