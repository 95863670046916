import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import User from '@/model/entities/organisation/user';
import UserFormComponent from '@/components/widgets/user-form/index.vue';

@Component({
    components:
    {
        'vp-user-form': UserFormComponent,
    },
})
export default class AdminUserEditView extends Vue
{
    private selectedUser: User|null = null;
    private isLoading = true;

    created()
    {
        this.loadUser();
    }

    /**
     * Returns the breadcrumb.
     */

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.users.message.index_title'),
                disabled: false,
                to: { name: 'admin-user-index' },
                exact: true,
            },
            {
                text: this.selectedUser ? this.selectedUser.fullName : this.$t('admin.users.action.edit'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Loads the User.
     */

    private async loadUser()
    {
        this.isLoading = true;
        this.selectedUser = await this.$serviceContainer.getUserService().loadUser(parseInt(this.$route.params.id));
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param user
     */

    private async onSave(user: User)
    {
        try
        {
            // Update the user.
            await this.$serviceContainer.getUserService().updateUser(user);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.users.message.user_updated'));
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}