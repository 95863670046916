import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import FirstAider from '../../../../../model/entities/organisation/first-aider';
import FirstAiderFormComponent from '@/components/widgets/first-aider-form/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-first-aider-form': FirstAiderFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminFirstAiderEditPageComponent extends Vue
{
    private firstAider: FirstAider|null = null;
    private isLoading = true;

    created()
    {
        this.loadFirstAider();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.first_aiders.message.index_title'),
                disabled: false,
                to: { name: 'admin-first-aider-index' },
                exact: true,
            },
            {
                text: this.$t('admin.first_aiders.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the title of the firstAider for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.firstAider ? `${ this.firstAider.firstName } ${ this.firstAider.lastName }` : this.$t('admin.first_aiders.edit_title').toString();
    }

    /**
     * Loads the safety ambassador.
     */

    private async loadFirstAider()
    {
        this.isLoading = true;
        const firstAider = await this.$serviceContainer.getFirstAiderService().loadFirstAider(parseInt(this.$route.params.id));
        this.firstAider = Object.assign({}, firstAider);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param firstAider
     */

    private async onSave(firstAider: FirstAider)
    {
        try
        {
            // Update the firstAider.
            const updated = await this.$serviceContainer.getFirstAiderService().updateFirstAider(firstAider);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.first_aiders.message.first_aider_updated'));

            this.firstAider = Object.assign(new FirstAider(), updated);
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}