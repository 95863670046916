import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Post from '../../../entities/blog/post';
import PostApiInterface from '../../../entities/blog/post-api-interface';
import PostResultsApiInterface from '../../../vos/blog/post-results-api-interface';
import PostTransformer from '../../../transformers/blog/post-transformer';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchConditionsService from '@/model/services/search/search-conditions-service';

export default class SearchPostsTask extends AbstractApiGetTask
{
    private _conditions!: SearchConditionsInterface;

    public async run(): Promise<PostResultsApiInterface|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiPosts: PostApiInterface[] = response.data.data.results;

                const posts: Post[] = [];
                for (const apiPost of apiPosts)
                {
                    try
                    {
                        // Parse the post.
                        posts.push(PostTransformer.fromApi(apiPost));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Post');
                        console.error(error);
                    }
                }

                return {
                    results: posts,
                    total_results: response.data.data.total_results,
                    results_per_page: response.data.data.results_per_page,
                    total_pages: response.data.data.total_pages,
                    page: response.data.data.page,
                    sort_by: response.data.data.sort_by,
                    descending: response.data.data.descending,
                };
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set conditions(conditions: SearchConditionsInterface)
    {
        this._conditions = conditions;
    }

    protected getUrl(): string
    {
        return '/posts';
    }

    protected getParams(): any
    {
        const searchConditionsService = new SearchConditionsService();
        const params = searchConditionsService.getQueryParamsFromConditions(
            this._conditions, 20, 'title',
        );

        params.limit = this._conditions.limit;

        return params;
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}