import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import AssociationsApiInterface from '../../../entities/associations-api-interface';
import LatestScenesApiInterface from '../../../vos/webscenes/latest-scenes-api-interface';
import Scene from '../../../entities/webscenes/scene';
import SceneTransformer from '../../../transformers/webscenes/scene-transformer';

export default class GetIncompleteScenesTask extends AbstractApiGetTask
{
    public async run(): Promise<Scene[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const data: LatestScenesApiInterface = response.data.data;
                const associations: AssociationsApiInterface = {
                    categories: data.categories,
                };

                const scenes: Scene[] = [];
                for (const apiScene of data.scenes)
                {
                    try
                    {
                        // Parse the scene.
                        const scene = SceneTransformer.fromApi(apiScene, associations);

                        // Add to the scenes array.
                        scenes.push(scene);
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Scene');
                        console.error(error);
                    }
                }

                return scenes;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/scenes/incomplete';
    }
}