import { Component, Vue } from 'vue-property-decorator';
import DividerComponent from '@/components/widgets/divider/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import TeamLeaderboardApiInterface from '../../../model/vos/organisation/team-leaderboard-api-interface';

@Component({
    components:
    {
        'app-divider': DividerComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class TeamLeaderboardPageComponent extends Vue
{
    private isLoading = true;
    private leaderboard: TeamLeaderboardApiInterface[] = [];

    async created()
    {
        this.isLoading = true;
        await this.loadLeaderboard();
        this.isLoading = false;
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('leaderboard.message.team_leaderboard'),
                disabled: false,
                to: { name: 'team-leaderboard' },
                exact: true,
            },
        ];
    }

    get headers()
    {
        return [
            {
                text: this.$t('general.field.name'),
                align: 'left',
                value: 'name',
                sortable: false,
            },
            {
                text: this.$t('leaderboard.field.members'),
                align: 'center',
                value: 'members',
                sortable: false,
            },
            {
                text: this.$t('general.field.points'),
                align: 'center',
                value: 'points',
                sortable: false,
            },
        ];
    }

    /**
     * Loads the leaderboard.
     */

    private async loadLeaderboard()
    {
        this.leaderboard = await this.$serviceContainer.getTeamService().getTeamLeaderboard();
    }
}