import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Newsletter from '../../../entities/media/newsletter';
import NewsletterApiInterface from '../../../entities/media/newsletter-api-interface';
import NewsletterTransformer from '../../../transformers/media/newsletter-transformer';

export default class GetActiveNewslettersTask extends AbstractApiGetTask
{
    public async run(): Promise<Newsletter[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiNewsletters: NewsletterApiInterface[] = response.data.data;

                const newsletters: Newsletter[] = [];
                for (const apiNewsletter of apiNewsletters)
                {
                    try
                    {
                        // Parse the newsletter.
                        newsletters.push(NewsletterTransformer.fromApi(apiNewsletter));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Newsletter');
                        console.error(error);
                    }
                }

                return newsletters;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/newsletters/active';
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}