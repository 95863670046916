import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import app from './app';
import authentication from '@vertical-plus/vue-js-micro-service-components/lib/store/authentication';
import createMutationsSharer from 'vuex-shared-mutations';
import ui from './ui';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

export interface Store
{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    commit: (path: string, value: any) => void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getters: {[path: string]: any};
}

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules:
    {
        authentication,
        app,
        ui,
    },
    plugins: [
        vuexLocal.plugin,
        createMutationsSharer({
            // Share entire state
            predicate: () => true,
        }),
    ],
});