import { Component, Vue } from 'vue-property-decorator';
import Activity from '../../../../../model/entities/rewards/activity';
import ActivityFormComponent from '@/components/widgets/activity-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-activity-form': ActivityFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminActivityCreatePageComponent extends Vue
{
    public activity: Activity|null = null;

    created()
    {
        this.activity = Object.assign({}, new Activity());
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.activities.message.index_title'),
                disabled: false,
                to: { name: 'admin-activity-index' },
                exact: true,
            },
            {
                text: this.$t('admin.activities.message.create_title'),
                disabled: true,
                to: { name: 'admin-activity-create' },
                exact: true,
            },
        ];
    }

    /**
     * Handles save events from the form.
     *
     * @param activity
     */

    private async onSave(activity: Activity)
    {
        try
        {
            // Create the activity.
            const created = await this.$serviceContainer.getActivityService().createActivity(activity);

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.activities.message.activity_created'));

            // Redirect to the activity.
            NavigationHelper.pushRoute({
                name: 'admin-activity-edit',
                params: { id: created.id.toString() },
            });
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }
}