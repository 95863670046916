import AbstractValidator from '../abstract-validator';
import Activity from '../../entities/rewards/activity';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class ActivityValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a activity.
     *
     * @param Activity
     */

    public validate(activity: Activity): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateName(activity, this.errors);
        this.validateDescription(activity, this.errors);
        this.validatePoints(activity, this.errors);

        return this.errors;
    }

    private validateName(activity: Activity, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(activity.name.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_name').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.name = errors;
        }
    }

    private validateDescription(activity: Activity, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (!this.isValidString(activity.description.en))
        {
            errors.push(this.i18n.t('general.validation.please_enter_a_description').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.description = errors;
        }
    }

    private validatePoints(activity: Activity, validationErrors: ValidationErrorsInterface)
    {
        const errors: string[] = [];

        if (isNaN(activity.points) || activity.points <= 0)
        {
            errors.push(this.i18n.t('admin.activities.validation.please_enter_points').toString());
        }

        if (errors.length > 0)
        {
            validationErrors.points = errors;
        }
    }
}