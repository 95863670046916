import { Component, Prop, Vue } from 'vue-property-decorator';
import UserService from '@/model/services/organisation/user-service';

@Component({
    components:
    {},
})
export default class TranslationComponent extends Vue
{
    @Prop()
    private value!: string;

    @Prop()
    private keyPath!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private block!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private negative!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private left!: boolean;

    /**
     * Returns whether the current user is an admin.
     */

    get isAdmin(): boolean
    {
        return !!(this.user && UserService.userHasRole(this.user, [ 'ROLE_ADMIN' ]));
    }
}