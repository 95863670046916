import { Component, Vue } from 'vue-property-decorator';
import Banner from '../../../../../model/entities/media/banner';
import BannerFileInterface from '../../../../../model/vos/media/banner-file-interface';
import BannerFormComponent from '@/components/widgets/banner-form/index.vue';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-banner-form': BannerFormComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class AdminBannerEditPageComponent extends Vue
{
    private banner: Banner|null = null;
    private isLoading = true;

    created()
    {
        this.loadBanner();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('admin.banners.message.index_title'),
                disabled: false,
                to: { name: 'admin-banner-index' },
                exact: true,
            },
            {
                text: this.$t('admin.banners.message.edit_title'),
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the title of the banner for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.banner ? this.$et(this.banner.title) : this.$t('admin.banners.edit_title').toString();
    }

    /**
     * Loads the banner.
     */

    private async loadBanner()
    {
        this.isLoading = true;
        const banner = await this.$serviceContainer.getBannerService().loadBanner(parseInt(this.$route.params.id));
        this.banner = Object.assign({}, banner);
        this.isLoading = false;
    }

    /**
     * Handles save events from the form.
     *
     * @param banner
     * @param files
     */

    private async onSave(banner: Banner, files: BannerFileInterface[])
    {
        try
        {
            // Update the banner.
            let updated = await this.$serviceContainer.getBannerService().updateBanner(banner);

            // Upload files.
            if (files.length > 0)
            {
                updated = await this.uploadFiles(updated, files);
            }

            // Notify the user of creation.
            EventBus.instance.$emit('notification', this.$t('admin.banners.message.banner_updated'));

            this.banner = updated;
        }
        catch (error)
        {
            console.error(error);

            EventBus.instance.$emit(
                'notification', this.$t('general.error.failed_saving_server_error'), 'error',
            );
        }
    }

    /**
     * Uploads banner files.
     *
     * @param banner
     * @param files
     */

    private async uploadFiles(banner: Banner, files: BannerFileInterface[]): Promise<Banner>
    {
        let updated: Banner = banner;
        for (const file of files)
        {
            const returned = await this.$serviceContainer.getBannerService().uploadBannerFile(
                banner.id,
                file.field,
                file.file,
            );

            if (returned)
            {
                updated = returned;
            }
        }

        return updated;
    }
}