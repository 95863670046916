import AbstractValidator from '../abstract-validator';
import User from '@/model/entities/organisation/user';
import UserValidator from '../organisation/user-validator';
import ValidationErrorsInterface from '../validation-errors-interface';

export default class RegistrationFormValidator extends AbstractValidator
{
    private errors: ValidationErrorsInterface = {};

    /**
     * Validates a login form.
     *
     * @param user
     * @param password
     * @param passwordConfirmation
     */

    public validate(
        user: User, password: string, passwordConfirmation: string,
    ): ValidationErrorsInterface
    {
        this.errors = {};

        this.validateUser(user, this.errors);
        this.validatePassword(
            password, passwordConfirmation, this.errors,
        );
        this.validateNewsletter(user, this.errors);
        this.validateMarketing(user, this.errors);

        return this.errors;
    }

    private validateUser(user: User, validationErrors: ValidationErrorsInterface)
    {
        const userValidator = new UserValidator();
        const userErrors = userValidator.validate(user);

        validationErrors = Object.assign(validationErrors, userErrors);
    }

    private validatePassword(
        password: string, passwordConfirmation: string, validationErrors: ValidationErrorsInterface,
    )
    {
        const errors: string[] = [];
        const confirmationErrors: string[] = [];

        if (!this.isValidString(password, 8))
        {
            errors.push('Please enter a password (min 8 characters)');
        }

        if (password !== passwordConfirmation)
        {
            confirmationErrors.push('The passwords do not match');
        }

        if (errors.length > 0)
        {
            validationErrors.password = errors;
        }

        if (confirmationErrors.length > 0)
        {
            validationErrors.passwordConfirmation = confirmationErrors;
        }
    }

    private validateNewsletter(user: User, validationErrors: ValidationErrorsInterface)
    {
        if (user.isNewsletterRegistered === null)
        {
            validationErrors.isNewsletterRegistered = [ 'Please choose a newsletter option' ];
        }
    }

    private validateMarketing(user: User, validationErrors: ValidationErrorsInterface)
    {
        if (user.isMarketingRegistered === null)
        {
            validationErrors.isMarketingRegistered = [ 'Please choose a marketing option' ];

            return;
        }

        if (!user.isMarketingRegistered)
        {
            return;
        }

        if (!this.isValidString(user.phone))
        {
            validationErrors.phone = [ 'Please enter a phone number' ];
        }

        if (!user.isPrivacyNoticeAccepted)
        {
            validationErrors.isPrivacyNoticeAccepted = [ 'You must accept the privacy notice' ];
        }
    }
}