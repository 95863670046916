import { Component, Vue } from 'vue-property-decorator';
import Scene from '../../../model/entities/webscenes/scene';
import TranslationComponent from '@/components/widgets/translation/index.vue';

@Component({
    components:
    {
        'app-translation': TranslationComponent,
    },
})
export default class LatestScenesComponent extends Vue
{
    private scenes: Scene[] = [];

    created()
    {
        this.loadLatestScenes();
    }

    /**
     * Loads the latest scenes.
     */

    private async loadLatestScenes()
    {
        this.scenes = await this.$serviceContainer.getSceneService().getLatestScenes();
    }
}