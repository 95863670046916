import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/widgets/button/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';
import Post from '../../../model/entities/blog/post';
import SafetyAmbassador from '../../../model/entities/organisation/safety-ambassador';

@Component({
    components:
    {
        'app-button': ButtonComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class SafetyAmbassadorsComponent extends Vue
{
    private safetyAmbassadors: SafetyAmbassador[] = [];
    private isLoading = true;

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.safety_ambassadors'),
                disabled: false,
                to: { name: 'safety-ambassadors' },
                exact: true,
            },
        ];
    }

    created()
    {
        this.loadSafetyAmbassadors();
    }

    private get ambassadorHeight(): number
    {
        switch (this.$vuetify.breakpoint.name)
        {
        case 'xl':
            return 400;

        case 'lg':
            return 300;

        case 'md':
            return 300;

        case 'sm':
        case 'xs':
        default:
            return 400;
        }
    }

    /**
     * Returns the uri for a safety ambassador's image
     *
     * @param safetyAmbassador
     */

    private getSafetyAmbassadorImageUri(safetyAmbassador: Post)
    {
        if (!safetyAmbassador.image)
        {
            return '#';
        }

        return process.env.VUE_APP_API_BASE_URL + safetyAmbassador.image.uri;
    }

    private async loadSafetyAmbassadors()
    {
        this.isLoading = true;

        this.safetyAmbassadors = await this.$serviceContainer.getSafetyAmbassadorService().getActiveSafetyAmbassadors();

        this.isLoading = false;
    }
}