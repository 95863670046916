import DeleteFirstAiderTask from '../../tasks/api/organisation/delete-first-aider';
import FirstAider from '../../entities/organisation/first-aider';
import FirstAiderResultsApiInterface from '../../vos/organisation/first-aider-results-api-interface';
import GetActiveFirstAidersTask from '../../tasks/api/organisation/get-active-first-aiders';
import GetFirstAiderTask from '../../tasks/api/organisation/get-first-aider';
import PatchFirstAiderTask from '../../tasks/api/organisation/patch-first-aider';
import PostFirstAiderTask from '../../tasks/api/organisation/post-first-aider';
import SearchConditionsInterface from '@/model/vos/search/search-conditions-interface';
import SearchFirstAidersTask from '../../tasks/api/organisation/search-first-aiders';

export default class FirstAiderService
{
    /**
     * Returns all the active first aiders.
     *
     * @param type
     */

    public async getActiveFirstAiders(type: string): Promise<FirstAider[]>
    {
        const task = new GetActiveFirstAidersTask();
        task.type = type;

        return task.run();
    }

    /**
     * Returns first aiders matching the conditions.
     *
     * @param conditions
     */

    public async searchFirstAiders(conditions: SearchConditionsInterface): Promise<FirstAiderResultsApiInterface|null>
    {
        const task = new SearchFirstAidersTask();
        task.conditions = conditions;

        return task.run();
    }

    /**
     * Loads a first aider.
     *
     * @param id
     */

    public async loadFirstAider(id: number|string): Promise<FirstAider|null>
    {
        const task = new GetFirstAiderTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a first aider.
     *
     * @param firstAider
     */

    public async createFirstAider(firstAider: FirstAider): Promise<FirstAider>
    {
        const task = new PostFirstAiderTask();
        task.firstAider = firstAider;

        return task.run();
    }

    /**
     * Updates a first aider.
     *
     * @param firstAider
     */

    public async updateFirstAider(firstAider: FirstAider): Promise<FirstAider>
    {
        const task = new PatchFirstAiderTask();
        task.firstAider = firstAider;

        return task.run();
    }

    /**
     * Deletes an first aider.
     *
     * @param id
     */

    public async deleteFirstAider(id: number): Promise<void>
    {
        const task = new DeleteFirstAiderTask();
        task.id = id;

        return task.run();
    }

    /**
     * Returns the types
     */

    public static getTypes(): {id: string, name: string}[]
    {
        return [
            { id: 'mental-health',
                name: 'Mental Health' },
        ];
    }
}