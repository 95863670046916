import { formatISO, parseISO } from 'date-fns';
import User from '@/model/entities/organisation/user';
import UserApiInterface from '@/model/entities/organisation/user-api-interface';

export default class UserTransformer
{
    /**
     * Transforms an API user representation into a User entity.
     *
     * @param apiUser
     */

    public static fromApi(apiUser: UserApiInterface): User
    {
        const user = new User();
        user.id = apiUser.id;
        user.firstName = apiUser.first_name;
        user.lastName = apiUser.last_name;
        user.email = apiUser.email;
        user.phone = apiUser.phone;
        user.isDisabled = apiUser.is_disabled;
        user.isNewsletterRegistered = apiUser.is_newsletter_registered;
        user.isMarketingRegistered = apiUser.is_marketing_registered;
        user.isPrivacyNoticeAccepted = apiUser.is_privacy_notice_accepted;
        user.registrationDate = parseISO(apiUser.registration_date);
        user.lastLogin = apiUser.last_login ? parseISO(apiUser.last_login) : null;
        user.roles = apiUser.roles ? apiUser.roles.slice(0) : [];
        user.token = apiUser.token ?? null;

        return user;
    }

    /**
     * Transforms a User entity into an API representation of a user.
     *
     * @param user
     */

    public static toApi(user: User): UserApiInterface
    {
        return {
            id: user.id,
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            phone: user.phone,
            is_disabled: user.isDisabled,
            is_newsletter_registered: user.isNewsletterRegistered,
            is_marketing_registered: user.isMarketingRegistered,
            is_privacy_notice_accepted: user.isPrivacyNoticeAccepted,
            registration_date: formatISO(user.registrationDate),
            last_login: user.lastLogin ? formatISO(user.lastLogin) : null,
            roles: user.roles.slice(0),
            token: user.token ?? undefined,
        };
    }
}