import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import SceneQuestionAnswer from '../../../model/entities/webscenes/scene-question-answer';
import TranslationsInterface from '@/model/vos/translations/translations-interface';
import ValidationErrorsInterface from '@/model/validator/validation-errors-interface';

@Component({
})
export default class SceneQuestionAnswerFormComponent extends Vue
{
    @Prop()
    private answer!: SceneQuestionAnswer;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private formAnswer: SceneQuestionAnswer|null = null;

    private validationErrors: ValidationErrorsInterface = {};

    created()
    {
        this.formAnswer = Object.assign(new SceneQuestionAnswer(), this.answer);
    }

    @Watch('answer')
    private onAnswerUpdated()
    {
        this.formAnswer = Object.assign(new SceneQuestionAnswer(), this.answer);
    }

    /**
     * Returns the URI for the image.
     */

    get imageUri(): string|null
    {
        if (!this.formAnswer)
        {
            return null;
        }

        return this.$serviceContainer.getSceneService().getSceneQuestionAnswerImageUri(this.formAnswer);
    }

    /**
     * Returns the supported locales.
     */

    get supportedLocales(): string[]
    {
        return LocaleHelper.getSupportedLocales();
    }

    get isCorrectEn(): boolean
    {
        if (!this.formAnswer)
        {
            return false;
        }

        return this.formAnswer.isCorrect.en;
    }

    set isCorrectEn(isCorrect: boolean)
    {
        if (!this.formAnswer)
        {
            return;
        }

        this.formAnswer.isCorrect.en = isCorrect;
    }

    get isCorrectNlBe(): boolean
    {
        if (!this.formAnswer)
        {
            return false;
        }

        if ('nl-BE' in this.formAnswer.isCorrect)
        {
            return this.formAnswer.isCorrect['nl-BE'];
        }
        else
        {
            return this.formAnswer.isCorrect.en;
        }
    }

    set isCorrectNlBe(isCorrect: boolean)
    {
        if (!this.formAnswer)
        {
            return;
        }

        this.formAnswer.isCorrect['nl-BE'] = isCorrect;
    }

    /**
     * Handles image file change.
     *
     * @param files
     */

    private onImageChange(files: FileList)
    {
        if (!this.formAnswer)
        {
            return;
        }

        this.formAnswer.imageFile = files[0];
    }

    /**
     * Handles save requests.
     */

    private onSave()
    {
        this.$emit('save', this.formAnswer);
    }

    /**
     * Handles cancel requests.
     */

    private onCancel()
    {
        this.$emit('cancel');
    }
}