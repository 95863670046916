import { NavigationFailureType, RawLocation } from 'vue-router';
import router from '@/router/index';

export default class NavigationHelper
{
    /**
     * Navigate to a route
     *
     * @param route
     */

    public static async pushRoute(route: RawLocation)
    {
        try
        {
            await router.push(route);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            // Ignore duplicated route errors
            if (error.type !== NavigationFailureType.duplicated)
            {
                throw error;
            }
        }
    }

    /**
     * Replaces the current route
     *
     * @param route
     */

    public static async replaceRoute(route: RawLocation)
    {
        try
        {
            await router.replace(route);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            if (!(error.type === NavigationFailureType.duplicated))
            {
                throw error;
            }
        }
    }
}