import AbstractApiPostTask from '@/model/tasks/api/abstract-api-post-task';
import Banner from '../../../entities/media/banner';
import BannerApiInterface from '@/model/entities/media/banner-api-interface';
import BannerTransformer from '../../../transformers/media/banner-transformer';

export default class PostBannerTask extends AbstractApiPostTask
{
    private _banner!: Banner;

    public async run(): Promise<Banner>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return BannerTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._banner;
    }

    set banner(banner: Banner)
    {
        this._banner = banner;
    }

    protected getUrl(): string
    {
        return '/banners';
    }

    protected getData(): BannerApiInterface
    {
        return BannerTransformer.toApi(this._banner);
    }
}