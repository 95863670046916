import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@vertical-plus/vue-js-core/dist/util/event/event-bus';
import NavigationHelper from '@/model/services/navigation/navigation-helper';

@Component({})
export default class LogoutView extends Vue
{
    created()
    {
        this.logout();
    }

    private async logout()
    {
        // Log the user out.
        await this.$serviceContainer.getAuthenticationService().logout();

        // Send a logged-out event.
        EventBus.instance.$emit('logged-out');

        // Redirect to the home page.
        NavigationHelper.pushRoute({ name: 'home' });
    }
}