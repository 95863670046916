import { Component, Vue } from 'vue-property-decorator';
import Category from '../../../../model/entities/webscenes/category';
import CategoryScenesComponent from '@/components/widgets/category-scenes/index.vue';
import PageTitleComponent from '@/components/widgets/page-title/index.vue';

@Component({
    components:
    {
        'app-category-scenes': CategoryScenesComponent,
        'app-page-title': PageTitleComponent,
    },
})
export default class CategoryViewPageComponent extends Vue
{
    private category: Category|null = null;
    private isLoading = true;

    created()
    {
        this.loadCategory();
    }

    get breadcrumb()
    {
        return [
            {
                text: this.$t('nav.home'),
                disabled: false,
                to: { name: 'home' },
                exact: true,
            },
            {
                text: this.$t('nav.safety_categories'),
                disabled: false,
                to: { name: 'category-index' },
                exact: true,
            },
            {
                text: this.titleName,
                disabled: true,
                exact: true,
            },
        ];
    }

    /**
     * Returns the name of the category for use in the page title / breadcrumb.
     */

    get titleName(): string
    {
        return this.category ? this.$et(this.category.name) : '';
    }

    /**
     * Loads the category.
     */

    private async loadCategory()
    {
        this.isLoading = true;
        const category = await this.$serviceContainer.getCategoryService().loadCategory(this.$route.params.categoryRef);
        this.category = Object.assign({}, category);
        this.isLoading = false;
    }
}