import AbstractApiDeleteTask from '@/model/tasks/api/abstract-api-delete-task';

export default class DeleteRewardTask extends AbstractApiDeleteTask
{
    private _id!: number;

    public async run(): Promise<void>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }
    }

    set id(id: number)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/rewards/${ this._id }`;
    }
}