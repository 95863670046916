import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Notification from '../../../entities/notifications/notification';
import NotificationApiInterface from '../../../entities/notifications/notification-api-interface';
import NotificationTransformer from '../../../transformers/notifications/notification-transformer';

export default class ReadNotificationTask extends AbstractApiPatchTask
{
    private _id!: number;

    public async run(): Promise<Notification|null>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiNotification: NotificationApiInterface = response.data;

                return NotificationTransformer.fromApi(apiNotification);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return null;
    }

    set id(id: number)
    {
        this._id = id;
    }

    protected getUrl(): string
    {
        return `/notifications/${ this._id }/read`;
    }
}