import Employee from '../model/entities/organisation/employee';
import EmployeeApiInterface from '../model/entities/organisation/employee-api-interface';
import EmployeeTransformer from '../model/transformers/organisation/employee-transformer';
import LocaleHelper from '@vertical-plus/vue-js-core/dist/util/i18n/locale-helper';
import ServiceContainer from '../model/services/service-container';
import User from '@/model/entities/organisation/user';
import UserApiInterface from '@/model/entities/organisation/user-api-interface';
import UserTransformer from '@/model/transformers/organisation/user-transformer';
import Vue, { PluginObject } from 'vue';
import showdown from 'showdown';
import store from '@/store';

// Instantiate a single service container
const serviceContainer = new ServiceContainer();

declare module 'vue/types/vue'
{
    interface Vue
    {
        $serviceContainer: ServiceContainer;
        $castEmployee(employee: EmployeeApiInterface): Employee;
        $castUser(user: UserApiInterface): User;
        $markdownToHtml(markdown: string): string;
        $et(translations: any): string;
        $lockPage(): void;
        $unlockPage(): void;
        user: User|null;
    }
}

const VerticalPlusPlugin: PluginObject<Vue> =
{
    install(Vue)
    {
        // Service container
        Vue.prototype.$serviceContainer = serviceContainer;

        Object.defineProperties(Vue.prototype, {
            user: {
                get()
                {
                    const user: UserApiInterface|null = this.$store.getters['app/user'];

                    if (user)
                    {
                        return Object.assign(new User(), UserTransformer.fromApi(user));
                    }
                    else
                    {
                        return null;
                    }
                },
            },
        });

        // Casts an employee from the API to an Employee entity
        Vue.prototype.$castEmployee = (employee: EmployeeApiInterface) =>
        {
            if (!employee)
            {
                return null;
            }

            return Object.assign(new Employee(), EmployeeTransformer.fromApi(employee));
        };

        // Casts a user from the API to a User entity
        Vue.prototype.$castUser = (user: UserApiInterface) =>
        {
            if (!user)
            {
                return null;
            }

            return Object.assign(new User(), UserTransformer.fromApi(user));
        };

        // Markdown -> HTML converter
        Vue.prototype.$markdownToHtml = (markdown: string) =>
        {
            const converter = new showdown.Converter();
            converter.setFlavor('github');

            return converter.makeHtml(markdown);
        };

        // Entity translator
        Vue.prototype.$et = (translations: any) => LocaleHelper.getTranslationForCurrentLocale(translations);

        // Page locking

        Vue.prototype.$lockPage = () =>
        {
            store.commit('ui/pageLocked', true);
        };

        Vue.prototype.$unlockPage = () =>
        {
            store.commit('ui/pageLocked', false);
        };
    },
};

Vue.use(VerticalPlusPlugin);

export default VerticalPlusPlugin;