import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Category from '../../../entities/webscenes/category';
import CategoryApiInterface from '../../../entities/webscenes/category-api-interface';
import CategoryTransformer from '../../../transformers/webscenes/category-transformer';

export default class GetActiveCategoriesTask extends AbstractApiGetTask
{
    public async run(): Promise<Category[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiCategories: CategoryApiInterface[] = response.data.data;

                const categories: Category[] = [];
                for (const apiCategory of apiCategories)
                {
                    try
                    {
                        // Parse the category.
                        categories.push(CategoryTransformer.fromApi(apiCategory));
                    }
                    catch (error)
                    {
                        console.error('Unable to parse Category');
                        console.error(error);
                    }
                }

                return categories;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/categories/active';
    }
}