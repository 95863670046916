import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component({
    components:
    {},
})
export default class ButtonComponent extends Vue
{
    @Prop({
        type: Boolean,
        default: false,
    })
    private neutral!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private secondary!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private error!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private fab!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private small!: boolean;

    @Prop()
    private to!: RawLocation;

    get colour(): string
    {
        let colour = '';
        if (this.neutral)
        {
            colour += 'tertiary dark--text';
        }
        else if (this.error)
        {
            colour += 'error white--text';
        }
        else
        {
            colour += 'light';

            if (this.secondary)
            {
                colour += ' secondary--text';
            }
            else
            {
                colour += ' primary--text';
            }
        }

        return colour;
    }

    /**
     * Proxies the underlying click event.
     */

    private onClick()
    {
        this.$emit('click');
    }
}