import AbstractApiGetTask from '@/model/tasks/api/abstract-api-get-task';
import Notification from '../../../entities/notifications/notification';
import NotificationApiInterface from '../../../entities/notifications/notification-api-interface';
import NotificationTransformer from '../../../transformers/notifications/notification-transformer';

export default class GetLatestNotificationsTask extends AbstractApiGetTask
{
    public async run(): Promise<Notification[]>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                const apiNotifications: NotificationApiInterface[] = response.data.data;

                const notifications: Notification[] = [];
                for (const apiNotification of apiNotifications)
                {
                    notifications.push(NotificationTransformer.fromApi(apiNotification));
                }

                return notifications;
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return [];
    }

    protected getUrl(): string
    {
        return '/notifications/latest';
    }

    protected getAuthenticated(): boolean
    {
        return false;
    }
}