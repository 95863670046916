import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Notification from '../../../entities/notifications/notification';
import NotificationApiInterface from '@/model/entities/notifications/notification-api-interface';
import NotificationTransformer from '../../../transformers/notifications/notification-transformer';

export default class PatchNotificationTask extends AbstractApiPatchTask
{
    private _notification!: Notification;

    public async run(): Promise<Notification>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return NotificationTransformer.fromApi(response.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._notification;
    }

    set notification(notification: Notification)
    {
        this._notification = notification;
    }

    protected getUrl(): string
    {
        return `/notifications/${ this._notification.id }`;
    }

    protected getData(): NotificationApiInterface
    {
        return NotificationTransformer.toApi(this._notification);
    }
}