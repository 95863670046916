import AbstractApiPatchTask from '@/model/tasks/api/abstract-api-patch-task';
import Video from '../../../entities/media/video';
import VideoApiInterface from '@/model/entities/media/video-api-interface';
import VideoTransformer from '../../../transformers/media/video-transformer';

export default class PatchVideoTask extends AbstractApiPatchTask
{
    private _video!: Video;

    public async run(): Promise<Video>
    {
        try
        {
            const response = await this.request();

            if (this.isSuccessful(response))
            {
                return VideoTransformer.fromApi(response.data.data);
            }
            else
            {
                this.handleError(response);
            }
        }
        catch (e: any)
        {
            this.handleError(e.response);
        }

        return this._video;
    }

    set video(video: Video)
    {
        this._video = video;
    }

    protected getUrl(): string
    {
        return `/videos/${ this._video.id }`;
    }

    protected getData(): VideoApiInterface
    {
        return VideoTransformer.toApi(this._video);
    }
}