import UserSceneAnswer from '../../entities/webscenes/user-scene-answer';
import UserSceneAnswerApiInterface from '../../entities/webscenes/user-scene-answer-api-interface';

export default class UserSceneAnswerTransformer
{
    /**
     * Transforms an API scene answer representation into a SceneQuestionAnswer entity.
     *
     * @param apiScene
     */

    public static fromApi(apiAnswer: UserSceneAnswerApiInterface): UserSceneAnswer
    {
        const answer = new UserSceneAnswer();
        answer.id = apiAnswer.id;
        answer.userId = apiAnswer.user_id;
        answer.sceneId = apiAnswer.scene_id;
        answer.questionId = apiAnswer.question_id;
        answer.answerId = apiAnswer.answer_id;

        return answer;
    }
}